const arrowIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 104.56">
      <title>Arrow</title>
      <path
        className={props.iconClass}
        d="M250,52.28,126.06,0l17.22,40.52H11.77a11.77,11.77,0,1,0,0,23.53H143.28l-17.22,40.51Z"
      />
    </svg>
  );
};

const busIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Bus</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="249.94" />
      <path
        className="icon"
        d="M150.83,340.25a36.16,36.16,0,0,0,33.95-23.7h121a36.17,36.17,0,0,0,67.9,0h52.9a8.77,8.77,0,0,0,8.25-5.81l11.53-32.13a23.54,23.54,0,0,0,1.05-11.88l-15.24-89.56a7.31,7.31,0,0,0-7.2-6.09H332.87l-3.7-8.51a4.7,4.7,0,0,0-4.31-2.82H161.1a4.69,4.69,0,0,0-4.31,2.82l-3.71,8.51H86.36a10.23,10.23,0,0,0-9.13,5.51C65.08,200.48,55,236.84,58.08,279.53H55.34a3,3,0,0,0-3,3V305a11.5,11.5,0,0,0,11.5,11.51h53.06A36.19,36.19,0,0,0,150.83,340.25Zm0-20.94a15.22,15.22,0,1,1,8.74-2.76A15.1,15.1,0,0,1,150.83,319.31Zm133.51-87.48a3.08,3.08,0,0,1-3.08,3.08H219.45a3.08,3.08,0,0,1-3.08-3.08V202.64a3.08,3.08,0,0,1,3.08-3.08h61.81a3.08,3.08,0,0,1,3.08,3.08Zm55.34,87.48a15.22,15.22,0,1,1,8.74-2.76A15.18,15.18,0,0,1,339.68,319.31ZM388.4,202.64a3.08,3.08,0,0,1,3.08-3.08h21.33a3.72,3.72,0,0,1,3.65,3l5.37,29.29a2.57,2.57,0,0,1-2.53,3H391.48a3.08,3.08,0,0,1-3.08-3.08Zm-18,0v29.19a3.08,3.08,0,0,1-3.08,3.08h-61.8a3.08,3.08,0,0,1-3.09-3.08V202.64a3.08,3.08,0,0,1,3.09-3.08h61.8A3.08,3.08,0,0,1,370.35,202.64Zm-240,0a3.08,3.08,0,0,1,3.08-3.08h61.81a3.08,3.08,0,0,1,3.08,3.08v29.19a3.08,3.08,0,0,1-3.08,3.08H133.43a3.08,3.08,0,0,1-3.08-3.08ZM107,258.3H80a2.91,2.91,0,0,1-3-3c1-24.74,6.16-41.13,12.88-53.14a5.3,5.3,0,0,1,4.64-2.65h9.17a3.27,3.27,0,0,1,3.26,3.08l3,52.58A2.9,2.9,0,0,1,107,258.3Z"
      />
    </svg>
  );
};

const carIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Car</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="249.9" />
      <path
        className={props.iconClass}
        d="M437.15,240.26h-.77V205.41A11.37,11.37,0,0,0,425,194H373.82l-47.11-30.91h-105a28.24,28.24,0,0,0-20.17,8.48L162,212,71.81,226.84a24,24,0,0,0-20.12,23.72v11.57a5.49,5.49,0,0,0-3.57,5.14v26.09a5.49,5.49,0,0,0,5.5,5.5H86.51a43,43,0,0,0,84.59,0H323.7a43,43,0,0,0,84.59,0h34c3.59,0,6.51-15.07,6.51-18.66V251.93A11.67,11.67,0,0,0,437.15,240.26Zm-24-17.23a9.75,9.75,0,0,1,9.74-9.74h2.78a1.83,1.83,0,0,1,1.83,1.83v11.66a1.84,1.84,0,0,1-1.83,1.84H415a1.84,1.84,0,0,1-1.84-1.84ZM305.49,183.67a16.4,16.4,0,0,1,9.9,3.33l34.74,26.3a1.86,1.86,0,0,1-1.12,3.33H304.78l-11.76-33ZM291.13,238H270.58v-5.68h20.55Zm-91.29-25.39,23.33-24.92a12.74,12.74,0,0,1,9.28-4h41.06l11.76,33H201.58A2.39,2.39,0,0,1,199.84,212.61ZM65.15,244.32a8.66,8.66,0,0,1,11.95-2.77l.47.33L68.4,256.55l-.5-.28A8.67,8.67,0,0,1,65.15,244.32ZM128.81,309a18.12,18.12,0,0,1-1.68-36.16c.55-.05,1.11-.08,1.68-.08s1.11,0,1.67.08A18.12,18.12,0,0,1,128.81,309ZM366,309a18.12,18.12,0,0,1-1.67-36.16c.55-.05,1.11-.08,1.67-.08s1.11,0,1.67.08A18.12,18.12,0,0,1,366,309Z"
      />
    </svg>
  );
};

const checkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Check</title>
      <polygon
        className="icon"
        points="450 115.89 423.61 89.5 158.24 354.86 79.12 257.74 50.17 281.33 155.41 410.5 450 115.89"
      />
    </svg>
  );
};

const closeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Close</title>
      <polygon
        className="icon"
        points="84.46 450 250 284.46 415.54 450 450 415.54 284.46 250 450 84.46 415.54 50 250 215.55 84.46 50 50 84.46 215.54 250 50 415.54 84.46 450"
      />
    </svg>
  );
};

const copyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Copy</title>
      <g className="icon">
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
      </g>
    </svg>
  );
};

const disketteIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Diskette</title>
      <g className={props.iconClass}>
        <path d="M387.4,80,420,113.25V420H80V80H387.4m4.36-30H69.63A19.63,19.63,0,0,0,50,69.63V430.37A19.63,19.63,0,0,0,69.63,450H430.37A19.63,19.63,0,0,0,450,430.37V109a19.64,19.64,0,0,0-5.61-13.74L405.77,55.89a19.6,19.6,0,0,0-14-5.89Z" />
        <path d="M345,280.5V420H155V280.5H345m7.23-30H147.77A22.78,22.78,0,0,0,125,273.27V450H375V273.27a22.78,22.78,0,0,0-22.77-22.77Z" />
        <path d="M320,81.5V171H181V81.5H320m18.6-30H162.4A11.39,11.39,0,0,0,151,62.9V189.6A11.39,11.39,0,0,0,162.4,201H338.6A11.39,11.39,0,0,0,350,189.6V62.9a11.39,11.39,0,0,0-11.4-11.4Z" />
      </g>
    </svg>
  );
};

const downloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Download</title>
      <g className="icon">
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
      </g>
    </svg>
  );
};

const dragIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Drag</title>
      <g className="icon">
        <path d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
      </g>
    </svg>
  );
};

const editIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <title>Edit</title>
      <g className="icon">
        <path d="M2 12.88V16h3.12L14 7.12 10.88 4 2 12.88zm14.76-8.51c.33-.33.33-.85 0-1.18l-1.95-1.95c-.33-.33-.85-.33-1.18 0L12 2.88 15.12 6l1.64-1.63z" />
      </g>
    </svg>
  );
};

const filtersIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Filters</title>
      <g className={props.iconClass}>
        <path d="M450,231.82H247.42c-8.14-31.25-36.36-54.55-70.15-54.55s-62,23.3-70.14,54.55H50v36.36h57.13c8.13,31.29,36.35,54.55,70.14,54.55s62-23.26,70.15-54.55H450ZM177.27,286.36A36.36,36.36,0,1,1,213.64,250,36.39,36.39,0,0,1,177.27,286.36Z" />
        <path d="M450,104.55H429.23C421.1,73.3,392.88,50,359.09,50s-62,23.3-70.14,54.55H50v36.36H289c8.13,31.28,36.35,54.54,70.14,54.54s62-23.26,70.14-54.54H450Zm-90.91,54.54a36.37,36.37,0,1,1,36.36-36.36A36.39,36.39,0,0,1,359.09,159.09Z" />
        <path d="M359.09,304.55c-33.79,0-62,23.29-70.14,54.54H50v36.36H289C297.08,426.74,325.3,450,359.09,450s62-23.26,70.14-54.55H450V359.09H429.23C421.1,327.84,392.88,304.55,359.09,304.55Zm0,109.09a36.37,36.37,0,1,1,36.36-36.37A36.4,36.4,0,0,1,359.09,413.64Z" />
      </g>
    </svg>
  );
};

const iOIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>IO</title>
      <g className="icon">
        <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z" />
      </g>
    </svg>
  );
};

const linkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Link</title>
      <g className="icon">
        <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
      </g>
    </svg>
  );
};

const optionsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Options</title>
      <g className="icon">
        <rect x="50" y="50" width="100" height="100" />
        <rect x="200" y="50" width="100" height="100" />
        <rect x="350" y="50" width="100" height="100" />
        <rect x="50" y="200" width="100" height="100" />
        <rect x="200" y="200" width="100" height="100" />
        <rect x="350" y="200" width="100" height="100" />
        <rect x="50" y="350" width="100" height="100" />
        <rect x="200" y="350" width="100" height="100" />
        <rect x="350" y="350" width="100" height="100" />
      </g>
    </svg>
  );
};

const settingsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Settings</title>
      <g className="icon">
        <path d="M326.11,199.71l27.18-27.18-25.82-25.82-27.18,27.18a90.24,90.24,0,0,0-32-13.34V122.17H231.74v38.38a90.24,90.24,0,0,0-32,13.34l-27.18-27.18-25.84,25.82,27.18,27.18a91.21,91.21,0,0,0-13.34,32H122.17v36.52h38.36a90.84,90.84,0,0,0,13.34,32.05L146.69,327.5l25.82,25.83,27.18-27.2a91.64,91.64,0,0,0,32.05,13.36v38.34h36.52V339.49a91.35,91.35,0,0,0,32-13.34l27.18,27.18,25.82-25.83-27.18-27.17a90.76,90.76,0,0,0,13.36-32.07h38.36V231.74H339.47A90.64,90.64,0,0,0,326.11,199.71ZM250,304.78A54.78,54.78,0,1,1,304.78,250,54.78,54.78,0,0,1,250,304.78Z" />
        <path d="M250,50C139.72,50,50,139.72,50,250s89.72,200,200,200S450,360.3,450,250,360.28,50,250,50Zm0,363.5c-90.14,0-163.48-73.37-163.48-163.5S159.86,86.52,250,86.52,413.48,159.87,413.48,250,340.14,413.5,250,413.5Z" />
      </g>
    </svg>
  );
};

const trashcanIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Trashcan</title>
      <g className="icon">
        <path d="M111.65,398a52,52,0,0,0,52,52H336.89a52,52,0,0,0,52-52V138.13h34.65V103.48H336.89V86.15A34.69,34.69,0,0,0,302.24,51.5h-104a34.69,34.69,0,0,0-34.65,34.65v17.33H77v34.65h34.65ZM198.28,86.15h104v17.33h-104Zm155.94,52V398a17.35,17.35,0,0,1-17.33,17.33H163.63A17.35,17.35,0,0,1,146.3,398V138.13Z" />
        <rect x="232.93" y="172.78" width="34.65" height="207.91" />
        <rect x="180.96" y="172.78" width="34.65" height="207.91" />
        <rect x="284.91" y="172.78" width="34.65" height="207.91" />
      </g>
    </svg>
  );
};

const truckIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Truck</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="249.94" />
      <path
        className="icon"
        d="M443.84,256.54h-8.22V219.21a4.56,4.56,0,0,0-4.57-4.57H262.88v-31.9a7.09,7.09,0,0,0-7.08-7.08H186.22a28,28,0,0,0-17.58,6.2l-39.23,31.59H94.55a29.78,29.78,0,0,0-29.79,29.77v21.1H57.27a6.1,6.1,0,0,0-6,7.43l3,13.51a9.53,9.53,0,0,0,9.28,7.43h26.5a35.71,35.71,0,0,0,71.21,0H333a35.7,35.7,0,0,0,71.2,0h32.36a7.45,7.45,0,0,0,7.3-6l4.9-24.21A5,5,0,0,0,443.84,256.54ZM80,243.22a29.63,29.63,0,0,1,.56-5.77,3.75,3.75,0,0,1,3.77-2.89h7.56a6.24,6.24,0,0,1,4.92,2.4l8.81,11.32a1.49,1.49,0,0,1-1.17,2.41H83a3,3,0,0,1-3-3.05ZM125.75,305A15,15,0,0,1,111,292.69a15.54,15.54,0,0,1-.26-2.75,15,15,0,0,1,30.07,0,15.54,15.54,0,0,1-.26,2.75A15,15,0,0,1,125.75,305Zm79.39-86.54a2.29,2.29,0,0,1-2.29,2.29H152.4a.8.8,0,0,1-.49-1.42l32.43-25.57a6.59,6.59,0,0,1,4.08-1.42h14.43a2.28,2.28,0,0,1,2.29,2.29Zm13.07,0V194.6a2.28,2.28,0,0,1,2.28-2.29h22a2.28,2.28,0,0,1,2.28,2.29v23.83a2.29,2.29,0,0,1-2.28,2.29h-22A2.29,2.29,0,0,1,218.21,218.43Zm28.79,25a1.52,1.52,0,0,1-1.53,1.52H228.69a1.52,1.52,0,0,1-1.52-1.52V241a1.52,1.52,0,0,1,1.52-1.52h16.78A1.52,1.52,0,0,1,247,241ZM368.56,305a15,15,0,0,1-14.77-12.28,14.65,14.65,0,0,1-.27-2.75,15,15,0,0,1,30.08,0,14.65,14.65,0,0,1-.27,2.75A15,15,0,0,1,368.56,305Z"
      />
    </svg>
  );
};

const pickupIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Pickup</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="249.94" />
      <path
        className="icon"
        d="M443.84,256.54h-8.22V219.21a4.56,4.56,0,0,0-4.57-4.57H262.88v-31.9a7.09,7.09,0,0,0-7.08-7.08H186.22a28,28,0,0,0-17.58,6.2l-39.23,31.59H94.55a29.78,29.78,0,0,0-29.79,29.77v21.1H57.27a6.1,6.1,0,0,0-6,7.43l3,13.51a9.53,9.53,0,0,0,9.28,7.43h26.5a35.71,35.71,0,0,0,71.21,0H333a35.7,35.7,0,0,0,71.2,0h32.36a7.45,7.45,0,0,0,7.3-6l4.9-24.21A5,5,0,0,0,443.84,256.54ZM80,243.22a29.63,29.63,0,0,1,.56-5.77,3.75,3.75,0,0,1,3.77-2.89h7.56a6.24,6.24,0,0,1,4.92,2.4l8.81,11.32a1.49,1.49,0,0,1-1.17,2.41H83a3,3,0,0,1-3-3.05ZM125.75,305A15,15,0,0,1,111,292.69a15.54,15.54,0,0,1-.26-2.75,15,15,0,0,1,30.07,0,15.54,15.54,0,0,1-.26,2.75A15,15,0,0,1,125.75,305Zm79.39-86.54a2.29,2.29,0,0,1-2.29,2.29H152.4a.8.8,0,0,1-.49-1.42l32.43-25.57a6.59,6.59,0,0,1,4.08-1.42h14.43a2.28,2.28,0,0,1,2.29,2.29Zm13.07,0V194.6a2.28,2.28,0,0,1,2.28-2.29h22a2.28,2.28,0,0,1,2.28,2.29v23.83a2.29,2.29,0,0,1-2.28,2.29h-22A2.29,2.29,0,0,1,218.21,218.43Zm28.79,25a1.52,1.52,0,0,1-1.53,1.52H228.69a1.52,1.52,0,0,1-1.52-1.52V241a1.52,1.52,0,0,1,1.52-1.52h16.78A1.52,1.52,0,0,1,247,241ZM368.56,305a15,15,0,0,1-14.77-12.28,14.65,14.65,0,0,1-.27-2.75,15,15,0,0,1,30.08,0,14.65,14.65,0,0,1-.27,2.75A15,15,0,0,1,368.56,305Z"
      />
    </svg>
  );
};

const uploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <title>Upload</title>
      <g className="icon">
        <path d="M3 2v2h12V2H3zm0 9h3v5h6v-5h3L9 5l-6 6z" />
      </g>
    </svg>
  );
};

const userIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>User</title>
      <g className="icon">
        <path d="M450,410.5c0-77.2-62.81-140-140-140H190c-77.19,0-140,62.8-140,140v40H450Zm-360,0a100.11,100.11,0,0,1,100-100H310a100.11,100.11,0,0,1,100,100Z" />
        <path d="M350,150.5a100,100,0,1,0-100,100A100.11,100.11,0,0,0,350,150.5Zm-160,0a60,60,0,1,1,60,60A60.06,60.06,0,0,1,190,150.5Z" />
      </g>
    </svg>
  );
};

const vanIcon = (props: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Van</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="246.63" />
      <path
        className="icon"
        d="M127.56,333.61a36.48,36.48,0,0,0,33.23-21.44H348.37a36.48,36.48,0,0,0,66.47,0h29.32a4.67,4.67,0,0,0,4.67-4.66V288.37a4.67,4.67,0,0,0-4.67-4.67h-2.47v-9.83c0-1.29,0-2.56-.06-3.86a164.26,164.26,0,0,0-25.86-84.64l-9.64-15.05a8.59,8.59,0,0,0-7.18-3.93H218a38,38,0,0,0-26.81,11.11l-37.66,37.66L82.59,231.49a10.17,10.17,0,0,0-6.7,5.19L73,242.22,60.72,265.77a19,19,0,0,0-2.15,8.8v9.13H55.84a4.67,4.67,0,0,0-4.67,4.67v19.14a4.67,4.67,0,0,0,4.67,4.66H94.33A36.48,36.48,0,0,0,127.56,333.61Zm0-21.13a15.09,15.09,0,0,1-3.1-.31,15.35,15.35,0,1,1,6.2,0A15.09,15.09,0,0,1,127.56,312.48Zm122.57-59H233.8V247h16.33Zm69.27,0H303.07V247H319.4Zm62.21,59a15.12,15.12,0,0,1-3.11-.31,15.71,15.71,0,1,1,3.11.31Zm47.55-69.2a122.12,122.12,0,0,1,2.69,23h-.61a11.59,11.59,0,0,1-2.08-23Zm-43.9-50.81,6.77,11.4a15.86,15.86,0,0,1-13.63,23.95H333.88v-40.7h42A11,11,0,0,1,385.26,192.47ZM319.4,227.82H261.71v-40.7H319.4Zm-136.63-4.3L213.65,194a25,25,0,0,1,17.23-6.9h15.68v40.7H184.49A2.49,2.49,0,0,1,182.77,223.52ZM89.36,262.38a5,5,0,0,1-5,5H72.55a.94.94,0,0,1-.84-1.38l9.47-18.14a2.56,2.56,0,0,1,2.28-1.38h4.35A1.55,1.55,0,0,1,89.36,248Z"
      />
    </svg>
  );
};

const car2Icon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Car 2</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M433.59,258.16H427.5v-33a16.75,16.75,0,0,0-16.76-16.75H382.83a17.47,17.47,0,0,1-11-3.93l-35.88-34.64a12.67,12.67,0,0,0-8.78-3.55H252.44a20.63,20.63,0,0,0-9.64,2.39l-88.53,46.9c-34.22-1.22-56.79,3.54-80.94,14.15a17.29,17.29,0,0,0-8.78,23l15.22,33.48-4.5.53a2.44,2.44,0,0,0-1.93,3.48l9,19.15a5.38,5.38,0,0,0,4.86,3.08h33.29a40,40,0,0,0,70.64,0H324a40,40,0,0,0,70.64,0h7.78A34.61,34.61,0,0,0,437,277.81V261.56A3.41,3.41,0,0,0,433.59,258.16Zm-57.49,35.6a16.82,16.82,0,1,1-33.63,0s0-.08,0-.13a16.81,16.81,0,0,1,33.62,0Zm-203.46,0a16.81,16.81,0,1,1-33.62,0s0-.08,0-.13A16.86,16.86,0,0,1,155.83,277a15.39,15.39,0,0,1,1.93.12,16.8,16.8,0,0,1,14.88,16.56Zm233.74-71.48a1.71,1.71,0,0,1,1.71-1.71h1.11a7.45,7.45,0,0,1,7.44,7.45v15.47a1.69,1.69,0,0,1-1.7,1.7h-6.85a1.71,1.71,0,0,1-1.71-1.7Zm-97.24-30.23a12.67,12.67,0,0,1,8.78,3.55l21.44,20.7a1.9,1.9,0,0,1-1.23,3.31H313.3l-7.63-27.56ZM207.79,217.22l43-22.78a20.58,20.58,0,0,1,9.62-2.39h32.83l7.64,27.56h-92.5A1.27,1.27,0,0,1,207.79,217.22ZM78.14,249.39a1.59,1.59,0,0,1,.83-2.23c4.82-1.73,10-.19,11.85,3.75s-.06,8.93-4.41,11.63a1.61,1.61,0,0,1-2.27-.74Z"
      />
    </svg>
  );
};

const clockIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <defs>
        <style>.cls-1{"{fill: #fff;}"}</style>
      </defs>
      <title>{props.title ? props.title : "Clock"}</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="cls-1"
        d="M250,428c-98.15,0-178-79.85-178-178S151.85,72,250,72s178,79.85,178,178S348.15,428,250,428Zm0-334.79C163.55,93.21,93.21,163.54,93.21,250S163.55,406.78,250,406.78,406.79,336.45,406.79,250,336.45,93.21,250,93.21Z"
      />
      <path
        className="cls-1"
        d="M250,267A17,17,0,1,1,267,250,17,17,0,0,1,250,267Z"
      />
      <rect
        className="cls-1"
        x="238.96"
        y="104.63"
        width="23.68"
        height="35.58"
        rx="8.52"
      />
      <path
        className="cls-1"
        d="M313.22,157.52a8.42,8.42,0,0,1-4.25-1.15l-5.76-3.32a8.52,8.52,0,0,1-3.12-11.63l9.27-16.07a8.56,8.56,0,0,1,5.18-4,8.31,8.31,0,0,1,2.2-.29,8.51,8.51,0,0,1,4.27,1.14l5.75,3.33a8.51,8.51,0,0,1,3.12,11.63l-9.28,16.06a8.47,8.47,0,0,1-5.17,4A8.71,8.71,0,0,1,313.22,157.52Z"
      />
      <path
        className="cls-1"
        d="M355.11,202.64a8.69,8.69,0,0,1-2.2-.29,8.51,8.51,0,0,1-5.17-4l-3.33-5.77A8.52,8.52,0,0,1,347.53,181l16.07-9.28a8.52,8.52,0,0,1,6.46-.85,8.45,8.45,0,0,1,5.17,4l3.33,5.76a8.52,8.52,0,0,1-3.12,11.63l-16.07,9.28A8.51,8.51,0,0,1,355.11,202.64Z"
      />
      <path
        className="cls-1"
        d="M233.24,271.88a8.75,8.75,0,0,1-2.2-.29,8.53,8.53,0,0,1-5.17-4l-3.32-5.76a8.52,8.52,0,0,1,3.12-11.63L304,205a8.54,8.54,0,0,1,11.63,3.12l3.32,5.76a8.44,8.44,0,0,1,.86,6.46,8.55,8.55,0,0,1-4,5.17l-78.37,45.25A8.62,8.62,0,0,1,233.24,271.88Z"
      />
      <path
        className="cls-1"
        d="M368.3,261.84a8.51,8.51,0,0,1-8.51-8.52v-6.65a8.51,8.51,0,0,1,8.51-8.51h18.55a8.51,8.51,0,0,1,8.52,8.51v6.65a8.51,8.51,0,0,1-8.52,8.52Z"
      />
      <path
        className="cls-1"
        d="M368.6,329.83a8.52,8.52,0,0,1-4.25-1.14l-16.08-9.28a8.51,8.51,0,0,1-3.11-11.64l3.33-5.75a8.51,8.51,0,0,1,11.63-3.12l16.06,9.28a8.52,8.52,0,0,1,3.12,11.63L376,325.57a8.53,8.53,0,0,1-5.17,4A8.77,8.77,0,0,1,368.6,329.83Z"
      />
      <path
        className="cls-1"
        d="M315.53,378a8.77,8.77,0,0,1-2.21-.29,8.51,8.51,0,0,1-5.17-4l-9.28-16.08A8.52,8.52,0,0,1,302,346l5.75-3.32a8.46,8.46,0,0,1,4.26-1.15,8.35,8.35,0,0,1,2.21.3,8.54,8.54,0,0,1,5.17,4l9.27,16.07a8.51,8.51,0,0,1-3.12,11.63l-5.75,3.33A8.45,8.45,0,0,1,315.53,378Z"
      />
      <rect
        className="cls-1"
        x="238.48"
        y="360.2"
        width="23.68"
        height="35.58"
        rx="8.52"
      />
      <path
        className="cls-1"
        d="M184.33,380.2a8.45,8.45,0,0,1-4.26-1.14l-5.76-3.33a8.52,8.52,0,0,1-3.12-11.63L180.47,348a8.47,8.47,0,0,1,5.17-4,8.77,8.77,0,0,1,2.21-.29,8.42,8.42,0,0,1,4.25,1.15l5.76,3.32A8.51,8.51,0,0,1,201,359.87l-9.27,16.07a8.54,8.54,0,0,1-5.18,4A8.75,8.75,0,0,1,184.33,380.2Z"
      />
      <path
        className="cls-1"
        d="M133.6,329a8.51,8.51,0,0,1-7.38-4.25l-3.33-5.77A8.52,8.52,0,0,1,126,307.31L142.08,298a8.53,8.53,0,0,1,11.63,3.13l3.33,5.75a8.52,8.52,0,0,1-3.12,11.64l-16.07,9.27A8.43,8.43,0,0,1,133.6,329Z"
      />
      <rect
        className="cls-1"
        x="104.63"
        y="238.95"
        width="35.58"
        height="23.68"
        rx="8.52"
      />
      <path
        className="cls-1"
        d="M144.87,201.05a8.49,8.49,0,0,1-4.24-1.14l-16.08-9.28A8.53,8.53,0,0,1,121.44,179l3.33-5.75a8.51,8.51,0,0,1,11.63-3.12l16.07,9.27A8.53,8.53,0,0,1,155.58,191l-3.32,5.75A8.51,8.51,0,0,1,144.87,201.05Z"
      />
      <path
        className="cls-1"
        d="M187.05,156.67a8.35,8.35,0,0,1-2.21-.3,8.54,8.54,0,0,1-5.17-4l-9.27-16.07a8.53,8.53,0,0,1,3.12-11.64l5.76-3.32a8.51,8.51,0,0,1,11.63,3.12l9.28,16.07a8.52,8.52,0,0,1-3.13,11.63l-5.75,3.32A8.46,8.46,0,0,1,187.05,156.67Z"
      />
      <path
        className="cls-1"
        d="M261.27,280.54a8.77,8.77,0,0,1-2.21-.29,8.49,8.49,0,0,1-5.17-4l-53.76-93.1a8.51,8.51,0,0,1,3.13-11.63l5.76-3.33a8.53,8.53,0,0,1,4.25-1.15,8.35,8.35,0,0,1,2.21.3,8.52,8.52,0,0,1,5.17,4l53.76,93.1a8.52,8.52,0,0,1-3.13,11.63l-5.75,3.33A8.55,8.55,0,0,1,261.27,280.54Z"
      />
    </svg>
  );
};

const fragileIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>{props.title ? props.title : "Fragile"}</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M268,375.6c-17.35-24.17-6.2-64.84-6.2-64.84s2.48-10.54,8.06-13.63c0,0,31-16.11,49.56-51.43S333,152,313.2,95c0,0-12.46-1.17-27.55-2.19l-29.93,59.62h50.9l-68.45,99.7,23.05-73.84-46.71,6.12L237.4,91.56c-22.85.86-50.6,3.45-50.6,3.45C167,152,162,210.39,180.61,245.7s49.56,51.43,49.56,51.43c5.58,3.09,8.06,13.63,8.06,13.63s11.15,40.67-6.2,64.84-59.48,27.26-59.48,27.26v5.58h154.9v-5.58S285.32,399.76,268,375.6Z"
      />
    </svg>
  );
};

const motorcycleIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Motorcycle</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M139.25,241.47A48.14,48.14,0,0,1,171,253.38L177.1,248c-15.66-14-37.18-19.09-52.66-16.43l-5.69-4.22c3.26-35.75,28.79-54.75,53.12-65.34l8.85,4.21,2.22,11.19,9.48-10.11h19.82v14.25H198.59l-12.23,13,.68,3.46,10.54,7.17L214.76,193a11.12,11.12,0,0,1,5.38-2l37-3.41a11.07,11.07,0,0,1,9.5,3.93l14.91,17.89A11,11,0,0,0,293,213l48.76-13.46a3.31,3.31,0,0,0,2.33-4.06l-3.41-12.82a4.28,4.28,0,0,1,4.16-5.38l55.52.29a2.77,2.77,0,0,1,2.75,2.77V184a2.76,2.76,0,0,1-1.24,2.32L312.51,245.2l14,10.51a48.25,48.25,0,1,1-14,34,48.63,48.63,0,0,1,.91-9.33l-18.25-7.85a59.31,59.31,0,0,0-.15,17.3,20.2,20.2,0,0,1-20,23.08H195.68c2.9-15,2-27.78-1.41-38.5L186,277.85a48.26,48.26,0,1,1-46.78-36.38Zm221.5,70.61a22.35,22.35,0,1,0-13-40.5l12.32,9.22a2.67,2.67,0,0,1,.69,3.49l-6.58,11.06a2.73,2.73,0,0,1-3.42,1.11l-12.27-5.28A22.33,22.33,0,0,0,360.75,312.08Zm-221.5,0a22.35,22.35,0,0,0,22.35-22.35c0-.58,0-1.16-.08-1.73l-16.87,7a2.86,2.86,0,0,1-3.5-1.1l-4.59-7.15a2.78,2.78,0,0,1,.51-3.59l14.05-12.33a22.34,22.34,0,1,0-11.87,41.27Z"
      />
    </svg>
  );
};

const sportsCarIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Sports Car</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M86.78,297.09h15.65a41,41,0,0,0,76.94,0h135.9a41,41,0,0,0,77,0h13.66c22-11.73,34.38-23.7,44.95-40.37A14,14,0,0,0,443,235.78L421,229.3V217.9a7,7,0,0,0-7-7H347.13a14.71,14.71,0,0,0-11.41,5.43l-10.46,12.91H242.17l9.49-11.48,6.16,5.09a3.5,3.5,0,0,0,4.93-.47l2.67-3.24a3.5,3.5,0,0,0-.47-4.93l-21-17.34a3.51,3.51,0,0,0-4.93.47l-2.67,3.24a3.53,3.53,0,0,0,.48,4.94l5.92,4.88L227.2,229.25H198.83v-5.59l31.34-32.09a5.51,5.51,0,0,0,1.58-4.3,5.67,5.67,0,0,0-2.21-4l-8-6a5.61,5.61,0,0,0-6.71,0l-59.26,45c-36.23,1.53-85.13,7.21-105.06,25.94a11.24,11.24,0,0,0-2.44,13.07C53.12,271.68,65.1,291.11,86.78,297.09Zm267,3a17.3,17.3,0,1,1,9.71-3A17.2,17.2,0,0,1,353.75,300.09Zm-92.51-53.77a1.75,1.75,0,0,1,1.75-1.76h14.9a1.75,1.75,0,0,1,1.75,1.76v2.85a1.75,1.75,0,0,1-1.75,1.75H263a1.75,1.75,0,0,1-1.75-1.75ZM140.9,300.09a17.3,17.3,0,1,1,9.71-3A17.17,17.17,0,0,1,140.9,300.09Z"
      />
    </svg>
  );
};

const stopWatchIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <defs>
        <style>.cls-1{"{fill: #fff;}"}</style>
      </defs>
      <title>{props.title ? props.title : "Stop Watch"}</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="cls-1"
        d="M250,403.39a121,121,0,1,1,121-121A121.14,121.14,0,0,1,250,403.39Zm0-213.79a92.79,92.79,0,1,0,92.78,92.79A92.89,92.89,0,0,0,250,189.6Z"
      />
      <rect
        className="cls-1"
        x="327.29"
        y="167.27"
        width="33.17"
        height="45.71"
        rx="3.16"
        transform="translate(235.15 -187.47) rotate(45)"
      />
      <path
        className="cls-1"
        d="M170.82,207.75l2.93-2.94a14.54,14.54,0,0,0,0-20.52L162,172.5a14.56,14.56,0,0,0-20.53,0l-2.93,2.94a14.55,14.55,0,0,0,0,20.52l11.8,11.8A14.56,14.56,0,0,0,170.82,207.75Z"
      />
      <rect
        className="cls-1"
        x="235.99"
        y="140.13"
        width="28.01"
        height="26.5"
      />
      <rect
        className="cls-1"
        x="217.56"
        y="96.61"
        width="64.87"
        height="32.44"
        rx="3.09"
      />
      <path
        className="cls-1"
        d="M250,210.75v71.64H178.36A71.64,71.64,0,1,0,250,210.75Z"
      />
    </svg>
  );
};

const suvIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Suv</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M217.28,295H291a39.22,39.22,0,0,0,78.44-.26c0-.45,0-.89,0-1.33.2-.17.42-.32.61-.51L385.8,277.2a7.18,7.18,0,0,0,2.1-5.08V257.68h-6.36a6.27,6.27,0,0,1-6.27-6.26V237.67a2.35,2.35,0,0,1,2.35-2.34h10.26a13.62,13.62,0,0,0-1.23-5.21L358.1,168.7a4.68,4.68,0,0,0-4.24-2.7H228.59a4.42,4.42,0,0,0-4.1,2.76L200.2,228.2H138.42a8.33,8.33,0,0,0-8.07,6.33l-2.17,8.9h-6.6a9.47,9.47,0,0,0-9.48,9.48v22.86a21.27,21.27,0,0,0,21.27,21.28h5.54a39.22,39.22,0,0,0,78.37-2Zm113,14A14.3,14.3,0,0,1,316,295c0-.09,0-.17,0-.26a14.31,14.31,0,0,1,28.62,0c0,.09,0,.17,0,.26A14.29,14.29,0,0,1,330.25,309.08ZM304.71,187a3,3,0,0,1,3-3H340a4.33,4.33,0,0,1,4,2.78l11.43,29.75a2,2,0,0,1-1.91,2.77H307.69a3,3,0,0,1-3-3Zm-19.38,58.64H266.28V238h19.05ZM227,216.54l11.94-29.79A4.44,4.44,0,0,1,243,184h37.09a3,3,0,0,1,3,3v29.35a3,3,0,0,1-3,3H228.82A2,2,0,0,1,227,216.54Zm-63,80.51a15.33,15.33,0,0,1-.19-2.27,14.36,14.36,0,1,1,.19,2.27Z"
      />
    </svg>
  );
};

const truck2Icon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Truck 2</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M65.82,262.13h9a2.72,2.72,0,0,0,2.72-2.72V227.34a4.37,4.37,0,0,1,2.86-4.1l32.91-12.15a4.35,4.35,0,0,0,1.94-1.41l36.51-46.79a6.91,6.91,0,0,1,5.45-2.66h78.45a6.92,6.92,0,0,1,6.91,6.92v58.26H417.77a8.34,8.34,0,0,1,8.25,8.43v35h6.72a4.26,4.26,0,0,1,4.13,5.29l-4.17,16.76a7,7,0,0,1-6.79,5.29H395.3c0,.72.06,1.45.06,2.17a41.47,41.47,0,0,1-82.94,0c0-.72,0-1.45.05-2.17H188.34c0,.72.06,1.45.06,2.17a41.48,41.48,0,0,1-82.95,0c0-.72,0-1.45.06-2.17H68.45A5.45,5.45,0,0,1,63,290.68V265A2.82,2.82,0,0,1,65.82,262.13Zm269.6,36.17a18.47,18.47,0,1,0,36.93,0,19.44,19.44,0,0,0-.14-2.17,18.45,18.45,0,0,0-36.65,0A19.44,19.44,0,0,0,335.42,298.3ZM201.06,217.61h17.18a1.44,1.44,0,0,0,1.45-1.44V178.8a1.91,1.91,0,0,0-1.91-1.9H201.06Zm-11.86-1.44V178.8a1.9,1.9,0,0,0-1.9-1.9H169.68a3.5,3.5,0,0,0-2.78,1.37L138.64,215a1.6,1.6,0,0,0,1.27,2.57h47.85A1.43,1.43,0,0,0,189.2,216.17ZM128.46,298.3a18.47,18.47,0,1,0,36.93,0,17.32,17.32,0,0,0-.14-2.17,18.45,18.45,0,0,0-36.65,0A19.44,19.44,0,0,0,128.46,298.3Z"
      />
    </svg>
  );
};

const vanSuvIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <title>Van-Suv</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="icon"
        d="M129.91,332a35.78,35.78,0,0,0,32.6-21h184a35.78,35.78,0,0,0,65.2,0h28.76A4.57,4.57,0,0,0,445,306.4V287.64a4.58,4.58,0,0,0-4.57-4.59H438v-9.64c0-1.26,0-2.51-.06-3.79a161.14,161.14,0,0,0-25.35-83l-9.47-14.76a8.39,8.39,0,0,0-7-3.86H218.61a37.16,37.16,0,0,0-26.29,10.9l-36.95,36.93-69.56,16a10,10,0,0,0-6.57,5.08l-2.83,5.44-12,23.09a18.65,18.65,0,0,0-2.11,8.63v9H59.58A4.58,4.58,0,0,0,55,287.64V306.4A4.56,4.56,0,0,0,59.58,311H97.32A35.78,35.78,0,0,0,129.91,332Zm0-20.72a14.35,14.35,0,0,1-3-.31,15.06,15.06,0,1,1,6.08,0A14.54,14.54,0,0,1,129.91,311.28Zm120.22-57.89h-16V247h16Zm67.93,0h-16V247h16Zm61,57.89A14.54,14.54,0,0,1,376,311a15.06,15.06,0,1,1,6.09,0A14.65,14.65,0,0,1,379.08,311.28Zm46.63-67.87A120.77,120.77,0,0,1,428.36,266h-.61a11.37,11.37,0,0,1-2-22.56Zm-43.06-49.84,6.65,11.19a15.55,15.55,0,0,1-13.37,23.49H332.26V188.33h41.18A10.72,10.72,0,0,1,382.65,193.57Zm-64.59,34.68H261.49V188.33h56.57Zm-134-4.22,30.29-28.93a24.44,24.44,0,0,1,16.9-6.77h15.37v39.92H185.75A2.45,2.45,0,0,1,184.06,224ZM92.45,262.15A4.87,4.87,0,0,1,87.58,267H76a.92.92,0,0,1-.82-1.35l9.29-17.8a2.52,2.52,0,0,1,2.23-1.36h4.27A1.53,1.53,0,0,1,92.45,248Z"
      />
    </svg>
  );
};

const weightIcon = (props: IconComponentProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <defs>
        <style>.cls-1{"{fill: #fff;}"}</style>
      </defs>
      <title>{props.title ? props.title : "Weight"}</title>
      <circle className={props.backgroundClass} cx="250" cy="250" r="250" />
      <path
        className="cls-1"
        d="M253.43,248.37h-6.86a51.56,51.56,0,0,1-51.51-51.5V185a51.57,51.57,0,0,1,51.51-51.51h6.86A51.57,51.57,0,0,1,304.94,185v11.86A51.56,51.56,0,0,1,253.43,248.37Zm-6.86-90.63A27.3,27.3,0,0,0,219.3,185v11.86a27.29,27.29,0,0,0,27.27,27.26h6.86a27.29,27.29,0,0,0,27.27-27.26V185a27.3,27.3,0,0,0-27.27-27.27Z"
      />
      <path
        className="cls-1"
        d="M376.53,344.1,347,214.58a18.32,18.32,0,0,0-17.86-14.25H170.88A18.32,18.32,0,0,0,153,214.58L123.47,344.1a18.32,18.32,0,0,0,17.86,22.4H358.67A18.32,18.32,0,0,0,376.53,344.1Zm-147.67-27L209.49,284,198,295.82V317.1H183.74V246.66H198v31.28l28.73-31.28h19.13L219.3,274.1l28,43ZM318,307.4q-4.47,4.32-13,7.61a47,47,0,0,1-17.18,3.29,38.57,38.57,0,0,1-19.27-4.63,29.46,29.46,0,0,1-12.35-13.27,42.87,42.87,0,0,1-4.13-18.76,40.66,40.66,0,0,1,4.61-19.56A31.07,31.07,0,0,1,270.28,249q6.77-3.51,16.86-3.51,13.13,0,20.5,5.5a24.89,24.89,0,0,1,9.49,15.21L303,268.81a14.88,14.88,0,0,0-5.6-8.19,16.94,16.94,0,0,0-10.26-3q-9.31,0-14.82,5.91t-5.5,17.53q0,12.56,5.57,18.82A18.63,18.63,0,0,0,287,306.15a24.5,24.5,0,0,0,9-1.76,30.56,30.56,0,0,0,7.71-4.25V291.2H287.38V279.33H318Z"
      />
    </svg>
  );
};

const defaultIcon = () => {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <title>Question Mark</title>
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke="black"
        strokeWidth="8"
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="40"
        fontFamily="Arial"
      >
        ?
      </text>
    </svg>
  );
};

const icons = (props: IconComponentProps) => ({
  arrow: () => arrowIcon(props),
  bus: () => busIcon(props),
  car: () => carIcon(props),
  check: () => checkIcon(),
  close: () => closeIcon(),
  copy: () => copyIcon(),
  diskette: () => disketteIcon(props),
  download: () => downloadIcon(),
  drag: () => dragIcon(),
  edit: () => editIcon(),
  filters: () => filtersIcon(props),
  iO: () => iOIcon(),
  link: () => linkIcon(),
  options: () => optionsIcon(),
  settings: () => settingsIcon(),
  trashcan: () => trashcanIcon(),
  truck: () => truckIcon(props),
  pickup: () => pickupIcon(props),
  upload: () => uploadIcon(),
  user: () => userIcon(),
  van: () => vanIcon(props),
  car2: () => car2Icon(props),
  clock: () => clockIcon(props),
  fragile: () => fragileIcon(props),
  motorcycle: () => motorcycleIcon(props),
  sportscar: () => sportsCarIcon(props),
  stopwatch: () => stopWatchIcon(props),
  suv: () => suvIcon(props),
  truck2: () => truck2Icon(props),
  vansuv: () => vanSuvIcon(props),
  weight: () => weightIcon(props),
  default: () => defaultIcon(),
});

type IconName = keyof ReturnType<typeof icons>;

type IconComponentProps = {
  name?: IconName;
  iconClass?: string;
  backgroundClass?: string;
  title?: string;
};

const IconComponent = (props: IconComponentProps) => {
  return props.name && Object.values(iconNameEnum).includes(props.name) ? (
    <>{icons(props)[props.name]()}</>
  ) : (
    <>{icons(props).default()}</>
  );
};

export default IconComponent;

export const iconNameEnum: Record<string, IconName> = {
  ARROW: "arrow",
  BUS: "bus",
  CAR: "car",
  CHECK: "check",
  CLOSE: "close",
  COPY: "copy",
  DISKETTE: "diskette",
  DOWNLOAD: "download",
  DRAG: "drag",
  EDIT: "edit",
  FILTERS: "filters",
  IO: "iO",
  LINK: "link",
  OPTIONS: "options",
  SETTINGS: "settings",
  TRASHCAN: "trashcan",
  TRUCK: "truck",
  PICKUP: "pickup",
  UPLOAD: "upload",
  USER: "user",
  VAN: "van",
  CAR2: "car2",
  CLOCK: "clock",
  FRAGILE: "fragile",
  MOTORCYCLE: "motorcycle",
  SPORTSCAR: "sportscar",
  STOPWATCH: "stopwatch",
  SUV: "suv",
  TRUCK2: "truck2",
  VANSUV: "vansuv",
  WEIGHT: "weight",
};
