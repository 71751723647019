export const CURRENT_EU_URL_PART = "eu-oms";

export const OrderState = {
  Processing: 0,
  ValidationFailed: 1,
  Error: 2,
  MappingDone: 3,
  ApiCreated: 4,
  Duplicated: 5,
  Updated: 6,
  Ignored: 7,
  Pending: 8,
  Assigned: 9,
  Pause: 15,
  Invisible: 16,
};

export const ShipmentStates = [
  { key: 0, text: "Draft - OMS", value: 0 },
  { key: 1, text: "Exported - OMS", value: 1 },
];

export const TurvoShipmentStatus = [
  { key: 2120, text: "Draft - Turvo", value: 2120 },
  { key: 2117, text: "Tender - offered - Turvo", value: 2117 },
  { key: 2118, text: "Tender - accepted - Turvo", value: 2118 },
  { key: 2119, text: "Tender - rejected - Turvo", value: 2119 },
  { key: 2101, text: "Tendered - Turvo", value: 2101 },
  { key: 2102, text: "Covered - Turvo", value: 2102 },
  { key: 2103, text: "Dispatched - Turvo", value: 2103 },
  { key: 2104, text: "At pickup - Turvo", value: 2104 },
  { key: 2115, text: "Picked up - Turvo", value: 2115 },
  { key: 2105, text: "En route - Turvo", value: 2105 },
  { key: 2106, text: "At delivery - Turvo", value: 2106 },
  { key: 2107, text: "Delivered - Turvo", value: 2107 },
  { key: 2116, text: "Route complete - Turvo", value: 2116 },
  { key: 2108, text: "Ready for billing - Turvo", value: 2108 },
  { key: 2109, text: "Processing - Turvo", value: 2109 },
  { key: 2112, text: "Completed - Turvo", value: 2112 },
  { key: 2113, text: "Canceled - Turvo", value: 2113 },
];
