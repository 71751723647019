import { PropsWithChildren } from "react";
import { Button, Modal } from "semantic-ui-react";

type WarningModalProps = {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
};

const WarningModal = (props: PropsWithChildren<WarningModalProps>) => {
  return (
    <Modal open={props.open} onClose={props.onCancel} closeIcon>
      <Modal.Content>{props.children}</Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={props.onCancel} content="Cancel" />
        <Button primary onClick={props.onContinue} content="Continue" />
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
