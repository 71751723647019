import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { memo, useRef, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import { ModifiedShipmentObject } from "../models/ShipmentObject";
import locationFormatter from "../utils/USStatesProvider";

const containerStyle = {
  width: "100%",
  height: "400px",
};

type MapComponentProps = {
  shipment: ModifiedShipmentObject;
};

type MarkerType = {
  type: "P" | "D";
  sequence: number | null;
  place: google.maps.LatLng;
  title: string;
};

const MapComponent = (props: MapComponentProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_APP_GOOGLE_API_KEY ?? "",
  });

  const mapRef = useRef<google.maps.Map | null>(null);

  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [routes, setRoutes] = useState<google.maps.DirectionsResult[]>([]);

  const loadRoutes = async () => {
    const directionsService = new google.maps.DirectionsService();
    const bounds = new google.maps.LatLngBounds();

    const newMarkers = props.shipment.routes.map<MarkerType>(
      ({ location, type, sequence }) => {
        const place = new google.maps.LatLng(location.lat, location.lon);

        bounds.extend(place);

        return {
          type: type === 0 ? "P" : "D",
          sequence,
          place,
          title: `${locationFormatter.toTitleCaseFrom(location.city)}, ${location.state}`
        };
      }
    );

    const routeRequests = props.shipment.routes
      .slice(0, -1)
      .map((route, index) => {
        const origin = route.location;
        const destination = props.shipment.routes[index + 1].location;

        return directionsService.route({
          origin: new google.maps.LatLng(origin.lat, origin.lon),
          destination: new google.maps.LatLng(destination.lat, destination.lon),
          travelMode: google.maps.TravelMode.DRIVING,
        });
      });

    const results = await Promise.allSettled(routeRequests);

    const newRoutes = results.reduce<google.maps.DirectionsResult[]>(
      (acc, result) => {
        if (result.status === "fulfilled") {
          if (result.value.status === "OK") {
            acc.push(result.value);
          } else {
            console.error(`error fetching directions ${result.value}`);
          }
        } else {
          console.error(result.reason);
        }

        return acc;
      },
      []
    );

    mapRef.current?.fitBounds(bounds);

    setMarkers(newMarkers);
    setRoutes(newRoutes);
  };

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    loadRoutes();
  };

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} onLoad={onLoad}>
      <Dimmer inverted active={!markers.length}>
        <Loader>Loading...</Loader>
      </Dimmer>
      {markers.map((route, index) => (
        <Marker
          zIndex={index}
          key={route.sequence}
          label={{
            text: `${route.type}${route.sequence}`,
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
          title={route.title} // city state value for hover tooltip
          position={route.place}
          icon={{
            path: "m 0 -7 c-3.87 0-7 3.13-7 7 0 1.74 0.5 3.37 1.41 4.84 0.95 1.54 2.2 2.86 3.16 4.4 0.47 0.75 0.81 1.45 1.17 2.26 0.26 0.55 0.47 1.5 1.26 1.5 s 1-0.95 1.25-1.5 c 0.37-0.81 0.7-1.51 1.17-2.26 0.96-1.53 2.21-2.85 3.16-4.4 0.92-1.47 1.42-3.1 1.42-4.84 0-3.87-3.13-7-7-7",
            rotation: 0,
            scale: 2,
            anchor: new google.maps.Point(0, 13),
            fillColor: route.type === "P" ? "green" : "red",
            fillOpacity: 1,
            strokeWeight: 0,
          }}
        />
      ))}
      {routes.map((route, index) => (
        <DirectionsRenderer
          key={index}
          routeIndex={index}
          options={{
            suppressMarkers: true,
            preserveViewport: true,
          }}
          directions={route}
        />
      ))}
    </GoogleMap>
  ) : null;
};

export default memo(MapComponent);
