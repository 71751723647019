import { VEHICLE_TYPE_KEY } from "..";
import { OrderFormObject } from "../models/OrderFormObject";

const getDefaultOrder = (): OrderFormObject => {
  return {
    id: 0,
    customer: {
      id: null,
      name: "",
      statusName: "",
    },
    externalId: {
      type: {
        key: "",
        value: "",
      },
      value: "",
    },
    item: {
      itemCategory: {
        key: VEHICLE_TYPE_KEY,
        value: "Vehicle",
      },
      name: "",
      qty: "",
      make: "",
      model: "",
      year: "",
      identityNumber: "",
      itemNumber: "",
      notes: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      wheelbase: "",
    },
    equipment: {
      type: {
        key: "",
        value: "",
      },
    },
    pickup: {
      name: "",
      formatted: "",
      shortFormatted: "",
      city: "",
      state: "",
      zip: "",
      address: "",
      lat: 0,
      lon: 0,
      poNumber: "",
      appointment: {
        scheduling: "9400",
        startDate: null,
        endDate: null,
        timezone: "",
      },
      notes: "",
    },
    delivery: {
      name: "",
      formatted: "",
      shortFormatted: "",
      city: "",
      state: "",
      zip: "",
      address: "",
      lat: 0,
      lon: 0,
      poNumber: "",
      appointment: {
        scheduling: "9400",
        startDate: null,
        endDate: null,
        timezone: "",
      },
      notes: "",
    },
    costs: [
      {
        id: crypto.randomUUID(),
        code: {
          key: "",
          value: "",
        },
        qty: "",
        note: "",
        price: "",
        amount: "",
      },
    ],
  };
};

export default getDefaultOrder;
