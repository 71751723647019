import { Grid, Progress, Dimmer, Container } from 'semantic-ui-react';
import { useAppSelector } from '../../store/hooks';

const OrderPanelDimmer = () => {
  const isBuilding = useAppSelector((state) => state.autoBuild.isBuilding);
  const buildItems = useAppSelector((state) => state.autoBuild.buildItems);
  const buildItemsTotal = useAppSelector(
    (state) => state.autoBuild.buildItemsTotal
  );

  return (
    <Dimmer active={isBuilding} inverted>
      <Grid centered>
        <Grid.Column>
          <Container>
            <Progress
              progress='percent'
              size='big'
              color='teal'
              percent={Math.ceil((buildItems.length * 100) / buildItemsTotal)}
              active>
              <label>{`${buildItems.length} of ${buildItemsTotal} Shipments processed`}</label>
            </Progress>
          </Container>
        </Grid.Column>
      </Grid>
    </Dimmer>
  );
};

export default OrderPanelDimmer;
