import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { isEqual } from "lodash-es";
import { OrdersFilter } from "../models/Filter";
import { HttpResponsePagination } from "../models/HttpResponse";
import { OrderAdditionalData } from "../models/OrderAdditionalData";
import { OrderObject } from "../models/OrderObject";
import { mapFilterObject } from "../modules/OrderPanel/ApiOrderDataMapper";
import { defaultFilter } from "../modules/OrderPanel/redux/initialState";
import httpHandler from "../utils/httpHandler";
import { RootState } from "./store";
import { FetchStatus } from "../types/FetchStatus";
import { OrderPostObject } from "../modules/OrderPanel/Modals/OrderPanelOrderDetailsModal/models/OrderPostObject";

export const getOrders = createAsyncThunk<
  HttpResponsePagination<OrderObject[]>,
  undefined,
  { rejectValue: string; state: RootState }
>(
  "orders/getOrders",
  async (_, { rejectWithValue, getState }) => {
    const { filter } = getState().orders;
    const filterForApi = mapFilterObject(filter);
    try {
      const response = await httpHandler.instanceAxios.post<
        HttpResponsePagination<OrderObject[]>
      >(`${import.meta.env.VITE_SL3_URL}/api/order/list`, filterForApi);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
  {
    condition: (_, { getState }) => {
      return !getState().orders.isDefaultFilter;
    },
  }
);

export const refreshOrders = createAsyncThunk<
  HttpResponsePagination<OrderObject[]>,
  undefined,
  { rejectValue: string; state: RootState }
>("orders/refreshOrders", async (_, { rejectWithValue, getState }) => {
  const { orders, filter } = getState().orders;
  const filterForApi = mapFilterObject(filter);
  try {
    const pageSize =
      orders.length > filter.pageSize
        ? Math.ceil(orders.length / filter.pageSize) * filter.pageSize
        : filter.pageSize;

    const response = await httpHandler.instanceAxios.post<
      HttpResponsePagination<OrderObject[]>
    >(`${import.meta.env.VITE_SL3_URL}/api/order/list`, {
      ...filterForApi,
      page: 1,
      pageSize,
    });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export const selectAllOrders = createAsyncThunk<
  HttpResponsePagination<OrderObject[]>,
  undefined,
  { rejectValue: string; state: RootState }
>("orders/selectAllOrders", async (_, { rejectWithValue, getState }) => {
  const { totalCount, orders, filter } = getState().orders;

  if (orders.length < totalCount) {
    const filterForApi = mapFilterObject(filter);
    try {
      const response = await httpHandler.instanceAxios.post<
        HttpResponsePagination<OrderObject[]>
      >(`${import.meta.env.VITE_SL3_URL}/api/order/list`, {
        ...filterForApi,
        page: 1,
        pageSize: totalCount,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  } else {
    return {
      totalPages: 1,
      total: totalCount,
      data: orders,
    } as HttpResponsePagination<OrderObject[]>;
  }
});

export const deleteOrders = createAsyncThunk<
  string,
  number[],
  { rejectValue: { message: string; id?: number } }
>("orders/deleteOrders", async (ids, { rejectWithValue }) => {
  try {
    for (const id of ids) {
      await httpHandler.instanceAxios.post("/api/order/delete", {
        id: id,
      });
    }
    return "deleteComplete";
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue({
      message: error.message,
      id: ids.length === 1 ? ids[0] : undefined,
    });
  }
});

export const toggleOrderState = createAsyncThunk<
  OrderObject,
  OrderObject,
  { rejectValue: string }
>("orders/toggleOrderState", async (order, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<OrderObject>(
      "/api/order/changeState",
      {
        id: order.id,
        state: order.state,
      }
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export const fetchAdditionalData = createAsyncThunk(
  "orders/fetchAdditionalData",
  async (order, { rejectWithValue }) => {
    try {
      const response = await httpHandler.instanceAxios.get<OrderAdditionalData>(
        "/api/order/getAdditionalData"
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const createOrder = createAsyncThunk<
  OrderObject,
  OrderPostObject,
  { rejectValue: string }
>("orders/createOrder", async (order, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<OrderObject>(
      "/api/order/create",
      order
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export const updateOrder = createAsyncThunk<
  OrderObject,
  OrderPostObject,
  { rejectValue: string }
>("orders/updateOrder", async (order, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<OrderObject>(
      "/api/order/update",
      order
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface OrderForm {
  status: string;
  errors: string | undefined;
  orderId: number | null;
  success: boolean;
  open: boolean;
}

export interface OrderState {
  additionalData: OrderAdditionalData;
  filter: OrdersFilter;
  form: OrderForm;
  isDefaultFilter: boolean;
  vehicleModalOpen: boolean;
  orders: OrderObject[];
  selectedOrderIds: number[];
  status: FetchStatus;
  lastGetRequestId: string;
  formStatus: FetchStatus;
  additionalDataStatus: FetchStatus;
  totalPages: number;
  totalCount: number;
  totalFilteredCount: number;
}

export const initialState: OrderState = {
  additionalData: {
    costLineItemTypes: [],
    currencies: [],
    dimensionUnits: [],
    equipmentTypes: [],
    externalIdTypes: [],
    itemCategoryTypes: [],
    itemUnitTypes: [],
    weightUnits: [],
  },
  filter: defaultFilter,
  form: {
    status: "",
    errors: "",
    orderId: null,
    success: false,
    open: false,
  },
  isDefaultFilter: true,
  vehicleModalOpen: false,
  orders: [],
  selectedOrderIds: [],
  status: "",
  lastGetRequestId: "",
  formStatus: "",
  additionalDataStatus: "",
  totalPages: 0,
  totalCount: 0,
  totalFilteredCount: 0,
};

const orderSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<OrdersFilter>) => {
      state.filter = action.payload;
      state.isDefaultFilter = isEqual(action.payload, defaultFilter);
      state.orders = [];
      state.totalCount = 0;
      state.totalPages = 0;
      state.totalFilteredCount = 0;
    },
    nextPage: (state) => {
      state.filter.page = state.filter.page + 1;
    },
    addSelected: (state, action: PayloadAction<number>) => {
      const newOrderIds = state.selectedOrderIds.concat(action.payload);
      state.selectedOrderIds = newOrderIds;
    },
    addMultipleSelected: (state, action: PayloadAction<number[]>) => {
      const newOrderIds = state.selectedOrderIds.concat(action.payload);
      state.selectedOrderIds = newOrderIds;
    },
    removeSelected: (state, action: PayloadAction<number>) => {
      const index = state.selectedOrderIds.indexOf(action.payload);
      state.selectedOrderIds.splice(index, 1);
    },
    removeMultipleSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedOrderIds = state.selectedOrderIds.filter(
        (orderId) => !action.payload.includes(orderId)
      );
    },
    removeAllSelected: (state) => {
      state.selectedOrderIds = [];
    },
    removeAllOrders: (state) => {
      state.totalPages = 0;
      state.totalCount = 0;
      state.totalFilteredCount = 0;
      state.orders = [];
    },
    setDefaultFilter: (state) => {
      state.filter = defaultFilter;
      state.isDefaultFilter = true;
      state.totalCount = 0;
      state.totalPages = 0;
      state.totalFilteredCount = 0;
    },
    removeOrderItem: (state, action: PayloadAction<number>) => {
      const orders = state.orders.filter((el) => el.id !== action.payload);
      state.orders = orders;
      state.totalCount = state.totalCount - 1;
      state.totalFilteredCount = state.totalFilteredCount - 1;
    },
    removeMultipleOrderItems: (state, action: PayloadAction<number[]>) => {
      const orders = state.orders.filter(
        (el) => !action.payload.includes(el.id)
      );
      const removedOrderCount = state.orders.length - orders.length;
      state.orders = orders;
      state.totalCount = state.totalCount - removedOrderCount;
      state.totalFilteredCount = state.totalFilteredCount - removedOrderCount;
    },
    removeShipmentOrderItems: (state, action: PayloadAction<OrderObject[]>) => {
      state.orders = action.payload.concat(state.orders);
      state.totalCount = state.totalCount + action.payload.length;
      state.totalFilteredCount =
        state.totalFilteredCount + action.payload.length;
      state.isDefaultFilter = isEqual(state.filter, defaultFilter);
    },
    openOrderUpdateForm: (state, action: PayloadAction<number>) => {
      state.form.orderId = action.payload;
      state.form.open = true;
    },
    closeOrderUpdateForm: (state) => {
      state.form.open = false;
      state.form.orderId = null;
      state.form.success = false;
      state.form.errors = "";
    },
    openVehicleModal: (state) => {
      state.vehicleModalOpen = true;
    },
    closeVehicleModal: (state) => {
      state.vehicleModalOpen = false;
    },
    receiveOrderCreationErrors: (state, action: PayloadAction<string>) => {
      state.form.errors = action.payload;
    },
    receiveOrderCreationSuccess: (state) => {
      state.form.success = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.status = "pending";
        state.lastGetRequestId = action.meta.requestId;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          if (state.filter.page === 1) {
            state.orders = action.payload.data;
          } else {
            state.orders = state.orders.concat(
              action.payload.data.filter(
                (incomingOrder) =>
                  !state.orders.find(
                    (existingOrder) => existingOrder.id === incomingOrder.id
                  )
              )
            );
          }
          state.status = "fulfilled";
          state.totalPages = action.payload.totalPages;
          state.totalCount = action.payload.total;
          state.totalFilteredCount = state.orders?.length ?? 0;
        }
      })
      .addCase(getOrders.rejected, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "rejected";
        }
      })
      .addCase(refreshOrders.pending, (state, action) => {
        state.status = "pending";
        state.lastGetRequestId = action.meta.requestId;
      })
      .addCase(refreshOrders.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "fulfilled";
          state.orders = action.payload.data;
          // totalPages in response is based on a different page size, so we calculate it manually
          state.totalPages = Math.ceil(
            action.payload.total / state.filter.pageSize
          );
          state.filter.page = Math.ceil(
            state.orders.length / state.filter.pageSize
          );
          state.totalCount = action.payload.total;
          state.totalFilteredCount = state.orders?.length ?? 0;
        }
      })
      .addCase(refreshOrders.rejected, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "rejected";
        }
      })
      .addCase(selectAllOrders.pending, (state, action) => {
        state.status = "pending";
        state.lastGetRequestId = action.meta.requestId;
      })
      .addCase(selectAllOrders.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "fulfilled";
          state.filter.page = 1;

          const updatedOrders = action.payload;
          state.orders = updatedOrders.data;
          state.totalFilteredCount = updatedOrders.total;
          state.totalCount = updatedOrders.total;
          state.totalPages = updatedOrders.totalPages;
          state.selectedOrderIds = updatedOrders.data.map((order) => order.id);
        }
      })
      .addCase(selectAllOrders.rejected, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "rejected";
        }
      })
      .addCase(toggleOrderState.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        toggleOrderState.fulfilled,
        (state, action: PayloadAction<OrderObject>) => {
          const orderIndex = state.orders.findIndex(
            (order) => order.id === action.payload.id
          );
          if (orderIndex > -1) {
            state.orders[orderIndex].state = action.payload.state;
          }
          state.status = "fulfilled";
        }
      )
      .addCase(toggleOrderState.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(fetchAdditionalData.pending, (state) => {
        state.additionalDataStatus = "pending";
      })
      .addCase(
        fetchAdditionalData.fulfilled,
        (state, action: PayloadAction<OrderAdditionalData>) => {
          state.additionalDataStatus = "fulfilled";
          state.additionalData = action.payload;
        }
      )
      .addCase(fetchAdditionalData.rejected, (state) => {
        state.additionalDataStatus = "rejected";
      })
      .addCase(createOrder.pending, (state) => {
        state.formStatus = "pending";
      })
      .addCase(createOrder.fulfilled, (state) => {
        state.formStatus = "fulfilled";
        state.form.success = true;
        state.form.errors = "";
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.formStatus = "rejected";
        state.form.errors = action.error.message;
      })
      .addCase(updateOrder.pending, (state) => {
        state.formStatus = "pending";
      })
      .addCase(updateOrder.fulfilled, (state) => {
        state.formStatus = "fulfilled";
        state.form.success = true;
        state.form.errors = "";
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.formStatus = "rejected";
        state.form.errors = action.error.message;
      });
  },
});

export const {
  changeFilter,
  nextPage,
  addSelected,
  addMultipleSelected,
  removeSelected,
  removeMultipleSelected,
  removeAllSelected,
  removeAllOrders,
  setDefaultFilter,
  removeOrderItem,
  removeMultipleOrderItems,
  removeShipmentOrderItems,
  openOrderUpdateForm,
  closeOrderUpdateForm,
  openVehicleModal,
  closeVehicleModal,
  receiveOrderCreationErrors,
  receiveOrderCreationSuccess,
} = orderSlice.actions;

export default orderSlice.reducer;
