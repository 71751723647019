import { ModifiedShipmentObject } from "../models/ShipmentObject";

const getDefaultShipment = (): ModifiedShipmentObject => ({
  id: crypto.randomUUID(),
  loading: false,
  customerId: null,
  resourceLink: null,
  turvoShipmentCustomId: null,
  turvoShipmentStatus: null,
  isUnsaved: false,
  isRouteUnoptimized: false,
  pickup: {
    id: null,
    name: "",
    address: null,
    city: "",
    state: null,
    zip: null,
    country: null,
    lat: 0,
    lon: 0,
    notes: "",
    poNumbers: "",
    formatted: null,
    shortFormatted: null,
    appointment: null,
    plannedAppointment: null,
    originalAppointment: null,
    phones: null,
  },
  delivery: {
    id: null,
    name: "",
    address: null,
    city: "",
    state: null,
    zip: null,
    country: null,
    lat: 0,
    lon: 0,
    notes: "",
    poNumbers: "",
    formatted: null,
    shortFormatted: null,
    appointment: null,
    plannedAppointment: null,
    originalAppointment: null,
    phones: null,
  },
  state: 0,
  actionFlow: 0,
  costAmount: 0,
  totalWeight: 0,
  routes: [],
  groupIds: [],
  items: [],
});

export default getDefaultShipment;
