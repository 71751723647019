import { OrdersFilter } from "../../../models/Filter";

export const defaultFilter: OrdersFilter = {
  customerId: "",
  deliveryDate: "",
  destination: "",
  origin: "",
  pickupDate: "",
  search: "",
  page: 1,
  pageSize: 20,
  itemStates: "active",
  vinAge: "",
  vinPriority: [],
  vinEquality: "",
};
