const locationFormatter = {
  toTitleCaseFrom: (city: string | null) => {
    if (!city) return "";
    return city
      .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
      .replace(/(\b[a-z](?!\s))/g, (match) => match.toUpperCase());
  },

  abbreviate: (city: string | null, digit = 8) => {
    if (!city) {
      city = "";
    }
    const formattedCity =
      city.length > digit ? `${city.substring(0, digit)}.` : city;
    return formattedCity;
  },
};

export { locationFormatter as default };
