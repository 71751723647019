import { Grid, Header, Segment } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import Vehicle from "../../ShipmentPanel/Vehicle/Vehicle";
import { OrderObject } from "../../../models/OrderObject";
import { addSelected } from "../../../store/orderSlice";

const OrderItemRow = () => {
  const dispatch = useAppDispatch();

  const orders = useAppSelector((state) => state.orders.orders);

  const selectedOrderIds = useAppSelector(
    (state) => state.orders.selectedOrderIds
  );

  const isDefaultFilter = useAppSelector(
    (state) => state.orders.isDefaultFilter
  );

  const onDragStart = (event: React.DragEvent, order: OrderObject) => {
    const data = {
      orderId: order.id,
      vehicle: { id: order.id },
    };
    if (!selectedOrderIds.includes(order.id)) {
      dispatch(addSelected(order.id));
    }
    event.dataTransfer.setData("orderItem", JSON.stringify(data));
  };

  return (
    <Grid columns={2}>
      {orders.length > 0 ? (
        orders.map((vehicle) => (
          <Grid.Column key={vehicle.id}>
            <div draggable onDragStart={(e) => onDragStart(e, vehicle)}>
              <Vehicle
                vehicle={vehicle}
                isOrder
                selectedOrderIds={selectedOrderIds}
              />
            </div>
          </Grid.Column>
        ))
      ) : (
        <Grid.Row columns={1}>
          <Grid.Column>
            <Segment placeholder inverted color="blue">
              <Header>
                {isDefaultFilter
                  ? "Please choose a filter"
                  : "No data for the filter."}
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default OrderItemRow;
