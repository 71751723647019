import allyLogoSource from "../img/Ally.png";
import carMaxLogoSource from "../img/CarMax.png";
import fordLogoSource from "../img/Ford.png";
import gMLogoSource from "../img/GM.png";
import nissanLogoSource from "../img/Nissan.png";
import gefco from "../img/Gefco.png";
import styled from "styled-components";

const CustomerLabel = styled.span`
  padding-right: 3px;
`;

type CompanyLogoComponentProps = {
  logoClassName: string;
  logoSource: string;
  companyName: string;
};

const imageSourceSelector = (companyName: string) => {
  switch (companyName) {
    case "Ally":
      return allyLogoSource;
    case "CarMax":
      return carMaxLogoSource;
    case "Ford":
      return fordLogoSource;
    case "General Motors":
      return gMLogoSource;
    case "Nissan":
      return nissanLogoSource;
    case "GEFCO":
    case "GEFCO - EDI":
      return gefco;
    default:
      return "";
  }
};

const CompanyLogoComponent = (props: CompanyLogoComponentProps) => {
  const customerLogo =
    props.logoSource || imageSourceSelector(props.companyName);

  return customerLogo ? (
    <img
      className={`${props.logoClassName} oms-icon`}
      src={customerLogo}
      alt="logo"
      title={props.companyName}
    />
  ) : (
    <CustomerLabel>{props.companyName}</CustomerLabel>
  );
};

export default CompanyLogoComponent;
