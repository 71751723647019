import { useContext, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Form,
  Grid,
  Input,
  Popup,
} from "semantic-ui-react";

import ShipmentBuilderStep from "../../models/ShipmentBuilderStep";
import { useAppSelector } from "../../store/hooks";
import AppContext from "../Main/AppContext";
import { ShipmentBuilderFormObject } from "../../models/ShipmentBuilderFormObject";
import ShipmentBuilderFormContext from "./context/ShipmentBuilderFormContext";

export type ShipmentBuilderFormProps = {
  step: ShipmentBuilderStep;
  shipmentBuildId: number;
};

const maxNoOfStopsOptions: DropdownItemProps[] = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: 3, value: 3, text: 3 },
  { key: 4, value: 4, text: 4 },
  { key: 5, value: 5, text: 5 },
];

const unitsOptions: DropdownItemProps[] = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: 3, value: 3, text: 3 },
  { key: 4, value: 4, text: 4 },
  { key: 5, value: 5, text: 5 },
  { key: 6, value: 6, text: 6 },
  { key: 7, value: 7, text: 7 },
  { key: 8, value: 8, text: 8 },
  { key: 9, value: 9, text: 9 },
  { key: 10, value: 10, text: 10 },
];

const ShipmentBuilderForm = (props: ShipmentBuilderFormProps) => {
  const appContext = useContext(AppContext);
  const { formObject, setFormObject } = useContext(ShipmentBuilderFormContext);

  const [buildIdIsCopied, setBuildIdIsCopied] = useState(false);

  const vehicles = useAppSelector((state) => state.vehicle.vehicles);

  const updateFormObject = (
    partialFormObject: Partial<ShipmentBuilderFormObject>
  ) => {
    setFormObject((formObject) => ({ ...formObject, ...partialFormObject }));
  };

  const isSingleSelected = useMemo(() => {
    const single = vehicles.find(
      (vehicle) => vehicle.name?.toLowerCase() === "singles"
    );
    return single?.id === formObject.vehicleId;
  }, [formObject.vehicleId, vehicles]);

  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column floated="left" verticalAlign="middle">
          <Button.Group>
            <Button
              primary={formObject.algorithmFunctionName === "OmsBinPacking"}
              onClick={() =>
                updateFormObject({ algorithmFunctionName: "OmsBinPacking" })
              }
              disabled={props.step === ShipmentBuilderStep.BUILDING}
            >
              Legacy Bin Packing
            </Button>

            <Button.Or />

            <Button
              primary={
                formObject.algorithmFunctionName === "OmsGroupedBinPacking"
              }
              onClick={() =>
                updateFormObject({
                  algorithmFunctionName: "OmsGroupedBinPacking",
                })
              }
              disabled={props.step === ShipmentBuilderStep.BUILDING}
            >
              Grouped Bin Packing
            </Button>
          </Button.Group>
        </Grid.Column>

        {props.step === ShipmentBuilderStep.PREVIEW && props.shipmentBuildId ? (
          <Grid.Column floated="right" verticalAlign="middle" textAlign="right">
            <Popup
              content="Copied to clipboard!"
              on="click"
              open={buildIdIsCopied}
              trigger={
                <Button
                  aria-label="Copy Build Id"
                  content={`Build Id: ${props.shipmentBuildId}`}
                  icon="copy"
                  labelPosition="left"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.shipmentBuildId.toString()
                    );
                    setBuildIdIsCopied(true);
                    setTimeout(() => setBuildIdIsCopied(false), 2000);
                  }}
                />
              }
            />
          </Grid.Column>
        ) : null}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="equipment type">Equipment Type</label>
            <Dropdown
              id="equipment type"
              value={formObject.vehicleId}
              onChange={(_e, { value }) => {
                updateFormObject({ vehicleId: Number(value) });
              }}
              options={vehicles.map((vehicle) => ({
                key: vehicle.id,
                value: vehicle.id,
                text: vehicle.name,
              }))}
              fluid
              selection
              clearable
              aria-label="Equipment Type"
              disabled={props.step === ShipmentBuilderStep.BUILDING}
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field>
            <label htmlFor="maximum units">Maximum Units</label>
            <Dropdown
              id="maximum units"
              fluid
              selection
              options={unitsOptions}
              disabled={
                props.step === ShipmentBuilderStep.BUILDING || isSingleSelected
              }
              aria-label="Maximum Units"
              value={formObject.maxUnits}
              onChange={(_e, { value }) => {
                updateFormObject({ maxUnits: Number(value) });
              }}
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field>
            <label htmlFor="minimum units">Minimum Units</label>
            <Dropdown
              id="minimum units"
              fluid
              selection
              options={unitsOptions}
              disabled={
                props.step === ShipmentBuilderStep.BUILDING || isSingleSelected
              }
              aria-label="Minimum Units"
              value={formObject.minUnits}
              onChange={(_e, { value }) => {
                updateFormObject({ minUnits: Number(value) });
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <label htmlFor="origin radius">Origin Radius *</label>
            <Input
              value={formObject.originRadius}
              onChange={(event) =>
                updateFormObject({ originRadius: event.target.value })
              }
              fluid
              label={{
                basic: true,
                content: appContext.isEU ? "km" : "miles",
              }}
              id="origin radius"
              labelPosition="right"
              type="number"
              disabled={
                props.step === ShipmentBuilderStep.BUILDING || isSingleSelected
              }
              className={formObject.originRadius === "" ? "error" : ""}
              placeholder="Enter Origin Radius"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field>
            <label htmlFor="destination radius">Destination Radius *</label>
            <Input
              value={formObject.destinationRadius}
              onChange={(event) =>
                updateFormObject({ destinationRadius: event.target.value })
              }
              fluid
              label={{
                basic: true,
                content: appContext.isEU ? "km" : "miles",
              }}
              id="destination radius"
              labelPosition="right"
              type="number"
              disabled={
                props.step === ShipmentBuilderStep.BUILDING || isSingleSelected
              }
              className={formObject.destinationRadius === "" ? "error" : ""}
              placeholder="Enter Destination Radius"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field>
            <label htmlFor="maximum stops">Max no of Stops</label>
            <Dropdown
              id="maximum stops"
              fluid
              selection
              options={maxNoOfStopsOptions}
              disabled={
                props.step === ShipmentBuilderStep.BUILDING || isSingleSelected
              }
              aria-label="Max no of Stops"
              value={formObject.maxStops}
              onChange={(_e, { value }) => {
                updateFormObject({ maxStops: Number(value) });
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ShipmentBuilderForm;
