import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { VinInfoObject } from '../models/VinInfoObject';

import httpHandler from '../utils/httpHandler';

export const getVinInfo = createAsyncThunk<
  VinInfoObject,
  string,
  { rejectValue: string }
>('vin/getVinInfo', async (vinNumber, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.get<VinInfoObject>(
      `api/vin/${vinNumber}`
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface VinState {
  vinInfo: VinInfoObject;
  status: '' | 'pending' | 'fulfilled' | 'rejected';
}

export const initialState: VinState = {
  vinInfo: {
    make: null,
    model: null,
    year: null,
    wheelBase: null,
    type: null,
    length: null,
    width: null,
    height: null,
    weight: null,
  },
  status: '',
};

const vinSlice = createSlice({
  name: 'vin',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVinInfo.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getVinInfo.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.vinInfo = action.payload;
      })
      .addCase(getVinInfo.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export default vinSlice.reducer;
