import { useState } from "react";
import styled from "styled-components";

import { Item, Grid, Button, Label } from "semantic-ui-react";

import ShipmentPanelFilter from "../ShipmentPanelFilter/ShipmentPanelFilter";
import ShipmentPanelFilterButtons from "./ShipmentPanelFilterButtons";
import ShipmentPanelActions from "./ShipmentPanelActions";

import DeleteAllConfirmationModal from "../Modals/DeleteAllConfirmationModal";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { formatFilterObjectForApi } from "../ApiShipmentDataMapper";
import {
  selectAllShipments,
  addShipment,
  clearAllSelectedShipments,
  clearShipments,
  deleteShipments,
  getShipments,
  postListStatistics,
  resetShipmentStatistics,
  resetShipments,
  updateShipmentFilter,
} from "../../../store/shipmentSlice";
import ShipmentPanelReshuffleModal from "../Modals/ReshuffleModal";
import { ShipmentFilter } from "../../../models/ShipmentFilter";

const ShipmentPanelHeaderControls = () => {
  const [deleteAllModalOpened, setDeleteAllModalOpened] = useState(false);
  const [isSearchOnly, setIsSearchOnly] = useState(false);

  const dispatch = useAppDispatch();

  const {
    shipments,
    selectedOrderIds,
    filter: shipmentFilter,
    statistics: shipmentStatistics,
    filteredTotal,
  } = useAppSelector((state) => state.shipments);

  const onShipmentsRequest = (partialFilter: Partial<ShipmentFilter>) => {
    dispatch(resetShipments());
    dispatch(
      updateShipmentFilter({
        ...shipmentFilter,
        ...partialFilter,
        page: 1,
        pageSize: 10,
      })
    );
    dispatch(getShipments())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(postListStatistics());
  };

  const refresh = () => {
    dispatch(clearShipments());
    dispatch(clearAllSelectedShipments());
    dispatch(updateShipmentFilter({ page: 1, pageSize: 10 }));
    dispatch(getShipments())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(postListStatistics());
  };

  const handleClear = () => {
    const requestObject = formatFilterObjectForApi({
      ...shipmentFilter,
      states: [0],
    });

    dispatch(deleteShipments(requestObject))
      .unwrap()
      .then(() => {
        dispatch(getShipments())
          .unwrap()
          .catch((err) => {
            console.error(err);
          });
        dispatch(postListStatistics());
      });
    setDeleteAllModalOpened(false);
  };

  const handleSelectAll = () => {
    if (shipments.length < filteredTotal) {
      dispatch(updateShipmentFilter({ page: 1, pageSize: filteredTotal }));
      dispatch(getShipments())
        .unwrap()
        .then(() => {
          dispatch(selectAllShipments());
        })
        .catch((err) => {
          console.error(err);
        });
      dispatch(postListStatistics());
    } else {
      dispatch(selectAllShipments());
    }
  };

  const handleDeselectAll = () => {
    dispatch(clearAllSelectedShipments());
  };

  const handleToggleClick = (name: string) => (value: boolean) => {
    dispatch(resetShipments());
    dispatch(
      updateShipmentFilter({
        ...shipmentFilter,
        [name]: value,
        page: 1,
        pageSize: 10,
      })
    );
    dispatch(getShipments())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(postListStatistics());
  };

  return (
    <ControlsItem>
      <Grid verticalAlign="middle">
        <FilterActionsRow>
          <ShipmentPanelFilterButtons
            headerText={
              shipmentFilter.showAll
                ? `${filteredTotal} Shipments`
                : `${shipmentStatistics.myShipmentsCount}/${shipmentStatistics.allShipmentsCount} Shipments`
            }
            showAll={shipmentFilter.showAll}
            onlyUnsaved={shipmentFilter.onlyUnsaved}
            onMyShipmentsClick={handleToggleClick("showAll")}
            onUnsavedShipmentsClick={handleToggleClick("onlyUnsaved")}
            refresh={refresh}
            resetFilter={() => {
              dispatch(resetShipments());
              dispatch(resetShipmentStatistics());
            }}
            setFilterPanelOpened={setIsSearchOnly}
            filterPanelOpened={isSearchOnly}
            unsavedTotal={shipmentStatistics.unsavedShipmentsCount}
          />
        </FilterActionsRow>

        <FilterRow>
          <NoPaddingColumn>
            <ShipmentPanelFilter
              isSearchOnly={isSearchOnly}
              onFilterChange={onShipmentsRequest}
            />
          </NoPaddingColumn>
        </FilterRow>

        <FilterRow columns={2}>
          <NoPaddingColumn>
            <ShipmentPanelActions
              deleteAllClick={setDeleteAllModalOpened}
              addShipment={() => {
                dispatch(addShipment());
              }}
            />
          </NoPaddingColumn>
          <NoPaddingColumn textAlign="right">
            {selectedOrderIds.length > 0 && (
              <Label color="blue">{selectedOrderIds.length} Selected</Label>
            )}
            <ShipmentPanelReshuffleModal size="mini" />
            <Button
              onClick={handleSelectAll}
              size="mini"
              icon="check square outline"
              content="Select All"
            />
            <Button
              onClick={handleDeselectAll}
              size="mini"
              icon
              name="x"
              content="Deselect All"
            />
          </NoPaddingColumn>
        </FilterRow>
      </Grid>

      <DeleteAllConfirmationModal
        opened={deleteAllModalOpened}
        onClose={() => setDeleteAllModalOpened(false)}
        clearAll={() => handleClear()}
      />
    </ControlsItem>
  );
};

export default ShipmentPanelHeaderControls;

const NoPaddingColumn = styled(Grid.Column)`
  padding: 0 !important;
`;

const ControlsItem = styled(Item)`
  margin-bottom: 2rem;
  padding: 0 1rem !important;
`;

const FilterActionsRow = styled(Grid.Row)`
  padding-bottom: 4px !important;
  display: flex;
`;

const FilterRow = styled(Grid.Row)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;
