import { CallbackComponent } from "redux-oidc";
import userManager from "../utils/userManager";

const CallbackPage = () => (
  <CallbackComponent
    userManager={userManager}
    successCallback={() => {
      window.location.href = "/work-space";
    }}
    errorCallback={(error) => {
      console.error(error);
    }}
  >
    <div>
      <span>Redirecting...</span>
    </div>
  </CallbackComponent>
);

export default CallbackPage;
