import httpHandler from "../utils/httpHandler";
import { AppNavHeaderWithPaths } from "rpm-app-navigation-header";
import userManager from "../utils/userManager";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { History } from "history";

type Props = {
  history: History;
};

const HeaderComponent = ({ history }: Props) => {
  const { user } = useAppSelector((state: RootState) => state.oidc);

  const fetchPaths = () => {
    return httpHandler.instanceAxios.get(
      `${import.meta.env.VITE_APP_NAVBAR_API}`
    );
  };

  const handleLogout = () => {
    userManager.signoutRedirect().catch((err) => {
      console.error(err);
    });
  };

  const handleLocalpathPush = (path: string) => {
    history.push(path);
  };

  return (
    <div>
      {user && (
        <AppNavHeaderWithPaths
          fetchPaths={fetchPaths}
          userName={user.profile?.name || null}
          onLogout={handleLogout}
          onLocalPathPush={handleLocalpathPush}
          activePath={history.location.pathname}
          logoutOptions={{ clearLS: true }}
        />
      )}
    </div>
  );
};

export default HeaderComponent;
