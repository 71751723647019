import { PropsWithChildren } from "react";
import { useAppSelector } from "../store/hooks";

const AuthContainer = (props: PropsWithChildren) => {
  const { user } = useAppSelector((state) => state.oidc);

  return user ? props.children : null
};

export default AuthContainer;
