import { Header, Table } from "semantic-ui-react";
import { AutoBuildPreviewShipmentObject } from "../../models/AutoBuildShipmentObject";

type PreviewShipmentResultsProps = {
  shipments: AutoBuildPreviewShipmentObject[];
  altText?: string;
};

const PreviewShipmentResults = (props: PreviewShipmentResultsProps) => (
  <>
    <Header as="h3">Shipments {props.shipments.length}</Header>
    {props.shipments.length > 0 ? (
      <section className="scrollable-container">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Pickup</Table.HeaderCell>
              <Table.HeaderCell width={3}>Delivery</Table.HeaderCell>
              <Table.HeaderCell width={10}>Items</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.shipments.map((shipment) => {
              return (
                <Table.Row key={crypto.randomUUID()}>
                  <Table.Cell>
                    {shipment.pickup?.shortFormatted ?? "Unknown"}
                  </Table.Cell>
                  <Table.Cell>
                    {shipment.delivery?.shortFormatted ?? "Unknown"}
                  </Table.Cell>
                  <Table.Cell>
                    {shipment.identityNumbers?.join(", ")}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </section>
    ) : (
      <p>{props.altText}</p>
    )}
  </>
);

export default PreviewShipmentResults;
