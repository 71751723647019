import { Button, Modal, Message } from "semantic-ui-react";
import PropTypes from "prop-types";

const DeleteAllConfirmationModal = ({ opened, clearAll, onClose }) => (
  <Modal open={opened} onClose={onClose} closeIcon>
    <Modal.Header>Delete all Shipment Drafts</Modal.Header>
    <Modal.Content>
      <Message color="yellow">
        <p>
          You are about to delete all shipment drafts you have saved. Are you
          sure you want to do that? It cannot be undone.
        </p>
      </Message>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose}>Cancel</Button>
      <Button color="red" onClick={clearAll}>
        CLEAR ALL
      </Button>
    </Modal.Actions>
  </Modal>
);

DeleteAllConfirmationModal.propTypes = {
  opened: PropTypes.bool,
  clearAll: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteAllConfirmationModal;
