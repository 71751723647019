import { useState, useEffect } from "react";
import {
  Grid,
  Header,
  Icon,
  Button,
  Modal,
  Progress,
  Label,
} from "semantic-ui-react";
import styled from "styled-components";

import { fetchCustomers } from "../../../store/customerSlice";
import OrderPanelFilter from "./OrderPanelFilter";
import OrderPanelAutoBuildModal from "../Modals/OrderPanelAutoBuildModal";
import OrderPanelOrderDetailsModal from "../Modals/OrderPanelOrderDetailsModal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  changeFilter,
  getOrders,
  removeAllOrders,
  removeAllSelected,
  setDefaultFilter,
  selectAllOrders,
  deleteOrders,
  removeOrderItem,
  removeSelected,
  refreshOrders,
} from "../../../store/orderSlice";
import { OrdersFilter } from "../../../models/Filter";
import { getVehicles } from "../../../store/vehicleSlice";

const LeftColumn = styled(Grid.Column)`
  padding-left: 0 !important;
`;

const RightColumn = styled(Grid.Column)`
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  & > :last-child {
    margin-right: 0;
  }
  text-align: right;
  padding-right: 0 !important;
`;

const MiddleColumn = styled(Grid.Column)`
  padding: 0 !important;
`;

const FilterGridRow = styled(Grid.Row)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;

const FilterActionsRow = styled(Grid.Row)`
  padding-bottom: 4px !important;
`;

const OrderPanelHeader = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.orders.filter);
  const orderFilterState = useAppSelector(
    (state) => state.orders.filter.itemStates
  );
  const totalCount = useAppSelector((state) => state.orders.totalCount);
  const totalFilteredCount = useAppSelector(
    (state) => state.orders.totalFilteredCount
  );
  const isDefaultFilter = useAppSelector(
    (state) => state.orders.isDefaultFilter
  );

  const selectedOrderIds = useAppSelector(
    (state) => state.orders.selectedOrderIds
  );

  const orderLoadStatus = useAppSelector((state) => state.orders.status);

  const orderCount = useAppSelector((state) => state.orders.orders.length);

  const [isSearchOnly, setIsSearchOnly] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingCount, setDeletingCount] = useState(0);

  const onOrdersRequest = (filterVal: OrdersFilter) => {
    dispatch(removeAllOrders());
    dispatch(removeAllSelected());
    dispatch(changeFilter({ ...filterVal, page: 1 }));
    dispatch(getOrders())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFilterReset = () => {
    dispatch(removeAllOrders());
    dispatch(removeAllSelected());
    dispatch(setDefaultFilter());
  };

  const handleActiveSelector = (val: string) => {
    dispatch(changeFilter({ ...filter, itemStates: val, page: 1 }));

    onOrdersRequest({ ...filter, itemStates: val });
  };

  const handleSelectAll = () => {
    dispatch(selectAllOrders())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  };
  const handleDeselectAll = () => {
    dispatch(removeAllSelected());
  };
  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };
  const handleRefreshOrders = () => {
    dispatch(refreshOrders())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteSelectedOrders = () => {
    setDeletingCount(selectedOrderIds.length);
    selectedOrderIds.forEach((orderId) => {
      dispatch(deleteOrders([orderId]))
        .unwrap()
        .then(() => {
          dispatch(removeOrderItem(orderId));
          dispatch(removeSelected(orderId));
          setIsDeleteModalOpen(false);
          dispatch(refreshOrders())
            .unwrap()
            .catch((err) => {
              console.error(err);
            });

          setDeletingCount(0);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  useEffect(() => {
    dispatch(fetchCustomers())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(getVehicles())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  return (
    <>
      <Grid verticalAlign="middle">
        <FilterActionsRow columns={2}>
          <LeftColumn widescreen={6} computer={6}>
            <Header as="h4">
              {totalFilteredCount === 0 || totalFilteredCount
                ? `${totalFilteredCount}/${totalCount}`
                : totalCount}
              &nbsp;Items
            </Header>
          </LeftColumn>
          <RightColumn widescreen={10} computer={10} floated="right">
            <Button.Group size="mini">
              <Button
                primary={orderFilterState === "active"}
                onClick={() => handleActiveSelector("active")}
              >
                Active
              </Button>
              <Button.Or />
              <Button
                primary={orderFilterState === "inactive"}
                onClick={() => handleActiveSelector("inactive")}
              >
                Inactive
              </Button>
            </Button.Group>
            <Button
              aria-label="Refresh Orders"
              size="mini"
              onClick={handleRefreshOrders}
              icon="refresh"
              disabled={isDefaultFilter}
            />
            <Button
              aria-label="Reset Filter"
              onClick={handleFilterReset}
              icon={
                <Icon.Group>
                  <Icon link name="filter" />
                  <Icon link corner name="delete" />
                </Icon.Group>
              }
              size="mini"
              disabled={isDefaultFilter && orderCount === 0}
            />
            <Button
              aria-label="Toggle Filter"
              onClick={() => setIsSearchOnly(!isSearchOnly)}
              icon={<Icon name="sliders horizontal" />}
              size="mini"
            />
          </RightColumn>
        </FilterActionsRow>

        <FilterGridRow>
          <MiddleColumn>
            <OrderPanelFilter
              isSearchOnly={isSearchOnly}
              onFilterChange={onOrdersRequest}
            />
          </MiddleColumn>
        </FilterGridRow>

        <FilterActionsRow columns={2}>
          <MiddleColumn>
            <OrderPanelOrderDetailsModal />

            <OrderPanelAutoBuildModal state={orderFilterState} size="mini" />
          </MiddleColumn>

          <MiddleColumn textAlign="right">
            {selectedOrderIds.length > 0 && (
              <Label color="blue">{selectedOrderIds.length} Selected</Label>
            )}
            <Button
              onClick={handleSelectAll}
              size="mini"
              icon="check square outline"
              content="Select All"
              disabled={
                isDefaultFilter ||
                orderCount === 0 ||
                selectedOrderIds.length === orderCount
              }
            />
            <Button
              onClick={handleDeselectAll}
              size="mini"
              icon
              name="x"
              content="Deselect All"
              disabled={
                isDefaultFilter ||
                selectedOrderIds.length === 0 ||
                orderLoadStatus === "pending"
              }
            />

            <Button
              onClick={handleDelete}
              size="mini"
              icon="trash alternate outline"
              content="Delete Selected"
              negative
              disabled={
                isDefaultFilter ||
                selectedOrderIds.length === 0 ||
                orderLoadStatus === "pending"
              }
            />
          </MiddleColumn>
        </FilterActionsRow>
      </Grid>
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        size="tiny"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Header size="small">Alert</Header>
          {deletingCount < 1 ? (
            <p>
              Are you sure you want to delete {selectedOrderIds.length} orders?
            </p>
          ) : (
            <Progress
              percent={100 - (selectedOrderIds.length / deletingCount) * 100}
              indicating
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="yellow"
            onClick={() => {
              setIsDeleteModalOpen(false);
              setDeletingCount(0);
            }}
            content="No"
          />
          <Button primary onClick={deleteSelectedOrders} content="Yes" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default OrderPanelHeader;
