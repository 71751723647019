import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createOrder,
  deleteOrders,
  getOrders,
  refreshOrders,
  selectAllOrders,
  toggleOrderState,
  updateOrder,
} from "./orderSlice";
import { cancelAutoBuild } from "./autoBuildSlice";
import { getTurvoCustomers } from "./turvoCustomerSlice";
import { getTurvoLocations } from "./turvoLocationSlice";
import { getVehicles } from "./vehicleSlice";
import { getVinInfo } from "./vinSlice";
import { fetchDistance } from "./geocodingSlice";
import { fetchCustomers } from "./customerSlice";
import {
  buildShipment,
  deleteShipment,
  deleteShipments,
  getShipments,
  postListStatistics,
  saveShipment,
} from "./shipmentSlice";

export interface MessageObject {
  id: string;
  message: string;
  success: boolean;
}

export interface AlertMessageState {
  messages: MessageObject[];
}

export const initialState: AlertMessageState = {
  messages: [],
};

const alertMessageSlice = createSlice({
  name: "alertMessage",
  initialState: initialState,
  reducers: {
    addSuccessMessage: (state, action: PayloadAction<string>) => {
      state.messages.push({
        message: action.payload,
        success: true,
        id: crypto.randomUUID(),
      });
    },
    addErrorMessage: (state, action: PayloadAction<string>) => {
      state.messages.push({
        message: action.payload,
        success: false,
        id: crypto.randomUUID(),
      });
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.messages = state.messages.filter((message) => message.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTurvoCustomers.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching Turvo customers: ${action.payload}`,
        });
      })
      .addCase(getTurvoLocations.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching Turvo locations: ${action.payload}`,
        });
      })
      .addCase(selectAllOrders.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error selecting all orders: ${action.payload}`,
        });
      })
      .addCase(getVinInfo.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching VIN info: ${action.payload}`,
        });
      })
      .addCase(cancelAutoBuild.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error cancelling auto build: ${action.payload}`,
        });
      })
      .addCase(fetchDistance.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching distance: ${action.payload}`,
        });
      })
      .addCase(createOrder.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error creating orders: ${action.payload}`,
        });
      })
      .addCase(getOrders.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching orders: ${action.payload}`,
        });
      })
      .addCase(updateOrder.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error updating order: ${action.payload}`,
        });
      })
      .addCase(deleteOrders.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `${
            action.payload?.id ? `Error deleting ${action.payload?.id}: ` : ""
          }${action.payload?.message}`,
        });
      })
      .addCase(getVehicles.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching vehicles: ${action.payload}`,
        });
      })
      .addCase(cancelAutoBuild.fulfilled, (state, action) => {
        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: "Autobuild cancelled",
        });
      })
      .addCase(deleteOrders.fulfilled, (state, action) => {
        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: "Order(s) deleted successfully",
        });
      })
      .addCase(toggleOrderState.fulfilled, (state, action) => {
        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: `Order state changed successfully`,
        });
      })
      .addCase(toggleOrderState.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error changing order state: ${action.payload}`,
        });
      })
      .addCase(refreshOrders.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error refreshing orders: ${action.payload}`,
        });
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching customers: ${action.payload}`,
        });
      })
      .addCase(postListStatistics.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching statistics: ${action.payload}`,
        });
      })
      .addCase(getShipments.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error fetching shipments: ${action.payload}`,
        });
      })
      .addCase(saveShipment.fulfilled, (state, action) => {
        // This one should use the latest ID from the payload
        const shipmentId =
          action.payload.turvoShipmentCustomId ?? `DS-${action.payload.id}`;

        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: `Shipment ${shipmentId} was saved successfully`,
        });
      })
      .addCase(saveShipment.rejected, (state, action) => {
        const shipmentId =
          action.meta.arg.turvoShipmentCustomId ?? `DS-${action.meta.arg.id}`;

        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error saving shipment ${shipmentId}: ${action.payload}`,
        });
      })
      .addCase(deleteShipment.fulfilled, (state, action) => {
        const shipmentId =
          action.meta.arg.turvoShipmentCustomId ?? `DS-${action.meta.arg.id}`;

        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: `Shipment ${shipmentId} was deleted successfully`,
        });
      })
      .addCase(deleteShipment.rejected, (state, action) => {
        const shipmentId =
          action.meta.arg.turvoShipmentCustomId ?? `DS-${action.meta.arg.id}`;

        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error deleting shipment ${shipmentId}: ${action.payload}`,
        });
      })
      .addCase(deleteShipments.fulfilled, (state, action) => {
        alertMessageSlice.caseReducers.addSuccessMessage(state, {
          ...action,
          payload: `Shipments were deleted successfully`,
        });
      })
      .addCase(deleteShipments.rejected, (state, action) => {
        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error deleting shipments: ${action.payload}`,
        });
      })
      .addCase(buildShipment.fulfilled, (state, action) => {
        if (action.payload?.turvoShipmentId) {
          alertMessageSlice.caseReducers.addSuccessMessage(state, {
            ...action,
            payload: `Shipment ${action.payload.shipmentId} was built successfully`,
          });
        }
      })
      .addCase(buildShipment.rejected, (state, action) => {
        const shipmentId =
          action.meta.arg.turvoShipmentCustomId ?? `DS-${action.meta.arg.id}`;

        alertMessageSlice.caseReducers.addErrorMessage(state, {
          ...action,
          payload: `Error building shipment ${shipmentId}: ${action.payload}`,
        });
      });
  },
});

export const { addSuccessMessage, addErrorMessage, removeMessage } =
  alertMessageSlice.actions;

export default alertMessageSlice.reducer;
