import { Fragment } from "react";
import {
  Button,
  Modal,
  Label,
  Icon,
  Grid,
  Divider,
  Segment,
  Table,
  Message,
} from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment-timezone";
import MapComponent from "../../../components/MapComponent";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideLoader, showLoader } from "../../../store/loaderSlice";
import {
  buildShipment,
  clearAllSelectedShipments,
  clearShipments,
  getShipments,
  postListStatistics,
} from "../../../store/shipmentSlice";
import { ModifiedShipmentObject } from "../../../models/ShipmentObject";
import {
  IndexedOrderObjectMapApi,
  OrderObjectMapApi,
} from "../../../models/OrderObject";
import { ShipmentRoute } from "../../../models/ShipmentRoute";

const routeTypes = {
  0: "Pickup",
  1: "Delivery",
};

type SchedulerModalProps = {
  opened: boolean;
  onClose: () => void;
  shipment: ModifiedShipmentObject;
};

const SchedulerModal = (props: SchedulerModalProps) => {
  const dispatch = useAppDispatch();
  const customers = useAppSelector((state) => state.customers.list);
  const { routes, items } = props.shipment;
  const onBuildClick = () => {
    const customer = customers.find((c) => c.id === props.shipment.customerId);
    const updatedShipment = {
      ...props.shipment,
      customerName: customer ? customer.name : "n/a",
    };

    dispatch(showLoader());
    dispatch(buildShipment(updatedShipment))
      .unwrap()
      .then((buildResult) => {
        if (buildResult) {
          dispatch(clearShipments());
          dispatch(clearAllSelectedShipments());
          dispatch(getShipments())
            .unwrap()
            .catch((err) => {
              console.error(err);
            });
          dispatch(postListStatistics());
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(hideLoader());
      });

    props.onClose();
  };

  return (
    <Modal open={props.opened} onClose={props.onClose} closeIcon>
      <ScrollableModal>
        <MapComponent shipment={props.shipment} />
        {props.shipment.isRouteUnoptimized && (
          <Message warning>
            <Message.Header>
              We could not optimize your shipment's route.
            </Message.Header>
            <p>You can still proceed to build and/or update your shipment.</p>
          </Message>
        )}
        <Grid>
          {routes.map((route) => (
            <Fragment key={route.sequence}>
              <StopTextRow textAlign="justified" columns={3}>
                <StopTypeText width={3}>
                  {routeTypes[route.type]} {route.sequence}
                </StopTypeText>
                <Grid.Column width={10}>
                  {route.location.name}, {route.location.formatted}
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <MilesLabel>
                    <Icon name="road" /> {route.distance} mi
                  </MilesLabel>
                </Grid.Column>
              </StopTextRow>
              <VehicleRow columns={2}>
                <Grid.Column width={3} />
                <Grid.Column>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Vin</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {getStopVehicles(items, route).map(
                        (item: OrderObjectMapApi) => (
                          <Table.Row key={item.identityNumber}>
                            <Table.Cell>
                              <Label>{item.vin || item.identityNumber}</Label>
                            </Table.Cell>
                            <Table.Cell>
                              {item.make} {item.model}
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </VehicleRow>
              <Grid.Row columns={1} centered>
                <Grid.Column>
                  <TimeSegment placeholder>
                    <Grid columns={2}>
                      <Grid.Column>
                        <TimeLabel>
                          <Icon name="clock" />{" "}
                          {moment(route.location.appointment?.start)
                            .tz(route.location.appointment?.timezone ?? "")
                            .format("MM/DD/YYYY HH:mm")}
                        </TimeLabel>
                      </Grid.Column>
                      <Grid.Column>
                        <TimeLabel>
                          <Icon name="clock" />{" "}
                          {moment(route.location.appointment?.end)
                            .tz(route.location.appointment?.timezone ?? "")
                            .format("MM/DD/YYYY HH:mm")}
                        </TimeLabel>
                      </Grid.Column>
                    </Grid>
                    <Divider vertical>To</Divider>
                  </TimeSegment>
                </Grid.Column>
              </Grid.Row>
            </Fragment>
          ))}
        </Grid>
      </ScrollableModal>
      <Modal.Actions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button color="red" onClick={onBuildClick}>
          Build
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SchedulerModal;

const StopTextRow = styled(Grid.Row)`
  font-size: 1.3rem;
  margin-top: 1.5rem;
`;

const StopTypeText = styled(Grid.Column)`
  font-weight: bold;
`;

const VehicleRow = styled(Grid.Row)`
  font-size: 1.1rem;
  padding-top: 0 !important;
  padding-bottom: 0.2rem !important;
`;

const TimeSegment = styled(Segment)`
  min-height: 7rem !important;
`;

const TimeLabel = styled(Segment)`
  font-size: 1.1rem;
  text-align: center;
`;

const MilesLabel = styled(Label)`
  font-size: 1.2rem !important;
`;

const ScrollableModal = styled(Modal.Content)`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
`;

const getStopVehicles = (
  items: IndexedOrderObjectMapApi[],
  route: ShipmentRoute
) => {
  const { type, location } = route;
  const routeType = routeTypes[type].toLowerCase();
  return items.filter((item) => item[routeType].id === location.id);
};
