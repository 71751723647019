import React, { useState } from "react";
import { Icon, Modal, Button, Header } from "semantic-ui-react";
import styled from "styled-components";
import { useAppDispatch } from "../../../store/hooks";
import {
  deleteOrders,
  removeOrderItem,
  openVehicleModal,
  closeVehicleModal,
  removeSelected,
  refreshOrders,
} from "../../../store/orderSlice";

const activeVehicleState = 8;
const inactiveVehicleState = 16;
const pausedVehicleState = 15;

type VehicleActionsRightPanelProps = {
  isUnsaved: boolean;
  isOrder: boolean;
  state: number;
  id: number;
  identityNumber: string;
  onStartOrderUpdate: (
    orderId: number,
    event: React.MouseEvent<HTMLElement>
  ) => void;
  onOrderStateChange: (vehicleState: number) => void;
};

const VehicleActionsRightPanel = (props: VehicleActionsRightPanelProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleOpenModal = (
    event: React.MouseEvent<HTMLElement>,
    action: string
  ) => {
    dispatch(openVehicleModal());
    setOpenModal(true);
    setModalAction(action);
    event.stopPropagation();
  };

  const handleCloseModal = () => {
    dispatch(closeVehicleModal());
    setOpenModal(false);
    setModalAction("");
  };

  const handleUpdateVehicleState = (action: "archive" | "pause") => {
    let vehicleState: number = props.state;

    switch (action) {
      case "archive":
        vehicleState =
          props.state === activeVehicleState
            ? inactiveVehicleState
            : activeVehicleState;
        break;
      case "pause":
        vehicleState =
          props.state === activeVehicleState
            ? pausedVehicleState
            : activeVehicleState;
        break;
      default:
        break;
    }

    props.onOrderStateChange(vehicleState);
  };

  const handleDeleteVehicle = () => {
    dispatch(deleteOrders([props.id]))
      .unwrap()
      .then(() => {
        setOpenModal(false);
        dispatch(closeVehicleModal());
        dispatch(removeOrderItem(props.id));
        dispatch(removeSelected(props.id));
        dispatch(refreshOrders())
          .unwrap()
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleConfirmModal = () => {
    switch (modalAction) {
      case "delete":
        handleDeleteVehicle();
        break;
      case "pause":
      case "continue":
        handleUpdateVehicleState("pause");
        handleCloseModal();
        break;
      default:
        break;
    }
  };

  return (
    <div className="vehicleActionsContainer">
      <div className="vehicleActions">
        {props.isOrder && (
          <>
            <TrashIconStyled
              title="deleteItem"
              name="trash"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleOpenModal(e, "delete")
              }
              link
            />
            <EditIconStyled
              title="editItem"
              name="edit"
              link
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                props.onStartOrderUpdate(props.id, event)
              }
            />
            <PauseIconStyled
              title="pauseItem"
              name={`${
                props.state === pausedVehicleState ? "play" : "pause"
              } circle outline`}
              link
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleOpenModal(
                  e,
                  props.state === pausedVehicleState ? "continue" : "pause"
                )
              }
              paused={(props.state === pausedVehicleState).toString()}
            />
          </>
        )}

        {!props.isOrder && props.isUnsaved && (
          <UnsavedIconStyled
            color="yellow"
            size="large"
            name="exclamation triangle"
            title="Unsaved in Turvo"
          />
        )}
      </div>
      <ToggleIconStyled
        title="toggleState"
        name={`toggle ${
          props.state === activeVehicleState ||
          props.state === pausedVehicleState
            ? "on"
            : "off"
        }`}
        onClick={() => handleUpdateVehicleState("archive")}
        link
      />

      <Modal open={openModal} onClose={handleCloseModal} size="tiny" closeIcon>
        <Modal.Content>
          <Header size="small">Alert</Header>
          <p>
            Are you sure you want to {modalAction} {props.identityNumber}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" onClick={handleCloseModal} content="No" />
          <Button primary onClick={handleConfirmModal} content="Yes" />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default VehicleActionsRightPanel;

const TrashIconStyled = styled(Icon)`
  margin-left: -2px !important;
  margin-bottom: 7px !important;
  margin-top: -5px !important;
`;

const EditIconStyled = styled(Icon)`
  margin-bottom: 7px !important;
`;

const PauseIconStyled = styled(Icon)`
  margin-left: -2px !important;
  color: ${(props) => (props.paused === "true" ? "#ff4d63" : "#4d4d4d")};
`;

const ToggleIconStyled = styled(Icon)`
  margin-left: -1px !important;
`;

const UnsavedIconStyled = styled(Icon)`
  margin-left: -2px !important;
  margin-top: -20px !important;
  cursor: auto !important;
`;
