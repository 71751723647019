import { Header, Table } from "semantic-ui-react";
import { getOrderIdentity } from "../../utils/shipmentHelper";
import { IncompatibleOrderObject } from "../../models/IncompatibleOrderObject";

type PreviewIncompatibleResultsProps = {
  incompatibleOrders: IncompatibleOrderObject[];
};

const PreviewIncompatibleResults = (props: PreviewIncompatibleResultsProps) => {
  const getOrderErrors = (orderItem: IncompatibleOrderObject) => {
    if (!orderItem.errors) {
      return;
    }
    return orderItem.errors.join(", ");
  };

  return (
    <>
      <Header as="h3">
        Incompatible orders {props.incompatibleOrders.length}
      </Header>
      {props.incompatibleOrders.length > 0 ? (
        <section className="scrollable-container">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>Errors</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.incompatibleOrders.map((orderItem) => {
                return (
                  <Table.Row key={orderItem.order.id}>
                    <Table.Cell>{getOrderIdentity(orderItem.order)}</Table.Cell>
                    <Table.Cell>{getOrderErrors(orderItem)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </section>
      ) : null}
    </>
  );
};

export default PreviewIncompatibleResults;
