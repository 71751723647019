import AuthContainer from '../../components/AuthContainer';
import ActionsLogTable from './components/ActionsLogTable';

const ActionsLogComponent = () => (
    <AuthContainer>
        <ActionsLogTable />
    </AuthContainer>
);

export { ActionsLogComponent as default };
