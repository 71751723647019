import { Divider, Icon, Progress, Table } from "semantic-ui-react";
import { getShipmentItems } from "../../utils/shipmentHelper";
import { OrderBuildItemObject } from "../../models/OrderBuildItemObject";

type ShipmentBuilderProgressProps = {
  buildItems: OrderBuildItemObject[];
  buildItemsTotal: number;
  processedTotal: number;
};

const ShipmentBuilderProgress = (props: ShipmentBuilderProgressProps) => (
  <>
    <Divider />
    <Progress
      percent={Math.ceil((props.processedTotal * 100) / props.buildItemsTotal)}
      color="blue"
    >
      <label>{`${props.processedTotal} of ${props.buildItemsTotal} Shipments processed`}</label>
    </Progress>

    <section className="scrollable-container">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Pickup</Table.HeaderCell>
            <Table.HeaderCell width={3}>Delivery</Table.HeaderCell>
            <Table.HeaderCell width={9}>Items</Table.HeaderCell>
            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.buildItems.map((buildItem) => (
            <Table.Row key={crypto.randomUUID()}>
              <Table.Cell>
                {buildItem.shipment.pickup?.shortFormatted ?? "Unknown"}
              </Table.Cell>
              <Table.Cell>
                {buildItem.shipment.delivery?.shortFormatted ?? "Unknown"}
              </Table.Cell>
              <Table.Cell>{getShipmentItems(buildItem.shipment)}</Table.Cell>
              <Table.Cell>
                {buildItem.error ? (
                  <span>{buildItem.error}</span>
                ) : (
                  <span>
                    <Icon name="check" />
                  </span>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </section>
  </>
);

export default ShipmentBuilderProgress;
