import { useContext } from "react";
import { Button } from "semantic-ui-react";

import AppContext from "../Main/AppContext";
import ShipmentBuilderFormContext from "./context/ShipmentBuilderFormContext";
import ShipmentBuilderStep from "../../models/ShipmentBuilderStep";
import { useAppSelector } from "../../store/hooks";
import { ShipmentBuilderApiObject } from "../../models/ShipmentBuilderApiObject";
import { getDistanceUnit } from "../../utils/helpers/unitConversionHelper";
import { mapFilterObject } from "../OrderPanel/ApiOrderDataMapper";

export type ShipmentBuilderFormProps = {
  step: ShipmentBuilderStep;
  onClose: () => void;
  onCancel: () => void;
  onPreview: (filter: ShipmentBuilderApiObject) => void;
};

const ShipmentBuilderFormButtons = (props: ShipmentBuilderFormProps) => {
  const appContext = useContext(AppContext);
  const { formObject } = useContext(ShipmentBuilderFormContext);

  const orderFilter = useAppSelector((state) => state.orders.filter);

  const formatFilterForApi = (): ShipmentBuilderApiObject => {
    return {
      ...formObject,
      originRadius: getDistanceUnit(
        Number(formObject.originRadius),
        appContext.isEU
      ),
      destinationRadius: getDistanceUnit(
        Number(formObject.destinationRadius),
        appContext.isEU
      ),
      filterData: mapFilterObject({
        ...orderFilter,
        page: null,
        pageSize: null,
      }),
    };
  };

  return (
    <>
      <Button onClick={props.onClose}>Close</Button>

      {props.step === ShipmentBuilderStep.BUILDING ? (
        <Button color="red" content="Cancel" onClick={props.onCancel} />
      ) : null}

      {props.step === ShipmentBuilderStep.READY ? (
        <Button
          primary
          content="Preview"
          onClick={() => props.onPreview(formatFilterForApi())}
          disabled={!formObject.originRadius || !formObject.destinationRadius}
        />
      ) : null}
    </>
  );
};

export default ShipmentBuilderFormButtons;
