import { useState } from "react";
import styled from "styled-components";
import { Dimmer, Loader } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroller";

import OrderItemRow from "./OrderItemRow";
import WarningModal from "../../../components/WarningModal";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  removeShipmentOrderItems,
  nextPage,
  addMultipleSelected,
  getOrders,
} from "../../../store/orderSlice";
import {
  removeSelectedShipment,
  removeShipmentsById,
  saveShipment,
} from "../../../store/shipmentSlice";
import { OrderObject } from "../../../models/OrderObject";
import { ModifiedShipmentObject } from "../../../models/ShipmentObject";

const ScrollContainer = styled.div`
  height: 64.4vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const OrderPanelBody = () => {
  const dispatch = useAppDispatch();

  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const totalPages = useAppSelector((state) => state.orders.totalPages);
  const filter = useAppSelector((state) => state.orders.filter);
  const selectAllLoading = useAppSelector((state) => state.orders.status);
  const { shipments, selectedShipments } = useAppSelector(
    (state) => state.shipments
  );

  const loadMore = () => {
    if (selectAllLoading === "pending" || totalPages <= filter.page) {
      return;
    }
    dispatch(nextPage());
    dispatch(getOrders())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
  };

  const fromShipmentListToOrderList = () => {
    selectedShipments.forEach((shipment) => {
      const orderIds = shipment.orderIds;
      const updatedShipment = {
        ...shipment.shipment,
        items: shipment.shipment.items.filter(
          (el) => !orderIds.includes(el.id)
        ),
      };

      const vehiclesMoved: OrderObject[] = [];
      shipments.forEach((shipment: ModifiedShipmentObject) => {
        shipment.items
          .filter((a) => orderIds.includes(a.id))
          .forEach((a) => vehiclesMoved.push(a));
      });

      dispatch(saveShipment(updatedShipment))
        .unwrap()
        .then((savedShipment) => {
          if (!savedShipment.items.length) {
            dispatch(removeShipmentsById([savedShipment.id]));
          }
          dispatch(removeSelectedShipment(shipment));
          dispatch(removeShipmentOrderItems(vehiclesMoved));
          dispatch(
            addMultipleSelected(vehiclesMoved.map((vehicle) => vehicle.id))
          );
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const handleItemDrop = (event: React.DragEvent) => {
    // when dropping an item from the shipment panel to the order panel,
    // remove selected items from shipments and add it to the order panel.
    const { dataTransfer } = event;

    const dropFromObject =
      dataTransfer.getData("shipmentItem") || dataTransfer.getData("orderItem");
    const dropFrom = JSON.parse(dropFromObject);

    if (dropFrom.orderId) {
      return;
    }

    const orderCount = selectedShipments.reduce(
      (acc, shipment) => acc + shipment.orderIds.length,
      0
    );

    if (orderCount >= 100) {
      setWarningModalOpen(true);
    } else {
      fromShipmentListToOrderList();
    }
  };

  return (
    <ScrollContainer
      onDragEnter={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleItemDrop}
    >
      <Dimmer inverted active={selectAllLoading === "pending"}>
        <Loader>Loading...</Loader>
      </Dimmer>
      <InfiniteScroll
        pageStart={filter.page}
        loadMore={loadMore}
        hasMore={totalPages > filter.page}
        useWindow={false}
        loader={<span key={0}>Loading...</span>}
        initialLoad={false}
        role="region"
      >
        <OrderItemRow />
      </InfiniteScroll>

      <WarningModal
        open={warningModalOpen}
        onContinue={() => {
          fromShipmentListToOrderList();
          setWarningModalOpen(false);
        }}
        onCancel={() => setWarningModalOpen(false)}
      >
        You're manipulating 100+ orders, are you sure you want to continue?
      </WarningModal>
    </ScrollContainer>
  );
};

export default OrderPanelBody;
