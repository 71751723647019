import { Header, Divider, Button, Icon } from "semantic-ui-react";
import { Control, UseFormSetValue, useFieldArray } from "react-hook-form";
import styled from "styled-components";

import CostItem from "./CostItem";
import { OrderFormCost, OrderFormObject } from "./models/OrderFormObject";
import { useAppSelector } from "../../../../store/hooks";
import { useMemo } from "react";

const StyledDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px !important;
`;

const TotalAmountStyled = styled.div`
  margin-right: 29px;
`;

const PriceSectionFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export type PriceSectionProps = {
  control: Control<OrderFormObject>;
  setValue: UseFormSetValue<OrderFormObject>;
  costs: OrderFormCost[];
  customerId: string | null;
};

const PriceSection = (props: PriceSectionProps) => {
  const orderId = useAppSelector((state) => state.orders.form.orderId);
  const orders = useAppSelector((state) => state.orders.orders);
  const turvoCustomers = useAppSelector(
    (state) => state.turvoCustomer.customers
  );

  const {
    fields: costFields,
    append: appendCost,
    remove: removeCost,
  } = useFieldArray({ control: props.control, name: "costs" });

  const order = useMemo(
    () => orders.find((order) => order.id === orderId),
    [orders, orderId]
  );
  const customer = useMemo(
    () => turvoCustomers.find((customer) => props.customerId === customer.id),
    [turvoCustomers, props.customerId]
  );
  const currencySign = useMemo(
    () => order?.currencySign || customer?.defaultCurrencySign || "N/A",
    [order, customer]
  );

  const getTotalAmount = () => {
    if (props.costs.length === 0) {
      return 0;
    }

    const totalAmount = props.costs.reduce<number>(
      (acc, { amount }) => acc + +amount,
      0
    );

    return totalAmount.toLocaleString("en", {
      useGrouping: false,
      minimumFractionDigits: 2,
    });
  };

  return (
    <>
      <StyledHeader as="h3">Price</StyledHeader>
      <StyledDivider />

      {costFields.map((field, index) =>
        props.costs[index] ? (
          <CostItem
            key={field.id}
            control={props.control}
            setValue={props.setValue}
            cost={props.costs[index]}
            costIndex={index}
            removeCost={() => removeCost(index)}
            currencySign={currencySign}
          />
        ) : null
      )}

      <PriceSectionFooterStyled>
        <Button
          type="button"
          icon
          size="mini"
          labelPosition="left"
          basic
          onClick={() => appendCost(getDefaultCost())}
        >
          <Icon name="plus" />
          Add Additional Price
        </Button>
        <TotalAmountStyled>
          Total Amount: {`${currencySign} `}
          {getTotalAmount() || "___"}
        </TotalAmountStyled>
      </PriceSectionFooterStyled>
    </>
  );
};

const getDefaultCost = (): OrderFormCost => ({
  id: crypto.randomUUID(),
  code: {
    key: "",
    value: "",
  },
  qty: "",
  note: "",
  price: "",
  amount: "",
});

export default PriceSection;
