import { Dispatch, DragEvent, SetStateAction, useState } from "react";
import styled from "styled-components";
import {
  Segment,
  Grid,
  Button,
  Dimmer,
  Loader,
  Icon,
  Label,
  Popup,
} from "semantic-ui-react";

import Vehicle from "../Vehicle/Vehicle";
import WarningModal from "../../../components/WarningModal";

import {
  toggleShipment as saveToggleShipment,
  isCollapsed,
} from "../../../utils/shipmentsCollapseProvider";
import {
  OrderState,
  ShipmentStates,
  TurvoShipmentStatus,
} from "../../../utils/constants";
import locationFormatter from "../../../utils/USStatesProvider";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  addMultipleSelected,
  refreshOrders,
  removeAllSelected,
  removeMultipleOrderItems,
  removeShipmentOrderItems,
} from "../../../store/orderSlice";
import {
  addSelectedShipment,
  deleteShipment,
  postListStatistics,
  removeSelectedShipment,
  removeShipmentsById,
  saveShipment,
  toggleShipment,
} from "../../../store/shipmentSlice";
import {
  ModifiedShipmentObject,
  SaveShipmentObject,
} from "../../../models/ShipmentObject";
import { OrderObjectMapApi } from "../../../models/OrderObject";
import MapComponent from "../../../components/MapComponent";

const itemsPerShipments = 9;
const exportedState = 1;

const getTurvoStatus = (shipment: ModifiedShipmentObject) => {
  return shipment.turvoShipmentStatus !== null &&
    shipment.turvoShipmentStatus !== undefined
    ? TurvoShipmentStatus.find((x) => x.key === shipment.turvoShipmentStatus)
        ?.text
    : null;
};

const getOmsStatus = (shipment: ModifiedShipmentObject) => {
  return shipment.state !== null && shipment.state !== undefined
    ? ShipmentStates.find((x) => x.key === shipment.state)?.text
    : null;
};

type ShipmentItemProps = {
  shipment: ModifiedShipmentObject;
  openScheduler: Dispatch<SetStateAction<ModifiedShipmentObject>>;
  maximumWeight: string;
};

const ShipmentItem = (props: ShipmentItemProps) => {
  const dispatch = useAppDispatch();
  const selectedOrderIds = useAppSelector(
    (state) => state.orders.selectedOrderIds
  );
  const isDefaultOrderFilter = useAppSelector(
    (state) => state.orders.isDefaultFilter
  );
  const selectedShipmentOrderIds = useAppSelector(
    (state) => state.shipments.selectedOrderIds
  );
  const [saveShipmentObject, setSaveShipmentObject] =
    useState<SaveShipmentObject | null>(null);

  const placeHoldersCount = Math.max(
    itemsPerShipments - props.shipment.items.length,
    0
  );

  const itemsWithPlaceholders: (OrderObjectMapApi | null)[] = [
    ...props.shipment.items,
    ...Array(placeHoldersCount).fill(null),
  ];

  const currencySign = props.shipment.items?.[0]?.currencySign ?? "N/A ";

  const handleOnDragStart = (
    event: DragEvent,
    payload: { shipment: ModifiedShipmentObject; vehicle: OrderObjectMapApi }
  ) => {
    const data = {
      shipmentId: payload.shipment.id,
      shipmentState: payload.shipment.state,
      vehicle: payload.vehicle,
      shipment: payload.shipment,
    };
    const selectedShipment = {
      shipment: payload.shipment,
      orderIds: [payload.vehicle.id],
    };

    if (!selectedShipmentOrderIds.includes(payload.vehicle.id)) {
      dispatch(addSelectedShipment(selectedShipment));
    }
    event.dataTransfer.setData("shipmentItem", JSON.stringify(data));
  };

  const removeShipment = (shipmentToRemove: ModifiedShipmentObject) => {
    dispatch(deleteShipment(shipmentToRemove))
      .unwrap()
      .then(() => {
        dispatch(
          removeSelectedShipment({
            shipment: shipmentToRemove,
            orderIds: shipmentToRemove.items.map((item) => item.id),
          })
        );
        dispatch(removeShipmentsById([shipmentToRemove.id]));
        dispatch(removeShipmentOrderItems(shipmentToRemove.items));
        dispatch(
          addMultipleSelected(
            shipmentToRemove.items
              .filter((item) => selectedShipmentOrderIds.includes(item.id))
              .map((item) => item.id)
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fromOrderListToShipmentList = async (
    updatedShipment: SaveShipmentObject
  ) => {
    dispatch(saveShipment(updatedShipment))
      .unwrap()
      .then((savedShipment) => {
        dispatch(
          addSelectedShipment({
            shipment: savedShipment,
            orderIds: selectedOrderIds,
          })
        );
        dispatch(postListStatistics());
        dispatch(removeMultipleOrderItems(selectedOrderIds));
        dispatch(removeAllSelected());

        if (!isDefaultOrderFilter) {
          dispatch(refreshOrders())
            .unwrap()
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleHandler = () => {
    saveToggleShipment(props.shipment);
    dispatch(toggleShipment(props.shipment));
  };

  const handleItemDrop = (
    event: DragEvent,
    shipmentDropTo: ModifiedShipmentObject
  ) => {
    // Don't allow shipment to shipment or dropping undefined order item
    if (
      event.dataTransfer.getData("shipmentItem") !== "" ||
      !event.dataTransfer.getData("orderItem")
    ) {
      return;
    }

    const orderDragFrom = event.dataTransfer.getData("orderItem");
    const orderDragFromObj = JSON.parse(orderDragFrom);

    if (orderDragFromObj.orderId) {
      const selectedVehicles = selectedOrderIds.map((id) => {
        return { id };
      });

      const updatedShipment: SaveShipmentObject = {
        ...shipmentDropTo,
        items: [...shipmentDropTo.items, ...selectedVehicles],
      };

      if (selectedOrderIds.length >= 100) {
        setSaveShipmentObject(updatedShipment);
      } else {
        fromOrderListToShipmentList(updatedShipment);
      }
    }
  };

  return (
    <Segment>
      <Dimmer inverted active={props.shipment.loading}>
        <Loader />
      </Dimmer>
      <Grid
        onDragEnter={(event: DragEvent) => event.preventDefault()}
        onDragOver={(event: DragEvent) => event.preventDefault()}
        onDrop={(event: DragEvent) => handleItemDrop(event, props.shipment)}
        doubling
        data-test="shipmentItem"
        id="shipmentGridDrop"
      >
        <Grid.Row
          verticalAlign="middle"
          columns={2}
          style={{ paddingBottom: 0 }}
        >
          <Grid.Column>
            {props.shipment.pickup && props.shipment.delivery ? (
              <HeaderLeftPanel>
                <CollapseIcon
                  name={
                    isCollapsed(props.shipment)
                      ? "triangle up"
                      : "triangle down"
                  }
                  onClick={toggleHandler}
                />
                <CityState>
                  {locationFormatter.toTitleCaseFrom(
                    props.shipment.pickup.city
                  )}
                  , {props.shipment.pickup.state}
                </CityState>
                <Divider>to</Divider>
                <CityState>
                  {locationFormatter.toTitleCaseFrom(
                    props.shipment.delivery.city
                  )}
                  , {props.shipment.delivery.state}
                </CityState>
                <Popup
                  position="top center"
                  on={["hover", "click"]}
                  disabled={props.shipment.routes.length === 0}
                  hoverable
                  content={
                    <MapPopup>
                      <MapComponent shipment={props.shipment} />
                    </MapPopup>
                  }
                  trigger={
                    <MapLogo>
                      <Button
                        title="Map Preview"
                        type="button"
                        size="mini"
                        icon="map outline"
                        content="Map"
                        disabled={props.shipment.routes.length === 0}
                      />
                    </MapLogo>
                  }
                />
              </HeaderLeftPanel>
            ) : null}
          </Grid.Column>

          <Grid.Column>
            <HeaderRightPanel>
              {isCollapsed(props.shipment) && props.shipment.isUnsaved ? (
                <UnsavedIconStyled
                  color="yellow"
                  size="big"
                  name="exclamation triangle"
                  title="Unsaved in Turvo"
                />
              ) : null}
              <ShipmentId>
                {!Number.isInteger(props.shipment.id) && "New"}
                {props.shipment.turvoShipmentCustomId ? (
                  <TurvoLabel>Turvo:</TurvoLabel>
                ) : null}
                {props.shipment.actionFlow === 1 ? <Icon name="cog" /> : null}
                {props.shipment.id &&
                  Number.isInteger(props.shipment.id) &&
                  (props.shipment.turvoShipmentCustomId ? (
                    <TurvoLink
                      href={`${import.meta.env.VITE_TURVO_URL}/#/${import.meta.env.VITE_TURVO_KEY}/shipments/${props.shipment.turvoShipmentId}/summary`}
                      target="_blank"
                    >
                      {props.shipment.turvoShipmentCustomId}
                    </TurvoLink>
                  ) : (
                    `DS-${props.shipment.id}`
                  ))}
              </ShipmentId>
              <ShipmentPrice>
                {currencySign}
                {formatPrice(props.shipment.costAmount ?? 0)}
              </ShipmentPrice>
            </HeaderRightPanel>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: 3 }}>
          <Grid.Column textAlign="right">
            <span style={{ color: "#bababa" }}>Status:</span>
            <Label style={{ border: 0 }} circular basic color="black">
              {getTurvoStatus(props.shipment) ?? getOmsStatus(props.shipment)}
            </Label>
          </Grid.Column>
        </Grid.Row>
        <ShipmentBody
          className={`collapsible ${
            isCollapsed(props.shipment) && "collapsed"
          }`}
        >
          <ItemsGrid columns={3} centered doubling role="list">
            {itemsWithPlaceholders.map((vehicle) => (
              <Grid.Column
                id="vehicleColumn"
                widescreen={5}
                computer={8}
                key={vehicle ? vehicle.id : crypto.randomUUID()}
                role="listitem"
                draggable={!!vehicle}
                onDragStart={(event: DragEvent) => {
                  if (vehicle) {
                    handleOnDragStart(event, {
                      shipment: props.shipment,
                      vehicle,
                    });
                  }
                }}
              >
                <Vehicle
                  selectedOrderIds={selectedShipmentOrderIds}
                  vehicle={vehicle}
                  shipment={props.shipment}
                  isOrder={false}
                />
              </Grid.Column>
            ))}
          </ItemsGrid>
          <Grid>
            <ButtonsRow columns={2} centered>
              <Grid.Column floated="left">
                <ShipmentWeight>
                  {props.shipment.totalWeight?.toLocaleString()}
                </ShipmentWeight>
                <ShipmentWeightMax>/{props.maximumWeight}lbs</ShipmentWeightMax>
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right">
                {props.shipment.isRouteUnoptimized ? (
                  <UnsavedIconStyled
                    color="orange"
                    size="big"
                    name="exclamation triangle"
                    title="Shipment route is not optimized"
                  />
                ) : null}
                {props.shipment.isUnsaved ? (
                  <UnsavedIconStyled
                    color="yellow"
                    size="big"
                    name="exclamation triangle"
                    title="Unsaved in Turvo"
                  />
                ) : null}
                <Button
                  onClick={() => removeShipment(props.shipment)}
                  disabled={props.shipment.state === exportedState}
                  negative
                >
                  Delete
                </Button>
                <Button
                  onClick={() => props.openScheduler(props.shipment)}
                  disabled={
                    props.shipment.items.length === 0 ||
                    props.shipment.items.some(
                      (i) => i.state === OrderState.Invisible
                    ) ||
                    props.shipment.turvoShipmentStatus ===
                      TurvoShipmentStatus.find((x) => x.key === 2112)?.key
                  }
                  primary
                >
                  {props.shipment.state === exportedState
                    ? "Update"
                    : "Schedule"}
                </Button>
              </Grid.Column>
            </ButtonsRow>
          </Grid>
        </ShipmentBody>
      </Grid>

      <WarningModal
        open={saveShipmentObject !== null}
        onContinue={() => {
          if (saveShipmentObject !== null) {
            fromOrderListToShipmentList(saveShipmentObject);
            setSaveShipmentObject(null);
          }
        }}
        onCancel={() => setSaveShipmentObject(null)}
      >
        You're manipulating 100+ orders, are you sure you want to continue?
      </WarningModal>
    </Segment>
  );
};

const TurvoLabel = styled.span`
  color: #bababa;
  font-size: 1.2rem;
`;

const ShipmentId = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 0.5rem;
`;

const ShipmentPrice = styled.div`
  background-color: #e5f9e7;
  display: inline-block;
  padding: 0.7rem;
  margin-left: 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
  box-shadow:
    0 0 0 1px #a3c293 inset,
    0 0 0 0 transparent;
  border-radius: 0.5rem;
`;

const HeaderRightPanel = styled.div`
  text-align: right;
`;

const HeaderLeftPanel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const CityState = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
`;

const ShipmentWeight = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
`;

const ShipmentWeightMax = styled.span`
  color: #bebebe;
  font-size: 1.2rem;
`;

const Divider = styled.span`
  margin: 0 0.5rem;
  color: #bababa;
  font-size: 1.2rem;
`;

const ItemsGrid = styled(Grid)`
  width: 100% !important;
  padding-right: 0rem !important;
`;

const TurvoLink = styled.a`
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 0.5rem;
`;

const CollapseIcon = styled(Icon)`
  cursor: pointer;
`;

const ShipmentBody = styled.div`
  width: 100% !important;
`;

const ButtonsRow = styled(Grid.Row)`
  margin-bottom: 1rem;
  display: flex !important;
  align-items: center !important;
`;

const UnsavedIconStyled = styled(Icon)`
  margin-right: 10px !important;
`;

const MapPopup = styled.div`
  width: 40vw;
  height: 50%;
`;

const MapLogo = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
`;

const formatPrice = (price: number) => Number(price).toFixed(2);

export default ShipmentItem;
