import moment from "moment-timezone";

const getFormattedDate = (date: string | null) => {
  if (date === null) {
    return null;
  }
  try {
    if (!moment(date).isValid()) {
      return null;
    }
    return moment(date).tz(moment.tz.guess()).format("MM/DD/YYYY HH:mm z");
  } catch (error) {
    console.error(`Failed to format date: ${error}`);
    return null;
  }
};

export default getFormattedDate;
