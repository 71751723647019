import { HubConnectionState } from "@microsoft/signalr";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SendMessage {
  name: string;
  messages: unknown[];
}

export interface SignalRState {
  messages: string[];
  connectionState: HubConnectionState;
}

const initialState: SignalRState = {
  messages: [],
  connectionState: HubConnectionState.Disconnected,
};

export const signalRSlice = createSlice({
  name: "signalR",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    connectServer: (state) => {},

    updateConnectionState: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionState = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    sendMessage: (state, action: PayloadAction<SendMessage>) => {},
    receiveMessage: (state, action: PayloadAction<string[]>) => {
      state.messages = action.payload;
    },
    listenForReceive: (state, action: PayloadAction<string>) => {
      state.messages = [action.payload];
    },
  },
});

export const {
  connectServer,
  updateConnectionState,
  sendMessage,
  receiveMessage,
  listenForReceive,
} = signalRSlice.actions;

export default signalRSlice.reducer;
