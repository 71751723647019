import { useState, useMemo, ChangeEvent, useEffect } from "react";
import {
  Header,
  Form,
  Divider,
  Search,
  InputOnChangeData,
} from "semantic-ui-react";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";
import debounce from "lodash-es/debounce";
import { getTurvoCustomers } from "../../../../store/turvoCustomerSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { OrderFormCustomer, OrderFormObject } from "./models/OrderFormObject";

const StyledDivider = styled(Divider)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0px !important;
`;

const ReadonlyInputStyled = styled.input`
  opacity: 0.7 !important;
`;

export type CustomerSectionProps = {
  control: Control<OrderFormObject>;
  customer: OrderFormCustomer;
};

const CustomerSection = (props: CustomerSectionProps) => {
  const dispatch = useAppDispatch();

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [customerSearch, setCustomerSearch] = useState<string>(
    props.customer.name
  );

  useEffect(() => {
    setCustomerSearch(props.customer.name);
  }, [props.customer.name]);

  const turvoCustomers = useAppSelector(
    (state) => state.turvoCustomer.customers
  );
  const turvoCustomersStatus = useAppSelector(
    (state) => state.turvoCustomer.status
  );

  // Have to find customer status initially because it is not on the order object
  const foundCustomerStatus = useMemo(() => {
    return turvoCustomers.find(
      (turvoCustomer) => turvoCustomer.id === props.customer.id
    )?.statusName;
  }, [turvoCustomers, props.customer.id]);

  const debouncedSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(getTurvoCustomers(value))
          .unwrap()
          .then(() => setSearchOpen(true))
          .catch((err) => {
            console.error(err);
          });
      }, 750),
    [dispatch, getTurvoCustomers]
  );

  const onSearchChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    setCustomerSearch(value);
    debouncedSearchChange(value);
  };

  return (
    <>
      <StyledHeader as="h3">Customer</StyledHeader>
      <StyledDivider />
      <Form.Group>
        <Controller
          name="customer"
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Form.Field
              id="customer.name"
              input={{ icon: "search", iconPosition: "left" }}
              control={Search}
              onSearchChange={onSearchChange}
              onResultSelect={(
                event: ChangeEvent<HTMLInputElement>,
                { result }: InputOnChangeData
              ) => {
                setSearchOpen(false);
                onChange({
                  id: result.id,
                  name: result.title,
                  statusName: result.description,
                });
                event.stopPropagation();
              }}
              loading={turvoCustomersStatus === "pending"}
              results={turvoCustomers.map((customer) => ({
                id: customer.id,
                title: customer.name,
                description: customer.statusName,
              }))}
              value={customerSearch}
              placeholder="Customer"
              label="Customer"
              width={10}
              fluid
              required
              open={searchOpen}
              onClick={() => setSearchOpen(true)}
              onBlur={() => setSearchOpen(false)}
            />
          )}
        />
        <Form.Field width={6}>
          <label htmlFor="customer.statusName">Status</label>
          <ReadonlyInputStyled
            id="customer.statusName"
            value={(props.customer.statusName || foundCustomerStatus) ?? ""}
            disabled
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default CustomerSection;
