import moment, { MomentInput } from "moment-timezone";

export default function displayDateTime(
  value: MomentInput,
  format: string = "DD MMM YY hh:mm A",
  utc: boolean = false
): string {
  const dateTime = moment(value);

  if (dateTime.isValid()) {
    return utc
      ? dateTime.utc().format(format)
      : dateTime.local().format(format);
  } else {
    console.error("Invalid date time:", value);
    return "Invalid date time";
  }
}
