import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { Message as SemanticMessage } from "semantic-ui-react";
import { MessageObject, removeMessage } from "../../store/alertMessageSlice";

type MessageProps = {
  message: MessageObject;
};

function Message(props: MessageProps) {
  const dispatch = useAppDispatch();
  const handleMessageRemoval = useCallback(() => {
    dispatch(removeMessage(props.message.id));
  }, [dispatch, props.message.id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleMessageRemoval();
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleMessageRemoval, props.message.id]);

  return (
    <SemanticMessage
      color={props.message.success ? "green" : "red"}
      onDismiss={handleMessageRemoval}
    >
      <SemanticMessage.Header>
        {props.message.success ? "Success" : "Error"}
      </SemanticMessage.Header>
      <p>{props.message.message}</p>
    </SemanticMessage>
  );
}

export default Message;
