import { ApiOrderFilter, OrdersFilter } from "../../models/Filter";
import { OrderState } from "../../utils/constants";

export const numberOrNull = (value: string | number) => {
  if (typeof value === "string") {
    return value ? Number(value) : null;
  }

  return value;
};

export const nonEmptyStringOrNull = (value: string) => {
  return value || null;
};

export const nonEmptyArrayOrNull = (value: any) => {
  return Array.isArray(value) && value.length ? value : null;
};

const states: Record<string, number[]> = {
  active: [OrderState.Pending, OrderState.Pause],
  inactive: [OrderState.Invisible],
};

interface MapFilterObject extends Omit<OrdersFilter, "pageSize" | "page"> {
  pageSize: number | null;
  page: number | null;
}

export const mapFilterObject = ({
  deliveryDate,
  destination,
  origin,
  pickupDate,
  search,
  page,
  pageSize,
  customerId,
  itemStates,
  vinAge,
  vinPriority,
  vinEquality,
}: MapFilterObject): ApiOrderFilter => {
  return {
    customer: {
      id: numberOrNull(customerId),
    },
    pickup: {
      search: origin,
      appointment: {
        from: nonEmptyStringOrNull(pickupDate),
        to: null,
      },
    },
    delivery: {
      search: destination,
      appointment: {
        from: nonEmptyStringOrNull(deliveryDate),
        to: null,
      },
    },
    search,
    pageSize,
    page,
    states: states[itemStates],
    orderVinAging: {
      age: numberOrNull(vinAge),
      condition: nonEmptyStringOrNull(vinEquality),
      priority: nonEmptyArrayOrNull(vinPriority),
    },
    attachedToShipment: false,
  };
};
