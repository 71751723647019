import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AutoBuildPreviewObject } from "../models/AutoBuildPreviewObject";
import { OrdersFilter } from "../models/Filter";
import { OrderBuildItemObject } from "../models/OrderBuildItemObject";
import { defaultFilter } from "../modules/OrderPanel/redux/initialState";
import httpHandler from "../utils/httpHandler";

export const cancelAutoBuild = createAsyncThunk<
  undefined,
  number,
  { rejectValue: string }
>("autoBuild/cancelAutoBuild", async (id, { rejectWithValue }) => {
  try {
    await httpHandler.instanceAxios.post("/api/autoBuild/cancel", {
      id: id,
    });
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface AutoBuildState {
  total: number;
  filter: OrdersFilter;
  loading: boolean;
  search: string;
  isBuilding: boolean;
  buildItems: OrderBuildItemObject[];
  buildItemsTotal: number;
  preview: {
    previewBuilding: boolean;
    buildComplete: boolean;
  };
  autoBuildPreview: AutoBuildPreviewObject;
}

export const initialState: AutoBuildState = {
  total: 0,
  filter: defaultFilter,
  loading: false,
  search: "",
  isBuilding: false,
  buildItems: [],
  buildItemsTotal: 0,
  preview: {
    previewBuilding: false,
    buildComplete: false,
  },
  autoBuildPreview: {
    incompatibleOrders: [],
    shipments: [],
    filterData: {
      customerId: [],
      fileImportId: [],
      from: "",
      to: "",
      states: [],
      customer: {
        id: 0,
        name: "",
      },
      pickup: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      delivery: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      orderGroupIds: [],
      ids: [],
      advancedSearch: false,
      orderType: 0,
      turvoShipmentStatus: [],
      metadata: [],
      includeTurvoShipment: false,
      expectedDate: {
        from: "",
        to: "",
      },
      pqType: [],
      flags: [],
      vin: "",
      attachedToShipment: false,
      turvoShipmentStatuses: [],
      pageSize: 0,
      page: 0,
      sorting: [],
      search: "",
    },
    autoBuildId: 0,
  },
};

const autoBuildSlice = createSlice({
  name: "autoBuild",
  initialState: initialState,
  reducers: {
    previewAutoBuild: (state) => {
      state.preview.previewBuilding = true;
    },
    previewAutoBuildSuccess: (
      state,
      action: PayloadAction<AutoBuildPreviewObject>
    ) => {
      state.preview.previewBuilding = false;
      state.autoBuildPreview = action.payload;
      state.buildItemsTotal =
        action.payload && action.payload.shipments
          ? action.payload.shipments.length
          : 0;
    },
    previewAutoBuildFailed: (state) => {
      state.preview.previewBuilding = false;
    },
    receiveAutoBuildData: (state) => {
      state.preview.previewBuilding = false;
    },
    autoBuildRestart: (state) => {
      state.preview.buildComplete = false;
    },
    autoBuildFinished: (state) => {
      state.preview.buildComplete = true;
    },
    startOrderBuild: (state) => {
      state.isBuilding = true;
      state.buildItems = [];
      state.buildItemsTotal = 0;
    },
    receiveBuildItem: (state, action: PayloadAction<OrderBuildItemObject>) => {
      state.buildItems = [...state.buildItems, action.payload];
      state.buildItemsTotal = action.payload.total;
    },
    clearAutoBuild: (state) => {
      state.buildItems = [];
      state.buildItemsTotal = 0;
      state.autoBuildPreview = initialState.autoBuildPreview;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelAutoBuild.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelAutoBuild.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(cancelAutoBuild.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  previewAutoBuild,
  previewAutoBuildSuccess,
  previewAutoBuildFailed,
  receiveAutoBuildData,
  autoBuildRestart,
  autoBuildFinished,
  startOrderBuild,
  receiveBuildItem,
  clearAutoBuild,
} = autoBuildSlice.actions;

export default autoBuildSlice.reducer;
