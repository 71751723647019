import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import httpHandler from "../utils/httpHandler";
import { PcMilerRouteObject } from "../models/PcMilerRouteObject";
import { FetchStatus } from "../types/FetchStatus";
import { GeoPoint } from "../models/GeoPoint";

export const fetchDistance = createAsyncThunk<
  PcMilerRouteObject,
  GeoPoint[],
  { rejectValue: string }
>("geocoding/getDistance", async (points, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<PcMilerRouteObject>(
      "/api/geocoding/getDistance",
      { points }
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface GeoCodingState {
  distance: number;
  status: FetchStatus;
}

export const initialState: GeoCodingState = {
  distance: 0,
  status: "",
};

const geocodingSlice = createSlice({
  name: "geoCoding",
  initialState: initialState,
  reducers: {
    resetDistance: (state) => {
      state.distance = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDistance.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchDistance.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.distance = action.payload.tMiles;
      })
      .addCase(fetchDistance.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const { resetDistance } = geocodingSlice.actions;

export default geocodingSlice.reducer;
