import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Customer } from "../models/Customer";
import { HttpResponsePagination } from "../models/HttpResponse";
import httpHandler from "../utils/httpHandler";

export const fetchCustomers = createAsyncThunk<
  HttpResponsePagination<Customer[]>,
  undefined,
  { rejectValue: string }
>("customers/fetchCustomers", async (_, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post(
      "/api/customer/list"
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface CustomerState {
  list: Customer[];
  loading: boolean;
}

export const initialState: CustomerState = {
  list: [],
  loading: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    receiveCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { receiveCustomers } = customerSlice.actions;

export default customerSlice.reducer;
