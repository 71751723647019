import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { HttpResponsePagination } from "../models/HttpResponse";
import { TurvoLocationObject } from "../models/TurvoLocationObject";

import httpHandler from "../utils/httpHandler";

export const getTurvoLocations = createAsyncThunk<
  HttpResponsePagination<TurvoLocationObject[]>,
  string | undefined,
  { rejectValue: string }
>("turvoLocation/getLocations", async (searchQuery, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<
      HttpResponsePagination<TurvoLocationObject[]>
    >(`api/turvoLocation/list`, {
      search: searchQuery,
    });
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface TurvoLocationState {
  locations: TurvoLocationObject[];
  status: "" | "pending" | "fulfilled" | "rejected";
}

export const initialState: TurvoLocationState = {
  locations: [],
  status: "",
};

const turvoLocationSlice = createSlice({
  name: "turvo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTurvoLocations.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTurvoLocations.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.locations = action.payload.data;
      })
      .addCase(getTurvoLocations.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export default turvoLocationSlice.reducer;
