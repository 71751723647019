import { useEffect, useState } from "react";
import { Dimmer, Loader, Segment, Grid, Header } from "semantic-ui-react";
import { HubConnectionState } from "@microsoft/signalr";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import SchedulerModal from "./Modals/SchedulerModal";
import BuildResultModal from "./Modals/BuildResultModal";
import ShipmentPanelHeaderControls from "./ShipmentPanelControls/ShipmentPanelHeaderControls";
import ShipmentItem from "./ShipmentItem/ShipmentItem";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { listenForReceive } from "../../store/signalRSlice";
import {
  clearShipmentBuildResult,
  getShipments,
  postListStatistics,
  updateShipmentFilter,
} from "../../store/shipmentSlice";
import httpHandler from "../../utils/httpHandler";

const ScrollContainer = styled.div`
  height: 64.4vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ShipmentScrollWrapper = styled(Segment)`
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
  height: 100%;
`;

const ShipmentSegment = styled(Segment)`
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 0 2px 0 rgba(34, 36, 38, 0.25) !important;
  padding: 10px !important;
`;

const ShipmentPanel = () => {
  const [shipment, toggleSchedulerModal] = useState(null);
  const [maximumWeight, setMaximumWeight] = useState<string>("");

  const {
    status,
    shipments,
    filter,
    totalPages,
    buildResult,
    isDefaultFilter,
  } = useAppSelector((state) => state.shipments);

  const connectionState = useAppSelector(
    (state) => state.signalR.connectionState
  );

  const dispatch = useAppDispatch();

  const loading = status === "pending";
  const hasMore = totalPages > filter.page;

  useEffect(() => {
    httpHandler.instanceAxios
      .get("api/shipment/list/maximumWeight")
      .then((response) => {
        setMaximumWeight(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (connectionState === HubConnectionState.Connected) {
      dispatch(listenForReceive("LockShipment"));
      dispatch(listenForReceive("LockShipmentsById"));
      dispatch(listenForReceive("UnlockShipment"));
      dispatch(listenForReceive("UnlockShipmentsById"));
    }
  }, [connectionState, dispatch]);

  const loadMore = () => {
    if (loading || !hasMore) {
      return;
    }

    dispatch(updateShipmentFilter({ page: filter.page + 1, pageSize: 10 }));
    dispatch(getShipments())
      .unwrap()
      .catch((err) => {
        console.error(err);
      });
    dispatch(postListStatistics());
  };

  return (
    <ShipmentSegment aria-label="region">
      <ShipmentPanelHeaderControls />
      <ShipmentScrollWrapper>
        <ScrollContainer>
          <Dimmer inverted active={loading}>
            <Loader>Loading...</Loader>
          </Dimmer>
          {shipments.length > 0 ? (
            <InfiniteScroll
              pageStart={filter.page}
              loadMore={loadMore}
              hasMore={hasMore}
              useWindow={false}
              loader={<span key={0}>Loading...</span>}
              initialLoad={false}
            >
              {shipments.map((el) => (
                <ShipmentItem
                  key={el.id}
                  shipment={el}
                  openScheduler={toggleSchedulerModal}
                  maximumWeight={maximumWeight}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Segment placeholder inverted color="blue">
                  <Header>
                    {isDefaultFilter
                      ? "Please choose a filter"
                      : "No data for the filter."}
                  </Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )}
        </ScrollContainer>
      </ShipmentScrollWrapper>

      {shipment && (
        <SchedulerModal
          opened={!!shipment}
          shipment={shipment || {}}
          onClose={() => toggleSchedulerModal(null)}
        />
      )}

      {buildResult && (
        <BuildResultModal
          opened={!!buildResult}
          buildResult={buildResult}
          onClose={() => {
            dispatch(clearShipmentBuildResult());
          }}
        />
      )}
    </ShipmentSegment>
  );
};

export default ShipmentPanel;
