import { UserManagerSettings, WebStorageStateStore } from "oidc-client";

export const AUTH_CONFIG: UserManagerSettings = {
  client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
  redirect_uri: `${window.location.origin}/oidc-callback`,
  post_logout_redirect_uri: window.location.origin,
  response_type: "token id_token",
  scope: "openid profile",
  authority: import.meta.env.VITE_AUTH_AUTHORITY,
  silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage }),
};
