import { OrderObject } from "../../models/OrderObject";
import { ApiShipmentFilter, ShipmentFilter } from "../../models/ShipmentFilter";
import {
  ModifiedShipmentObject,
  SaveShipmentObject,
  ShipmentObject,
} from "../../models/ShipmentObject";
import { mapApiOrders } from "../OrderPanel/redux/dataMapper";
import { nonEmptyStringOrNull } from "../OrderPanel/ApiOrderDataMapper";

export const formatFilterObjectForApi = ({
  showAll,
  page,
  pageSize,
  states,
  origin,
  destination,
  pickupDate,
  deliveryDate,
  search,
  orderStates,
  customerId,
  onlyUnsaved,
}: ShipmentFilter): ApiShipmentFilter => {
  // 2000+ is turvo statuses
  const shipmentStates = states.filter((x) => x < 2000);
  const turvoShipmentStates = states.filter((x) => x >= 2000);

  return {
    userFilterType: showAll ? 0 : 1,
    pickup: {
      search: origin,
      appointment: { from: nonEmptyStringOrNull(pickupDate) },
    },
    delivery: {
      search: destination,
      appointment: { from: nonEmptyStringOrNull(deliveryDate) },
    },
    search,
    page,
    pageSize,
    states: shipmentStates,
    turvoShipmentStatuses: turvoShipmentStates,
    orderStates:
      orderStates !== null && orderStates.length === 0 ? null : orderStates,
    customerId: !customerId ? [] : [customerId],
    onlyUnsaved: onlyUnsaved,
  };
};

export const mapApiShipment = (
  shipment: ShipmentObject
): ModifiedShipmentObject => ({
  id: shipment.id ?? 0,
  customerId: shipment.ordersGroups?.[0]?.customerId ?? null,
  pickup: shipment.pickup,
  delivery: shipment.delivery,
  resourceLink: shipment.resourceLink,
  turvoShipmentCustomId: shipment.turvoShipmentCustomId,
  turvoShipmentId: shipment.turvoShipmentId,
  totalWeight: shipment.totalWeight,
  costAmount: shipment.costAmount,
  state: shipment.state,
  routes: shipment.route,
  actionFlow: shipment.actionFlow,
  groupIds:
    shipment.ordersGroups
      ?.map((el) => el.id)
      .filter((id): id is number => id !== null) ?? [],
  turvoShipmentStatus: shipment.turvoShipmentStatus,
  items: shipment.ordersGroups
    ? shipment.ordersGroups
        .map((group) =>
          mapApiOrders(group.orders ?? []).map((order: OrderObject) => ({
            ...order,
            groupId: group.id,
          }))
        )
        .flat(2)
    : [],
  isUnsaved: shipment.isUnsaved,
  isRouteUnoptimized: shipment.isRouteUnoptimized,
});

export const mapApiShipments = (shipments: ShipmentObject[]) =>
  shipments.map((el) => mapApiShipment(el));

export const mapShipmentForApi = (shipment: SaveShipmentObject) => {
  const groupId = shipment.groupIds[0] || null;
  const customerId = shipment.items[0]?.customer?.id;
  const ordersGroups = [
    {
      id: groupId,
      orders: shipment.items.map((el) => ({ id: el.id })),
      customerId,
    },
  ];
  return {
    id: shipment.id,
    ordersGroups,
    customerId: shipment.customerId,
    turvoShipmentStatus: shipment.turvoShipmentStatus
  };
};
