import Message from "./Message";
import styled from "styled-components";
import { useAppSelector } from "../../store/hooks";

const MessageContainer = styled.div`
  width: 20%;
  position: absolute;
  z-index: 9999;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  right: 10px;
  top: 65px;
`;

const MessageViewer = () => {
  const messages = useAppSelector((state) => state.alertMessages.messages);

  return (
    <MessageContainer>
      {messages.map((el) => (
        <Message message={el} key={el.id} />
      ))}
    </MessageContainer>
  );
};

export { MessageViewer as default };
