import axios from "axios";
import userManager from "./userManager";

export const getToken = async () => {
  const userInfo = await userManager.getUser();
  if (userInfo) {
    return userInfo.access_token;
  }
  return "";
};

const instanceAxios = axios.create();

instanceAxios.interceptors.request.use(async (config) => {
  const token = await getToken();
  const newConfig = { ...config };
  newConfig.baseURL = import.meta.env.VITE_API_URL;
  newConfig.headers.Authorization = `Bearer ${token}`;
  if (config.method === "get") {
    newConfig.headers["Content-Type"] = "application/json";
  }
  return newConfig;
});

export default { instanceAxios };
