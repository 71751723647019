import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { HttpResponsePagination } from "../models/HttpResponse";
import { TurvoCustomer } from "../models/TurvoCustomer";
import httpHandler from "../utils/httpHandler";

export const getTurvoCustomers = createAsyncThunk<
  HttpResponsePagination<TurvoCustomer[]>,
  string | undefined,
  { rejectValue: string }
>("turvoCustomer/getCustomers", async (searchQuery, { rejectWithValue }) => {
  const queryParams = searchQuery
    ? `searchQuery=${searchQuery}&page=1&pageSize=100`
    : `page=1&pageSize=100`;
  try {
    const response = await httpHandler.instanceAxios.get<
      HttpResponsePagination<TurvoCustomer[]>
    >(`/api/turvoCustomer/list?${queryParams}`);
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface TurvoCustomerState {
  customers: TurvoCustomer[];
  status: "" | "pending" | "fulfilled" | "rejected";
}

export const initialState: TurvoCustomerState = {
  customers: [],
  status: "",
};

const turvoCustomerSlice = createSlice({
  name: "turvo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTurvoCustomers.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTurvoCustomers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.customers = action.payload.data;
      })
      .addCase(getTurvoCustomers.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export default turvoCustomerSlice.reducer;
