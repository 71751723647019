import { OrderObject } from "../models/OrderObject";
import { ShipmentObject } from "../models/ShipmentObject";

const getOrderIdentity = (order: OrderObject) => {
  if (!order.items) {
    return;
  }

  return order.items.map((i) => i.identityNumber).join(", ");
};

const getShipmentItems = (shipment: ShipmentObject) => {
  if (!shipment || !shipment.ordersGroups) {
    return "Unknown";
  }

  return shipment.ordersGroups
    .reduce<string[]>((acc, og) => {
      if (og.orders) {
        acc.push(og.orders.map(getOrderIdentity).join(", "));
      }

      return acc;
    }, [])
    .join(", ");
};

export { getOrderIdentity, getShipmentItems };
