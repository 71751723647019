import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ReshufflePreviewObject } from "../models/ReshufflePreviewObject";
import { OrdersFilter } from "../models/Filter";
import { OrderBuildItemObject } from "../models/OrderBuildItemObject";
import { ReshuffleDeleteObject } from "../models/ReshuffleDeleteObject";
import { defaultFilter } from "../modules/OrderPanel/redux/initialState";
import httpHandler from "../utils/httpHandler";

export const cancelReshuffle = createAsyncThunk<
  undefined,
  number,
  { rejectValue: string }
>("reshuffle/cancelReshuffle", async (id, { rejectWithValue }) => {
  try {
    await httpHandler.instanceAxios.post("/api/autoBuild/cancel", {
      id: id,
    });
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface ReshuffleState {
  total: number;
  filter: OrdersFilter;
  loading: boolean;
  search: string;
  isBuilding: boolean;
  deletedItems: number;
  buildItems: OrderBuildItemObject[];
  buildItemsTotal: number;
  preview: {
    previewBuilding: boolean;
    buildComplete: boolean;
  };
  reshufflePreview: ReshufflePreviewObject;
  invalid: boolean;
}

export const initialState: ReshuffleState = {
  total: 0,
  filter: defaultFilter,
  loading: false,
  search: "",
  isBuilding: false,
  deletedItems: 0,
  buildItems: [],
  buildItemsTotal: 0,
  preview: {
    previewBuilding: false,
    buildComplete: false,
  },
  reshufflePreview: {
    incompatibleOrders: [],
    shipments: [],
    filterData: {
      customerId: [],
      fileImportId: [],
      from: "",
      to: "",
      states: [],
      customer: {
        id: 0,
        name: "",
      },
      pickup: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      delivery: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      orderGroupIds: [],
      ids: [],
      advancedSearch: false,
      orderType: 0,
      turvoShipmentStatus: [],
      metadata: [],
      includeTurvoShipment: false,
      expectedDate: {
        from: "",
        to: "",
      },
      pqType: [],
      flags: [],
      vin: "",
      attachedToShipment: false,
      turvoShipmentStatuses: [],
      pageSize: 0,
      page: 0,
      sorting: [],
      search: "",
    },
    autoBuildId: 0,
    deletedShipments: [],
    shipmentFilterData: {
      customerId: [0],
      from: "",
      to: "",
      states: [],
      customer: {
        id: 0,
        name: "",
      },
      pickup: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      delivery: {
        id: "",
        name: "",
        state: [],
        appointment: {
          from: "",
          to: "",
        },
        search: "",
        radius: {
          center: {
            lat: 0,
            lon: 0,
          },
          radius: 0,
        },
      },
      turvoShipmentId: "",
      userId: 0,
      userFilterType: undefined,
      orderStates: [],
      turvoShipmentStatuses: [],
      onlyUnsaved: false,
      shipmentRatings: [],
      pageSize: 0,
      page: 1,
      sorting: [],
      search: "",
    },
  },
  invalid: false,
};

const reshuffleSlice = createSlice({
  name: "reshuffle",
  initialState: initialState,
  reducers: {
    previewReshuffle: (state) => {
      state.preview.previewBuilding = true;
    },
    previewReshuffleSuccess: (
      state,
      action: PayloadAction<ReshufflePreviewObject>
    ) => {
      state.preview.previewBuilding = false;
      state.reshufflePreview = action.payload;
      state.buildItemsTotal =
        action.payload.shipments.length +
        action.payload.deletedShipments.length;
    },
    receiveReshuffleData: (state) => {
      state.preview.previewBuilding = false;
    },
    reshuffleRestart: (state) => {
      state.preview.buildComplete = false;
    },
    reshuffleFinished: (state) => {
      state.preview.buildComplete = true;
    },
    startReshuffle: (state) => {
      state.isBuilding = true;
      state.buildItems = [];
    },
    receiveReshuffleItem: (
      state,
      action: PayloadAction<OrderBuildItemObject>
    ) => {
      state.buildItems = [...state.buildItems, action.payload];
    },
    receiveReshuffleDelete: (
      state,
      action: PayloadAction<ReshuffleDeleteObject>
    ) => {
      state.deletedItems = state.deletedItems + action.payload.deletedCount;
    },
    invalidateReshuffle: (state) => {
      state.invalid = true;
    },
    clearReshuffle: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelReshuffle.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelReshuffle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(cancelReshuffle.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  previewReshuffle,
  previewReshuffleSuccess,
  receiveReshuffleData,
  reshuffleRestart,
  reshuffleFinished,
  startReshuffle,
  receiveReshuffleItem,
  receiveReshuffleDelete,
  clearReshuffle,
  invalidateReshuffle,
} = reshuffleSlice.actions;

export default reshuffleSlice.reducer;
