import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Icon,
  Table,
  Input,
  Pagination,
  Dimmer,
  Loader,
  PaginationProps,
  Button,
  Grid,
} from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";
import { OmsActionLogObject } from "../../../models/OmsActionLogObject";
import { ActionsLogFilter } from "../../../models/Filter";
import httpHandler from "../../../utils/httpHandler";
import { HttpResponsePagination } from "../../../models/HttpResponse";
import { useAppDispatch } from "../../../store/hooks";
import { addErrorMessage } from "../../../store/alertMessageSlice";

const MainContainer = styled.div`
  padding: 1rem 2rem !important;
`;

const ActionIcon = styled(Icon)`
  margin-right: 5px !important;
`;

const getActionDescription = (logObj: OmsActionLogObject) => {
  const {
    shipmentCustomId,
    orderIdentityNumber,
    actionType,
    actionFlow,
    shipmentId,
  } = logObj;

  const actionFlowDescription =
    actionFlow === 1 ? (
      <>
        <Icon name="cog" />
        Auto
      </>
    ) : (
      "Manual"
    );
  const shipmentIdentificator = shipmentCustomId ?? `DS-${shipmentId}`;

  const actionTypes = {
    0: (
      <div>
        <ActionIcon name="plus" />
        Shipment <strong>{shipmentIdentificator}</strong> created (
        {actionFlowDescription})
      </div>
    ),
    1: (
      <div>
        <ActionIcon name="plus" />
        Order <strong>{orderIdentityNumber}</strong> added to{" "}
        <strong>{shipmentIdentificator}</strong> ({actionFlowDescription})
      </div>
    ),
    2: (
      <div>
        <ActionIcon name="cloud upload" />
        Shipment <strong>{shipmentIdentificator}</strong> exported (
        {actionFlowDescription})
      </div>
    ),
    3: (
      <div>
        <ActionIcon name="cloud upload" />
        Shipment <strong>{shipmentIdentificator}</strong> updated (
        {actionFlowDescription})
      </div>
    ),
    4: (
      <div>
        <ActionIcon name="cloud upload" />
        Order <strong>{orderIdentityNumber}</strong> removed from{" "}
        <strong>{shipmentIdentificator}</strong> ({actionFlowDescription})
      </div>
    ),
    5: (
      <div>
        <ActionIcon name="minus" />
        Shipment <strong>{shipmentIdentificator}</strong> deleted (
        {actionFlowDescription})
      </div>
    ),
  };
  return actionTypes[actionType];
};

const ActionsLogTable = () => {
  const dispatch = useAppDispatch();

  const [actionLogs, setActionLogs] = useState<OmsActionLogObject[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [status, setStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [filter, setFilter] = useState<ActionsLogFilter>({
    search: "",
    page: 1,
    pageSize: 15,
  });

  const fetchActionLogs = useCallback(() => {
    setStatus("pending");
    httpHandler.instanceAxios
      .post<HttpResponsePagination<OmsActionLogObject[]>>(
        "/api/actionsLog/list",
        filter
      )
      .then((response) => {
        setStatus("fulfilled");
        setActionLogs(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        setStatus("rejected");
        setError(true);
        dispatch(addErrorMessage(`Error: ${error}`));
      });
  }, [dispatch, filter]);

  useEffect(() => {
    fetchActionLogs();
  }, [fetchActionLogs]);

  const onPageChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    setFilter({ ...filter, page: data.activePage as number });
  };

  const onSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  };

  const onSearchKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter") {
      setFilter({ ...filter, search: search, page: 1 });
    }
  };

  const handleSearch = () => {
    setFilter({ ...filter, search: search, page: 1 });
  };

  const handleReset = () => {
    setSearch("");
    setFilter({ search: "", page: 1, pageSize: 15 });
  };

  return error ? (
    <MainContainer>
      <h1>Error - Unable to display data</h1>
    </MainContainer>
  ) : (
    <MainContainer>
      <Dimmer inverted active={status === "pending"}>
        <Loader>Loading...</Loader>
      </Dimmer>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Input
              placeholder="VIN, shipment id or user name..."
              value={search}
              action
              onChange={onSearchChange}
              onKeyDown={onSearchKeyDown}
              fluid={true}
            >
              <input />
              <Button primary onClick={handleSearch}>
                <Icon name="search" />
                Search
              </Button>
              <Button basic onClick={handleReset}>
                Reset
              </Button>
            </Input>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {actionLogs.map((log: OmsActionLogObject) => (
            <Table.Row key={log.id}>
              <Table.Cell>
                {moment(log.updateDate).format("MM/DD/YYYY HH:mm")}
              </Table.Cell>
              <Table.Cell>{getActionDescription(log)}</Table.Cell>
              <Table.Cell>{log.userName}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Pagination
                floated="right"
                totalPages={totalPages}
                activePage={filter.page}
                boundaryRange={2}
                onPageChange={onPageChange}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </MainContainer>
  );
};

export { ActionsLogTable as default };
