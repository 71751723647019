export const getWeightUnit = (vehicleWeight: number, isEu: boolean) => {
  return isEu
    ? `${Math.ceil(Math.ceil(vehicleWeight * 0.45359237)).toLocaleString()} kgs`
    : `${Math.ceil(vehicleWeight).toLocaleString()} lbs`;
};

export const getLengthUnit = (vehicleLength: number, isEu: boolean) => {
  return isEu
    ? `${Number((vehicleLength * 2.54).toFixed(1))} cm`
    : `${Number(vehicleLength.toFixed(1))}"`;
};

export const getDistanceUnit = (distance: number, isEu: boolean) => {
  return isEu ? Math.ceil(distance * 0.6213711922) : Math.ceil(distance);
};
