import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiShipmentFilter, ShipmentFilter } from "../models/ShipmentFilter";
import {
  formatFilterObjectForApi,
  mapApiShipment,
  mapApiShipments,
  mapShipmentForApi,
} from "../modules/ShipmentPanel/ApiShipmentDataMapper";
import httpHandler from "../utils/httpHandler";
import { OrderState } from "../utils/constants";
import {
  ModifiedShipmentObject,
  SaveShipmentObject,
  ShipmentObject,
} from "../models/ShipmentObject";
import { HttpResponsePagination } from "../models/HttpResponse";
import { RootState } from "./store";
import { isEqual } from "lodash";
import getDefaultShipment from "../utils/getDefaultShipment";
import { ShipmentBuildItem } from "../models/ShipmentBuildItem";
import { addErrorMessage } from "./alertMessageSlice";
import { ShipmentClearResult } from "../models/ShipmentClearResult";
import { ShipmentStatisticsObject } from "../models/ShipmentStatisticsObject";
import { SelectedShipmentsObject } from "../models/SelectedShipmentsObject";
import { FetchStatus } from "../types/FetchStatus";

export const getShipments = createAsyncThunk<
  HttpResponsePagination<ModifiedShipmentObject[]>,
  void,
  { rejectValue: string; state: RootState }
>(
  "shipments/getShipments",
  async (_, { rejectWithValue, getState }) => {
    const { filter } = getState().shipments;
    const filterForApi = formatFilterObjectForApi(filter);

    try {
      const response = await httpHandler.instanceAxios.post<
        HttpResponsePagination<ShipmentObject[]>
      >(`${import.meta.env.VITE_SL3_URL}/api/shipment/list`, filterForApi);

      return {
        ...response.data,
        data: mapApiShipments(response.data.data),
      };
    } catch (error) {
      return rejectWithValue((error as AxiosError).message);
    }
  },
  {
    condition: (_, { getState }) => {
      return !getState().shipments.isDefaultFilter;
    },
  }
);

export const saveShipment = createAsyncThunk<
  ModifiedShipmentObject,
  SaveShipmentObject,
  {
    rejectValue: string;
    state: RootState;
  }
>("shipments/saveShipment", async (shipment, { rejectWithValue, dispatch }) => {
  const apiShipment = mapShipmentForApi(shipment);

  try {
    const response = await httpHandler.instanceAxios.post<ShipmentObject>(
      "/api/shipment/save",
      apiShipment
    );

    response.data.error?.forEach((error) => {
      dispatch(addErrorMessage(error));
    });

    return mapApiShipment(response.data);
  } catch (error) {
    return rejectWithValue((error as AxiosError).response?.data as string);
  }
});

export const deleteShipment = createAsyncThunk<
  void,
  ModifiedShipmentObject,
  {
    rejectValue: string;
    state: RootState;
  }
>("shipments/deleteShipment", async (shipment, { rejectWithValue }) => {
  try {
    // If the shipment id is a string, it's a temporary FE ID the BE doesn't care about so skip request
    if (Number.isInteger(shipment.id)) {
      await httpHandler.instanceAxios.post<ShipmentObject>(
        "/api/shipment/delete",
        { ids: [shipment.id] }
      );
    }
  } catch (error) {
    return rejectWithValue((error as AxiosError).message);
  }
});

export const deleteShipments = createAsyncThunk<
  ShipmentClearResult,
  ApiShipmentFilter,
  {
    rejectValue: string;
    state: RootState;
  }
>("shipments/deleteShipments", async (requestObject, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.post<ShipmentClearResult>(
      "/api/shipment/delete/list",
      requestObject
    );

    return response.data;
  } catch (error) {
    return rejectWithValue((error as AxiosError).message);
  }
});

export const buildShipment = createAsyncThunk<
  ShipmentBuildItem | null,
  ModifiedShipmentObject,
  {
    rejectValue: string;
    state: RootState;
  }
>(
  "shipments/buildShipment",
  async (shipment, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpHandler.instanceAxios.post<{
        buildResults: ShipmentBuildItem[];
      }>("/api/shipment/build", [
        {
          shipmentId: shipment.id,
          customerId: shipment.customerId,
          customerName: shipment.customerName,
          turvoShipmentStatus: shipment.turvoShipmentStatus,
        },
      ]);

      const buildResult = response.data.buildResults[0];

      if (!buildResult) {
        dispatch(
          addErrorMessage(`Missing build result for shipment ${shipment.id}`)
        );

        return null;
      }

      buildResult.errors?.forEach((error) => {
        dispatch(addErrorMessage(error));
      });

      return buildResult;
    } catch (error) {
      return rejectWithValue((error as AxiosError).message);
    }
  }
);

export const postListStatistics = createAsyncThunk<
  ShipmentStatisticsObject,
  void,
  { rejectValue: string; state: RootState }
>(
  "shipments/listStatistics",
  async (_, { rejectWithValue, getState }) => {
    const { filter } = getState().shipments;
    const filterForApi = formatFilterObjectForApi(filter);

    try {
      const response =
        await httpHandler.instanceAxios.post<ShipmentStatisticsObject>(
          "/api/shipment/list/statistics",
          filterForApi
        );

      return response.data;
    } catch (error) {
      return rejectWithValue((error as AxiosError).message);
    }
  },
  {
    condition: (_, { getState }) => {
      return !getState().shipments.isDefaultFilter;
    },
  }
);

const shipmentState = {
  drafted: 0,
  exported: 1,
};

export const defaultFilter: ShipmentFilter = {
  showAll: true,
  states: [1, 0],
  page: 1,
  pageSize: 10,
  origin: "",
  destination: "",
  pickupDate: "",
  deliveryDate: "",
  search: "",
  orderStates: [OrderState.Pending, OrderState.Invisible],
  customerId: "",
  onlyUnsaved: false,
};

export interface ShipmentSlice {
  status: FetchStatus;
  statisticsStatus: FetchStatus;
  lastGetRequestId: string;
  shipments: ModifiedShipmentObject[];
  selectedShipments: SelectedShipmentsObject[];
  selectedOrderIds: number[];
  totalPages: number;
  filter: ShipmentFilter;
  filteredTotal: number;
  isDefaultFilter: boolean;
  buildResult: ShipmentBuildItem | null;
  statistics: ShipmentStatisticsObject;
}

export const initialState: ShipmentSlice = {
  status: "",
  statisticsStatus: "",
  lastGetRequestId: "",
  shipments: [],
  selectedShipments: [],
  selectedOrderIds: [],
  totalPages: 1,
  filter: defaultFilter,
  filteredTotal: 0,
  isDefaultFilter: true,
  buildResult: null,
  statistics: {
    allShipmentsCount: 0,
    myShipmentsCount: 0,
    unsavedShipmentsCount: 0,
  },
};

const shipmentSlice = createSlice({
  name: "shipments",
  initialState: initialState,
  reducers: {
    resetShipments: () => initialState,
    clearShipments: (state) => {
      state.shipments = [];
    },
    clearShipmentBuildResult: (state) => {
      state.buildResult = null;
    },
    updateShipmentFilter: (
      state,
      action: PayloadAction<Partial<ShipmentFilter>>
    ) => {
      const updatedFilter = { ...state.filter, ...action.payload };
      state.filter = updatedFilter;
      state.isDefaultFilter = isEqual(defaultFilter, updatedFilter);
    },
    addShipment: (state) => {
      state.shipments = [getDefaultShipment(), ...state.shipments];
    },
    removeShipmentsById: (
      state,
      action: PayloadAction<(string | number)[]>
    ) => {
      state.shipments = state.shipments.filter(
        (shipment) => !action.payload.includes(shipment.id)
      );
    },
    toggleShipment: (state, action: PayloadAction<ModifiedShipmentObject>) => {
      const shipmentIndex = state.shipments.findIndex(
        (shipment) => shipment.id === action.payload.id
      );

      if (shipmentIndex > -1) {
        state.shipments[shipmentIndex].collapsed =
          !state.shipments[shipmentIndex].collapsed;
      }
    },
    lockShipmentsById: (state, action: PayloadAction<(string | number)[]>) => {
      state.shipments.forEach((shipment) => {
        if (action.payload.includes(shipment.id)) {
          shipment.loading = true;
        }
      });
    },
    unlockShipmentsById: (
      state,
      action: PayloadAction<(string | number)[]>
    ) => {
      state.shipments.forEach((shipment) => {
        if (action.payload.includes(shipment.id)) {
          shipment.loading = false;
        }
      });
    },
    unlockShipmentAndUpdate: (
      state,
      action: PayloadAction<ModifiedShipmentObject>
    ) => {
      const shipmentIndex = state.shipments.findIndex(
        (shipment) => shipment.id === action.payload.id
      );

      if (shipmentIndex > -1) {
        state.shipments[shipmentIndex] = { ...action.payload, loading: false };
      }
    },
    addSelectedShipment: (
      state,
      action: PayloadAction<SelectedShipmentsObject>
    ) => {
      if (!action.payload.shipment.items) {
        return;
      }

      const shipmentIndex = state.selectedShipments.findIndex(
        (shipmentObj) => shipmentObj.shipment.id === action.payload.shipment.id
      );

      if (shipmentIndex > -1) {
        state.selectedShipments[shipmentIndex] = {
          shipment: action.payload.shipment,
          orderIds: Array.from(
            new Set([
              ...action.payload.orderIds,
              ...state.selectedShipments[shipmentIndex].orderIds,
            ])
          ),
        };
      } else {
        state.selectedShipments.push(action.payload);
      }

      state.selectedOrderIds = Array.from(
        new Set([...state.selectedOrderIds, ...action.payload.orderIds])
      );
    },
    selectAllShipments: (state) => {
      const selectedOrderIds: number[] = [];
      state.selectedShipments = state.shipments.map((shipment) => ({
        shipment,
        orderIds: shipment.items.map((item) => {
          selectedOrderIds.push(item.id);
          return item.id;
        }),
      }));
      state.selectedOrderIds = selectedOrderIds;
    },
    removeSelectedShipment: (
      state,
      action: PayloadAction<SelectedShipmentsObject>
    ) => {
      const shipmentIndex = state.selectedShipments.findIndex(
        (shipmentObj) => shipmentObj.shipment.id === action.payload.shipment.id
      );

      if (shipmentIndex > -1) {
        const orderIds = state.selectedShipments[shipmentIndex].orderIds.filter(
          (orderId) => !action.payload.orderIds.includes(orderId)
        );

        if (orderIds.length > 0) {
          state.selectedShipments[shipmentIndex] = {
            shipment: action.payload.shipment,
            orderIds,
          };
        } else {
          state.selectedShipments = state.selectedShipments.filter(
            (shipmentObj) =>
              shipmentObj.shipment.id !== action.payload.shipment.id
          );
        }
      }

      state.selectedOrderIds = state.selectedOrderIds.filter(
        (orderId) => !action.payload.orderIds.includes(orderId)
      );
    },
    updateSelectedShipment: (
      state,
      action: PayloadAction<SelectedShipmentsObject>
    ) => {
      const shipmentIndex = state.selectedShipments.findIndex(
        (shipmentObj) => shipmentObj.shipment.id === action.payload.shipment.id
      );

      if (shipmentIndex > -1) {
        const orderIds: number[] = [];
        const removedOrderIds: number[] = [];

        state.selectedShipments[shipmentIndex].orderIds.forEach((orderId) => {
          if (action.payload.orderIds.includes(orderId)) {
            orderIds.push(orderId);
          } else {
            removedOrderIds.push(orderId);
          }
        });

        if (orderIds.length > 0) {
          state.selectedShipments[shipmentIndex] = {
            shipment: action.payload.shipment,
            orderIds,
          };
        } else {
          state.selectedShipments = state.selectedShipments.filter(
            (shipmentObj) =>
              shipmentObj.shipment.id !== action.payload.shipment.id
          );
        }

        state.selectedOrderIds = state.selectedOrderIds.filter(
          (orderId) => !removedOrderIds.includes(orderId)
        );
      }
    },
    clearAllSelectedShipments: (state) => {
      state.selectedOrderIds = [];
      state.selectedShipments = [];
    },
    resetShipmentStatistics: (state) => {
      state.statistics = {
        allShipmentsCount: 0,
        myShipmentsCount: 0,
        unsavedShipmentsCount: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipments.pending, (state, action) => {
        state.status = "pending";
        state.lastGetRequestId = action.meta.requestId;
      })
      .addCase(getShipments.fulfilled, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "fulfilled";
          state.totalPages = action.payload.totalPages;
          state.filteredTotal = action.payload.total;

          if (state.filter.page === 1) {
            state.shipments = action.payload.data;
          } else {
            state.shipments = state.shipments.concat(action.payload.data);
          }
        }
      })
      .addCase(getShipments.rejected, (state, action) => {
        if (action.meta.requestId === state.lastGetRequestId) {
          state.status = "rejected";
        }
      })
      .addCase(saveShipment.pending, (state, action) => {
        state.status = "pending";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = true;
        }
      })
      .addCase(saveShipment.fulfilled, (state, action) => {
        state.status = "fulfilled";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex] = {
            ...action.payload,
            loading: false,
          };
        }
      })
      .addCase(saveShipment.rejected, (state, action) => {
        state.status = "rejected";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = false;
        }
      })
      .addCase(deleteShipment.pending, (state, action) => {
        state.status = "pending";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = true;
        }
      })
      .addCase(deleteShipment.fulfilled, (state, action) => {
        state.status = "fulfilled";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = false;
        }
      })
      .addCase(deleteShipment.rejected, (state, action) => {
        state.status = "rejected";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = false;
        }
      })
      .addCase(deleteShipments.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteShipments.fulfilled, (state) => {
        state.status = "fulfilled";
      })
      .addCase(deleteShipments.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(buildShipment.pending, (state, action) => {
        state.status = "pending";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = true;
        }
      })
      .addCase(buildShipment.fulfilled, (state, action) => {
        state.status = "fulfilled";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = false;

          if (action.payload) {
            state.buildResult = action.payload;
            state.shipments[shipmentIndex] = {
              ...state.shipments[shipmentIndex],
              id: action.payload.shipmentId,
              turvoShipmentCustomId: action.payload.shipmentCustomId,
              resourceLink: action.payload.resourceLink,
              state:
                action.payload.buildResult === 0
                  ? shipmentState.exported
                  : shipmentState.drafted,
            };
          }
        }
      })
      .addCase(buildShipment.rejected, (state, action) => {
        state.status = "rejected";

        const shipmentIndex = state.shipments.findIndex(
          (shipment) => shipment.id === action.meta.arg.id
        );

        if (shipmentIndex > -1) {
          state.shipments[shipmentIndex].loading = false;
        }
      })
      .addCase(postListStatistics.pending, (state) => {
        state.statisticsStatus = "pending";
      })
      .addCase(postListStatistics.fulfilled, (state, action) => {
        state.statisticsStatus = "fulfilled";
        state.statistics = action.payload;
      })
      .addCase(postListStatistics.rejected, (state) => {
        state.statisticsStatus = "rejected";
      });
  },
});

export const {
  resetShipments,
  clearShipments,
  clearShipmentBuildResult,
  updateShipmentFilter,
  addShipment,
  removeShipmentsById,
  toggleShipment,
  lockShipmentsById,
  unlockShipmentsById,
  unlockShipmentAndUpdate,
  addSelectedShipment,
  selectAllShipments,
  updateSelectedShipment,
  removeSelectedShipment,
  clearAllSelectedShipments,
  resetShipmentStatistics,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
