import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { VehicleObject } from '../models/VehicleObject';
import httpHandler from '../utils/httpHandler';

export const getVehicles = createAsyncThunk<
  VehicleObject[],
  undefined,
  { rejectValue: string }
>('vehicle/getVehicles', async (order, { rejectWithValue }) => {
  try {
    const response = await httpHandler.instanceAxios.get<VehicleObject[]>(
      '/api/vehicle/list'
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export interface VehicleState {
  vehicles: VehicleObject[];
  status: '' | 'pending' | 'fulfilled' | 'rejected';
}

export const initialState: VehicleState = {
  vehicles: [],
  status: '',
};

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.vehicles = action.payload;
      })
      .addCase(getVehicles.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export default vehicleSlice.reducer;
