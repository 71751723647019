import { Button, Modal, List, Grid, Header } from "semantic-ui-react";
import styled from "styled-components";
import { ShipmentBuildItem } from "../../../models/ShipmentBuildItem";

const ShipmentId = styled.span`
  font-size: 1.1rem;
`;

const errorStatus = 1;

type BuildResultModalProps = {
  opened: boolean;
  onClose: () => void;
  buildResult: ShipmentBuildItem;
};

const BuildResultModal = (props: BuildResultModalProps) => (
  <Modal size="small" onClose={props.onClose} open={props.opened} closeIcon>
    <Modal.Header>Build shipment result</Modal.Header>
    <Modal.Content>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h3">Shipment Id</Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">Shipment Turvo Id</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <ShipmentId>{props.buildResult.shipmentId}</ShipmentId>
          </Grid.Column>
          <Grid.Column>
            <ShipmentId>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.buildResult.resourceLink}
              >
                {props.buildResult.shipmentCustomId}
              </a>
            </ShipmentId>
          </Grid.Column>
        </Grid.Row>
        {props.buildResult.buildResult === errorStatus ? (
          <>
            <Grid.Row>
              <Header as="h3" color="orange">
                There was a problem building this shipment
              </Header>
            </Grid.Row>
            <Grid.Row columns={1}>
              <List>
                {props.buildResult.errors?.map((error) => (
                  <List.Item key={error}>{error}</List.Item>
                ))}
              </List>
            </Grid.Row>
          </>
        ): null}
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button color="yellow" onClick={props.onClose}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
);


export default BuildResultModal;
