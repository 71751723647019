export const convertToTimeZoneDate = ({
  utcDateString,
  timeZone,
}: {
  utcDateString: string;
  timeZone: string;
}): Date | null => {
  const parsedDate = new Date(utcDateString);

  if (isNaN(parsedDate.getTime())) {
    return null;
  }

  const localeString = parsedDate.toLocaleString(window.navigator.language, {
    hour12: false,
    timeZone,
  });

  return new Date(localeString);
};

export const getTzOffset = ({
  localDateObject,
  timeZone,
}: {
  localDateObject: Date;
  timeZone: string;
}) => {
  const localDate = new Date(
    localDateObject.toLocaleString(window.navigator.language)
  );
  const timeZoneDate = new Date(
    localDateObject.toLocaleString(window.navigator.language, { timeZone })
  );

  return localDate.getTime() - timeZoneDate.getTime();
};

export const convertToUtcDate = ({
  localDateObject,
  timeZone,
}: {
  localDateObject: Date;
  timeZone: string;
}): string => {
  const offset = getTzOffset({ localDateObject, timeZone });
  const offsetDate = new Date(localDateObject.getTime() + offset);

  return offsetDate.toISOString();
};
