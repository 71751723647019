import * as signalR from "@microsoft/signalr";
import { Dispatch, MiddlewareAPI, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from "./store";
import {
  previewAutoBuildSuccess,
  previewAutoBuildFailed,
  receiveAutoBuildData,
  autoBuildFinished,
  previewAutoBuild,
  receiveBuildItem,
} from "../store/autoBuildSlice";
import { getToken } from "../utils/httpHandler";
import { receiveMessage, updateConnectionState } from "./signalRSlice";
import { mapApiShipment } from "../modules/ShipmentPanel/ApiShipmentDataMapper";
import {
  ModifiedShipmentObject,
  ShipmentObject,
} from "../models/ShipmentObject";
import { IdsObject } from "../models/IdsObject";
import { removeMultipleOrderItems, removeMultipleSelected } from "./orderSlice";
import {
  lockShipmentsById,
  postListStatistics,
  removeSelectedShipment,
  removeShipmentsById,
  unlockShipmentAndUpdate,
  unlockShipmentsById,
  updateSelectedShipment,
} from "./shipmentSlice";
import {
  previewReshuffle,
  previewReshuffleSuccess,
  receiveReshuffleData,
  receiveReshuffleItem,
  receiveReshuffleDelete,
  reshuffleFinished,
  invalidateReshuffle,
} from "./reshuffleSlice";
import { addErrorMessage, addSuccessMessage } from "./alertMessageSlice";

const createSignalRMiddleware = (url: string) => {
  let connection: signalR.HubConnection;
  return (store: MiddlewareAPI) =>
    (next: Dispatch) =>
    (action: PayloadAction<any>) => {
      switch (action.type) {
        case "signalR/connectServer":
          if (connection === undefined) {
            connection = new signalR.HubConnectionBuilder()
              .withUrl(url, {
                accessTokenFactory: () => getToken(),
              })
              .configureLogging(signalR.LogLevel.Debug)
              .withAutomaticReconnect()
              .build();
            connection
              .start()
              .then(() =>
                store.dispatch(updateConnectionState(connection.state))
              )
              .catch((err) => console.error("HERE--->cannot connect", err));
          }
          break;
        case "signalR/listenForReceive":
          connection.off(action.payload);
          connection.on(action.payload, (...args) => {
            switch (action.payload) {
              case "AutoBuildPreviewProcessed":
                store.dispatch(receiveAutoBuildData());
                store.dispatch(previewAutoBuildSuccess(args[0]));
                break;

              case "AutoBuildPreviewFailed":
                store.dispatch(addErrorMessage("Auto Build Preview Failed"));
                store.dispatch(previewAutoBuildFailed());
                break;

              case "ReshufflePreviewProcessed":
                store.dispatch(receiveReshuffleData());
                store.dispatch(previewReshuffleSuccess(args[0]));
                break;

              case "ReshufflePreviewFailed":
              case "ReshuffleFailed":
                store.dispatch(invalidateReshuffle());
                store.dispatch(addErrorMessage(args[0]));
                break;

              case "ProcessedShipment":
                store.dispatch(receiveBuildItem(args[0]));
                break;

              case "ProcessedReshuffleShipment":
                store.dispatch(receiveReshuffleItem(args[0]));
                break;

              case "DeletedReshuffleShipment":
                store.dispatch(receiveReshuffleDelete(args[0]));
                break;

              case "BuildShipmentsComplete":
                store.dispatch(autoBuildFinished());
                store.dispatch(addSuccessMessage("Auto Build Complete!"));
                break;

              case "ReshuffleShipmentsComplete":
                store.dispatch(reshuffleFinished());
                store.dispatch(addSuccessMessage("Reshuffle Complete!"));
                break;

              case "LockShipment":
                {
                  const arg = args[0] as ShipmentObject;
                  if (arg.id) {
                    store.dispatch(lockShipmentsById([arg.id]));
                  }
                }
                break;
              case "LockShipmentsById":
                {
                  const arg = args[0] as IdsObject;
                  store.dispatch(lockShipmentsById(arg.ids));
                }
                break;
              case "UnlockShipment":
                handleUnlockShipment(
                  store as AppStore,
                  args[0] as { deleted: boolean; shipment: ShipmentObject }
                );
                break;
              case "UnlockShipmentsById":
                handleUnlockShipmentsById(
                  store as AppStore,
                  args[0] as {
                    deleted: boolean;
                    shipmentIds: IdsObject;
                  }
                );
                break;
              default:
                store.dispatch(receiveMessage(args));
            }
          });
          break;
        case "signalR/sendMessage":
          switch (action.payload.name) {
            case "previewBuildItems":
              store.dispatch(previewAutoBuild());
              break;

            case "previewReshuffle":
              store.dispatch(previewReshuffle());
              break;
          }

          if (
            connection === undefined ||
            connection.state !== signalR.HubConnectionState.Connected
          ) {
            console.error("You need to connect the server before sending");
          } else {
            connection
              .send(action.payload.name, ...action.payload.messages)
              .catch((err) => console.error("HERE--->cannot send", err));
          }
          break;
      }
      return next(action);
    };
};

const handleUnlockShipment = (
  store: AppStore,
  arg: { deleted: boolean; shipment: ShipmentObject }
) => {
  const updatedShipment: ModifiedShipmentObject = mapApiShipment(arg.shipment);

  if (arg.deleted || updatedShipment.items.length === 0) {
    // Shipment was deleted
    const { shipments, selectedShipments } = store.getState().shipments;

    const shipmentIndex = shipments.findIndex(
      (shipmentObj) => shipmentObj.id === updatedShipment.id
    );

    if (shipmentIndex > -1) {
      // Remove selected items on shipment side since shipment was deleted
      const selectedIndex = selectedShipments.findIndex(
        (shipmentObj) => shipmentObj.shipment.id === updatedShipment.id
      );

      if (selectedIndex > -1) {
        store.dispatch(
          removeSelectedShipment(selectedShipments[selectedIndex])
        );
      }

      // Remove shipment from store since it was deleted
      store.dispatch(removeShipmentsById([updatedShipment.id]));
      store.dispatch(postListStatistics());
    }
  } else {
    const { orders } = store.getState().orders;

    store.dispatch(unlockShipmentAndUpdate(updatedShipment));

    const orderIds = updatedShipment.items.map((item) => item.id);

    // Update selected shipment (remove selected orders if they aren't there anymore)
    store.dispatch(
      updateSelectedShipment({
        shipment: updatedShipment,
        orderIds,
      })
    );

    // Remove orders and selected orders from the order side if they were added to the shipment by someone else
    const knownOrderIds = orders
      .filter((order) => orderIds.includes(order.id))
      .map((order) => order.id);

    store.dispatch(removeMultipleSelected(knownOrderIds));
    store.dispatch(removeMultipleOrderItems(knownOrderIds));
  }
};

const handleUnlockShipmentsById = (
  store: AppStore,
  arg: { deleted: boolean; shipmentIds: IdsObject }
) => {
  const { shipments, selectedShipments } = store.getState().shipments;

  if (arg.deleted) {
    const foundShipments = shipments.filter((shipmentObj) =>
      arg.shipmentIds.ids.includes(shipmentObj.id as number)
    );

    if (foundShipments.length > 0) {
      arg.shipmentIds.ids.forEach((id) => {
        const selectedIndex = selectedShipments.findIndex(
          (shipmentObj) => shipmentObj.shipment.id === id
        );

        if (selectedIndex > -1) {
          store.dispatch(
            removeSelectedShipment(selectedShipments[selectedIndex])
          );
        }
      });

      store.dispatch(removeShipmentsById(arg.shipmentIds.ids));
      store.dispatch(postListStatistics());
    }
  } else {
    store.dispatch(unlockShipmentsById(arg.shipmentIds.ids));
  }
};

export default createSignalRMiddleware;
