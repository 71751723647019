import { reducer as oidc } from "redux-oidc";
import alertMessageReducer from "./alertMessageSlice";
import customerReducer from "./customerSlice";
import loaderReducer from "./loaderSlice";
import signalRReducer from "./signalRSlice";
import ordersReducer from "./orderSlice";
import shipmentsReducer from "./shipmentSlice";
import vehicleReducer from "./vehicleSlice";
import turvoCustomerReducer from "./turvoCustomerSlice";
import turvoLocationReducer from "./turvoLocationSlice";
import vinReducer from "./vinSlice";
import geocodingReducer from "./geocodingSlice";
import autoBuildReducer from "./autoBuildSlice";
import reshuffleReducer from "./reshuffleSlice";

const rootReducers = () => ({
  oidc,
  autoBuild: autoBuildReducer,
  customers: customerReducer,
  geocoding: geocodingReducer,
  loader: loaderReducer,
  alertMessages: alertMessageReducer,
  orders: ordersReducer,
  reshuffle: reshuffleReducer,
  shipments: shipmentsReducer,
  signalR: signalRReducer,
  turvoCustomer: turvoCustomerReducer,
  turvoLocation: turvoLocationReducer,
  vehicle: vehicleReducer,
  vin: vinReducer,
});

export default rootReducers;
