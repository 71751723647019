import { Segment, Grid } from "semantic-ui-react";
import styled from "styled-components";

import OrderPanelBody from "./OrderPanelBody/OrderPanelBody";
import OrderPanelHeader from "./OrderPanelHeader/OrderPanelHeader";
import OrderPanelDimmer from "./OrderPanelDimmer";

const SegmentContainer = styled(Segment)`
  display: flex;
  justify-content: center;
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 0 2px 0 rgba(34, 36, 38, 0.25) !important;
`;

const GridContainer = styled(Grid)`
  padding: 10px !important;
  width: 100% !important;
`;

const GridPanelBodyColumn = styled(Grid.Row)`
  padding-right: 1px !important;
`;

const OrderPanel = () => (
  <SegmentContainer aria-label="order panel">
    <OrderPanelDimmer />
    <GridContainer>
      <Grid.Row columns={1}>
        <Grid.Column>
          <OrderPanelHeader />
        </Grid.Column>
      </Grid.Row>
      <GridPanelBodyColumn columns={1}>
        <Grid.Column style={{ padding: 0 }}>
          <OrderPanelBody />
        </Grid.Column>
      </GridPanelBodyColumn>
    </GridContainer>
  </SegmentContainer>
);

export default OrderPanel;
