import { PropsWithChildren, useMemo, useState } from "react";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { ShipmentBuilderFormObject } from "../../models/ShipmentBuilderFormObject";
import ShipmentBuilderFormContext from "./context/ShipmentBuilderFormContext";

type ShipmentBuilderModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
};

const ShipmentBuilderModal = (
  props: PropsWithChildren<ShipmentBuilderModalProps>
) => {
  const [formObject, setFormObject] = useState<ShipmentBuilderFormObject>({
    algorithmFunctionName: "OmsGroupedBinPacking",
    vehicleId: 1,
    maxUnits: 9,
    minUnits: 4,
    originRadius: "25",
    destinationRadius: "25",
    maxStops: 3,
  });

  const value = useMemo(
    () => ({ formObject, setFormObject }),
    [formObject, setFormObject]
  );

  return (
    <ShipmentBuilderFormContext.Provider value={value}>
      <Modal open={props.isOpen} onClose={props.onClose} closeIcon>
        <Dimmer inverted active={props.isLoading}>
          <Loader />
        </Dimmer>

        {props.children}
      </Modal>
    </ShipmentBuilderFormContext.Provider>
  );
};

ShipmentBuilderModal.Content = (props: PropsWithChildren) => (
  <Modal.Content>{props.children}</Modal.Content>
);

ShipmentBuilderModal.Actions = (props: PropsWithChildren) => (
  <Modal.Actions>{props.children}</Modal.Actions>
);

export default ShipmentBuilderModal;
