import { useEffect, useState } from "react";
import { Button, Divider, Modal } from "semantic-ui-react";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { listenForReceive, sendMessage } from "../../../../store/signalRSlice";
import {
  changeFilter as changeOrderFilter,
  getOrders,
  removeAllSelected,
} from "../../../../store/orderSlice";
import {
  cancelReshuffle,
  clearReshuffle,
  reshuffleRestart,
} from "../../../../store/reshuffleSlice";

import {
  getShipments,
  postListStatistics,
  updateShipmentFilter,
} from "../../../../store/shipmentSlice";
import { formatFilterObjectForApi } from "../../ApiShipmentDataMapper";
import ShipmentBuilderProgress from "../../../ShipmentBuilder/ShipmentBuilderProgress";
import PreviewShipmentResults from "../../../ShipmentBuilder/PreviewShipmentResults";
import PreviewDeletedResults from "../../../ShipmentBuilder/PreviewDeletedResults";
import PreviewIncompatibleResults from "../../../ShipmentBuilder/PreviewIncompatibleResults";
import ShipmentBuilderForm from "../../../ShipmentBuilder/ShipmentBuilderForm";
import ShipmentBuilderStep from "../../../../models/ShipmentBuilderStep";
import { ShipmentBuilderApiObject } from "../../../../models/ShipmentBuilderApiObject";
import ShipmentBuilderModal from "../../../ShipmentBuilder/ShipmentBuilderModal";
import ShipmentBuilderFormButtons from "../../../ShipmentBuilder/ShipmentBuilderFormButtons";

type ReshuffleModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ReshuffleModal = (props: ReshuffleModalProps) => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(ShipmentBuilderStep.READY);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const orderFilter = useAppSelector((state) => state.orders.filter);
  const shipmentFilter = useAppSelector((state) => state.shipments.filter);

  const buildItems = useAppSelector((state) => state.reshuffle.buildItems);
  const buildItemsTotal = useAppSelector(
    (state) => state.reshuffle.buildItemsTotal
  );
  const deletedItems = useAppSelector((state) => state.reshuffle.deletedItems);
  const previewBuilding = useAppSelector(
    (state) => state.reshuffle.preview.previewBuilding
  );
  const buildComplete = useAppSelector(
    (state) => state.reshuffle.preview.buildComplete
  );
  const previewResult = useAppSelector(
    (state) => state.reshuffle.reshufflePreview
  );

  useEffect(() => {
    setStep(ShipmentBuilderStep.READY);
  }, [dispatch, props.isOpen]);

  useEffect(() => {
    if (buildComplete) {
      setStep(ShipmentBuilderStep.READY);
      dispatch(updateShipmentFilter({ page: 1, pageSize: 10 }));
      dispatch(changeOrderFilter({ ...orderFilter, page: 1, pageSize: 20 }));
      dispatch(getOrders())
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
      dispatch(getShipments())
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
      dispatch(reshuffleRestart());
      dispatch(removeAllSelected());
    }
  }, [dispatch, buildComplete]);

  useEffect(() => {
    if (previewResult) {
      setStep(ShipmentBuilderStep.PREVIEW);
    }
  }, [previewResult]);

  const onPreview = (apiObject: ShipmentBuilderApiObject) => {
    const shipmentFilterForApi = formatFilterObjectForApi(shipmentFilter);

    dispatch(listenForReceive("ReshufflePreviewProcessed"));
    dispatch(listenForReceive("ReshufflePreviewFailed"));
    dispatch(
      sendMessage({
        name: "previewReshuffle",
        messages: [{ ...apiObject, shipmentFilterData: shipmentFilterForApi }],
      })
    );
  };

  const handleReshuffle = () => {
    setIsConfirmOpen(false);
    setStep(ShipmentBuilderStep.BUILDING);
    dispatch(listenForReceive("ProcessedReshuffleShipment"));
    dispatch(listenForReceive("ReshuffleShipmentsComplete"));
    dispatch(listenForReceive("DeletedReshuffleShipment"));
    dispatch(listenForReceive("ReshuffleFailed"));
    dispatch(
      sendMessage({
        name: "reshuffleAndSchedule",
        messages: [previewResult.autoBuildId],
      })
    );
  };

  const onCancel = () => {
    dispatch(cancelReshuffle(previewResult["autoBuildId"]))
      .unwrap()
      .then(() => {
        dispatch(clearReshuffle());
        setStep(ShipmentBuilderStep.READY);
        dispatch(changeOrderFilter({ ...orderFilter, pageSize: 20, page: 1 }));
        dispatch(updateShipmentFilter({ page: 1, pageSize: 10 }));
        dispatch(getShipments())
          .unwrap()
          .catch((err) => {
            console.error(err);
          });
        dispatch(postListStatistics());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <ShipmentBuilderModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        isLoading={previewBuilding}
      >
        <ShipmentBuilderModal.Content>
          <ShipmentBuilderForm
            step={step}
            shipmentBuildId={previewResult.autoBuildId}
          />

          {step === ShipmentBuilderStep.PREVIEW && previewResult ? (
            <>
              <Divider />
              <section className="scrolling-container">
                <PreviewShipmentResults
                  shipments={previewResult.shipments}
                  altText="No shipments eligible for reshuffling."
                />
                <PreviewDeletedResults
                  deletedShipments={previewResult.deletedShipments}
                  headerText={`Shipments included in the reshuffle ${previewResult.deletedShipments.length}`}
                  altText="No shipments eligible for reshuffling."
                />
                <PreviewIncompatibleResults
                  incompatibleOrders={previewResult.incompatibleOrders}
                />
              </section>
            </>
          ) : null}

          {step === ShipmentBuilderStep.BUILDING ? (
            <ShipmentBuilderProgress
              buildItems={buildItems}
              buildItemsTotal={buildItemsTotal}
              processedTotal={buildItems.length + deletedItems}
            />
          ) : null}
        </ShipmentBuilderModal.Content>

        <ShipmentBuilderModal.Actions>
          <ShipmentBuilderFormButtons
            step={step}
            onClose={props.onClose}
            onCancel={onCancel}
            onPreview={onPreview}
          />

          {step === ShipmentBuilderStep.PREVIEW ? (
            <Button
              primary
              content="Confirm Reshuffle"
              onClick={() => setIsConfirmOpen(true)}
              disabled={previewResult.shipments.length === 0}
            />
          ) : null}
        </ShipmentBuilderModal.Actions>
      </ShipmentBuilderModal>

      <Modal
        closeIcon
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        size="mini"
      >
        <Modal.Header>Confirm Reshuffle</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to reshuffle?</p>
          <p>
            Reshuffling <strong>{previewResult.shipments.length}</strong>{" "}
            shipments
          </p>
          <p>
            Removing <strong>{previewResult.deletedShipments.length}</strong>{" "}
            shipments
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsConfirmOpen(false)}>No</Button>
          <Button primary onClick={handleReshuffle}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ReshuffleModal;
