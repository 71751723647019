import {
  nonEmptyStringOrNull,
  numberOrNull,
} from "../../../ApiOrderDataMapper";
import { OrderPostObject } from "../models/OrderPostObject";
import { OrderFormObject } from "../models/OrderFormObject";
import { convertToUtcDate } from "./dateConversion";

const mapFormToApiOrder = (
  orderFormObject: OrderFormObject
): OrderPostObject => {
  const pickupStart = orderFormObject.pickup.appointment.startDate
    ? convertToUtcDate({
        localDateObject: orderFormObject.pickup.appointment.startDate,
        timeZone: orderFormObject.pickup.appointment.timezone,
      })
    : null;

  const pickupEnd = orderFormObject.pickup.appointment.endDate
    ? convertToUtcDate({
        localDateObject: orderFormObject.pickup.appointment.endDate,
        timeZone: orderFormObject.pickup.appointment.timezone,
      })
    : null;

  const deliveryStart = orderFormObject.delivery.appointment.startDate
    ? convertToUtcDate({
        localDateObject: orderFormObject.delivery.appointment.startDate,
        timeZone: orderFormObject.delivery.appointment.timezone,
      })
    : null;

  const deliveryEnd = orderFormObject.delivery.appointment.endDate
    ? convertToUtcDate({
        localDateObject: orderFormObject.delivery.appointment.endDate,
        timeZone: orderFormObject.delivery.appointment.timezone,
      })
    : null;

  return {
    id: orderFormObject.id,
    customer: orderFormObject.customer,
    externalIds: orderFormObject.externalId.type.value
      ? [orderFormObject.externalId]
      : [],
    items: [
      {
        ...orderFormObject.item,
        identityNumber: orderFormObject.item.name,
        qty: numberOrNull(orderFormObject.item.qty),
        length: numberOrNull(orderFormObject.item.length),
        width: numberOrNull(orderFormObject.item.width),
        height: numberOrNull(orderFormObject.item.height),
        weight: numberOrNull(orderFormObject.item.weight),
        wheelbase: nonEmptyStringOrNull(orderFormObject.item.wheelbase),
      },
    ],
    equipment: orderFormObject.equipment,
    pickup: {
      ...orderFormObject.pickup,
      poNumbers: [orderFormObject.pickup.poNumber],
      appointment: {
        scheduling: orderFormObject.pickup.appointment.scheduling,
        timezone: orderFormObject.pickup.appointment.timezone,
        start: pickupStart,
        end: pickupEnd,
      },
    },
    delivery: {
      ...orderFormObject.delivery,
      poNumbers: [orderFormObject.delivery.poNumber],
      appointment: {
        scheduling: orderFormObject.delivery.appointment.scheduling,
        timezone: orderFormObject.delivery.appointment.timezone,
        start: deliveryStart,
        end: deliveryEnd,
      },
    },
    costs: orderFormObject.costs.map((cost) => ({
      ...cost,
      qty: numberOrNull(cost.qty),
      price: numberOrNull(cost.price),
      amount: numberOrNull(cost.amount),
    })),
  };
};

export default mapFormToApiOrder;
