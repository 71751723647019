import moment, { Moment } from "moment";
import { ModifiedShipmentObject } from "../models/ShipmentObject";

type ShipmentState = {
  id: number;
  modifyDate: Moment;
};

const removeObsoleteStates = (states: ShipmentState[]) =>
  states.filter((el) =>
    moment(el.modifyDate).isAfter(moment().add(-30, "days"))
  );

export const toggleShipment = (shipment: ModifiedShipmentObject) => {
  const shipmentsStates = JSON.parse(
    localStorage.getItem("shipmentsCollapseStatuses") ?? "[]"
  );
  let updatedStates;
  const isCollapsed = shipmentsStates.some(
    (el: ShipmentState) => el.id === shipment.id
  );
  if (!isCollapsed) {
    updatedStates = [
      ...removeObsoleteStates(shipmentsStates),
      { id: shipment.id, modifyDate: moment() },
    ];
  } else {
    updatedStates = removeObsoleteStates(shipmentsStates).filter(
      (el) => el.id !== shipment.id
    );
  }
  localStorage.setItem(
    "shipmentsCollapseStatuses",
    JSON.stringify(updatedStates)
  );
};

export const isCollapsed = (shipment: ModifiedShipmentObject) => {
  const shipmentsStates = JSON.parse(
    localStorage.getItem("shipmentsCollapseStatuses") ?? "[]"
  );
  return shipmentsStates.some((el: ShipmentState) => el.id === shipment.id);
};
