import { useEffect, useState } from "react";
import { Button, Divider } from "semantic-ui-react";

import ShipmentBuilderStep from "../../../../models/ShipmentBuilderStep";
import {
  autoBuildRestart,
  cancelAutoBuild,
  clearAutoBuild,
} from "../../../../store/autoBuildSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  changeFilter as changeOrderFilter,
  getOrders,
  removeAllSelected,
} from "../../../../store/orderSlice";
import {
  getShipments,
  postListStatistics,
  updateShipmentFilter,
} from "../../../../store/shipmentSlice";
import { listenForReceive, sendMessage } from "../../../../store/signalRSlice";
import ShipmentBuilderProgress from "../../../ShipmentBuilder/ShipmentBuilderProgress";
import PreviewShipmentResults from "../../../ShipmentBuilder/PreviewShipmentResults";
import PreviewIncompatibleResults from "../../../ShipmentBuilder/PreviewIncompatibleResults";
import ShipmentBuilderForm from "../../../ShipmentBuilder/ShipmentBuilderForm";
import { ShipmentBuilderApiObject } from "../../../../models/ShipmentBuilderApiObject";
import ShipmentBuilderModal from "../../../ShipmentBuilder/ShipmentBuilderModal";
import ShipmentBuilderFormButtons from "../../../ShipmentBuilder/ShipmentBuilderFormButtons";

type AutoBuildModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AutoBuildModal = (props: AutoBuildModalProps) => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(ShipmentBuilderStep.READY);

  const orderFilter = useAppSelector((state) => state.orders.filter);

  const buildItems = useAppSelector((state) => state.autoBuild.buildItems);
  const buildItemsTotal = useAppSelector(
    (state) => state.autoBuild.buildItemsTotal
  );
  const previewBuilding = useAppSelector(
    (state) => state.autoBuild.preview.previewBuilding
  );
  const buildComplete = useAppSelector(
    (state) => state.autoBuild.preview.buildComplete
  );
  const previewResult = useAppSelector(
    (state) => state.autoBuild.autoBuildPreview
  );

  useEffect(() => {
    setStep(ShipmentBuilderStep.READY);
    dispatch(clearAutoBuild());
  }, [dispatch, props.isOpen]);

  useEffect(() => {
    if (buildComplete) {
      setStep(ShipmentBuilderStep.READY);
      dispatch(updateShipmentFilter({ page: 1, pageSize: 10 }));
      dispatch(changeOrderFilter({ ...orderFilter, page: 1, pageSize: 20 }));
      dispatch(getOrders())
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
      dispatch(getShipments())
        .unwrap()
        .catch((err) => {
          console.error(err);
        });
      dispatch(postListStatistics());
      dispatch(autoBuildRestart());
      dispatch(removeAllSelected());
    }
  }, [dispatch, buildComplete]);

  useEffect(() => {
    if (previewResult) {
      setStep(ShipmentBuilderStep.PREVIEW);
    }
  }, [previewResult]);

  const onPreview = (apiObject: ShipmentBuilderApiObject) => {
    dispatch(listenForReceive("AutoBuildPreviewProcessed"));
    dispatch(listenForReceive("AutoBuildPreviewFailed"));
    dispatch(
      sendMessage({
        name: "previewBuildItems",
        messages: [apiObject],
      })
    );
  };

  const handleBuild = () => {
    setStep(ShipmentBuilderStep.BUILDING);
    dispatch(listenForReceive("ProcessedShipment"));
    dispatch(listenForReceive("BuildShipmentsComplete"));
    dispatch(
      sendMessage({
        name: "buildShipments",
        messages: [previewResult.autoBuildId],
      })
    );
  };

  const handleBuildAndSchedule = () => {
    setStep(ShipmentBuilderStep.BUILDING);
    dispatch(listenForReceive("ProcessedShipment"));
    dispatch(listenForReceive("BuildShipmentsComplete"));
    dispatch(
      sendMessage({
        name: "buildAndScheduleShipments",
        messages: [previewResult.autoBuildId],
      })
    );
  };

  const onCancel = () => {
    dispatch(cancelAutoBuild(previewResult["autoBuildId"]))
      .unwrap()
      .then(() => {
        dispatch(clearAutoBuild());
        setStep(ShipmentBuilderStep.READY);
        dispatch(changeOrderFilter({ ...orderFilter, pageSize: 20, page: 1 }));
        dispatch(updateShipmentFilter({ page: 1, pageSize: 10 }));
        dispatch(getShipments())
          .unwrap()
          .catch((err) => {
            console.error(err);
          });
        dispatch(postListStatistics());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <ShipmentBuilderModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={previewBuilding}
    >
      <ShipmentBuilderModal.Content>
        <ShipmentBuilderForm
          step={step}
          shipmentBuildId={previewResult.autoBuildId}
        />

        {step === ShipmentBuilderStep.PREVIEW && previewResult ? (
          <>
            <Divider />
            <section className="scrolling-container">
              <PreviewShipmentResults shipments={previewResult.shipments} />
              <PreviewIncompatibleResults
                incompatibleOrders={previewResult.incompatibleOrders}
              />
            </section>
          </>
        ) : null}

        {step === ShipmentBuilderStep.BUILDING ? (
          <ShipmentBuilderProgress
            buildItems={buildItems}
            buildItemsTotal={buildItemsTotal}
            processedTotal={buildItems.length}
          />
        ) : null}
      </ShipmentBuilderModal.Content>

      <ShipmentBuilderModal.Actions>
        <ShipmentBuilderFormButtons
          step={step}
          onClose={props.onClose}
          onCancel={onCancel}
          onPreview={onPreview}
        />

        {step === ShipmentBuilderStep.PREVIEW ? (
          <>
            <Button primary content="Build" onClick={handleBuild} />
            <Button
              primary
              content="Build & Schedule"
              onClick={handleBuildAndSchedule}
            />
          </>
        ) : null}
      </ShipmentBuilderModal.Actions>
    </ShipmentBuilderModal>
  );
};

export default AutoBuildModal;
