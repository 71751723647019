import { createBrowserHistory } from "history";
import createRootReducer from "./reducer";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import signalRMiddleware from "./signalRMiddleware";

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getMiddleWare) =>
    getMiddleWare({
      serializableCheck: {
        ignoredActions: ["redux-oidc/USER_FOUND"],
        ignoredPaths: ["oidc.user"],
      },
    }).concat(signalRMiddleware(import.meta.env.VITE_API_URL + "/hubs/shipmenthub")),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: createRootReducer(),
    preloadedState,
    middleware: (getMiddleWare) =>
      getMiddleWare({
        serializableCheck: {
          ignoredActions: ["redux-oidc/USER_FOUND"],
          ignoredPaths: ["oidc.user"],
        },
      }).concat(signalRMiddleware(import.meta.env.VITE_API_URL + "/hubs/shipmenthub")),
  });
}

export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
