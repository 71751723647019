import { Header, Table } from "semantic-ui-react";
import { ShipmentIdsObject } from "../../models/ShipmentIdsObject";

type PreviewDeletedResultsProps = {
  deletedShipments: ShipmentIdsObject[];
  headerText: string;
  altText: string;
};

const PreviewDeletedResults = (props: PreviewDeletedResultsProps) => (
  <>
    <Header as="h3">{props.headerText}</Header>
    {props.deletedShipments.length > 0 ? (
      <section className="scrollable-container">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Shipment ID</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.deletedShipments.map((shipment) => {
              return (
                <Table.Row key={shipment.slShipmentId}>
                  <Table.Cell>
                    {shipment.turvoShipmentCustomId ||
                      "DS-" + shipment.slShipmentId}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </section>
    ) : (
      <p>{props.altText}</p>
    )}
  </>
);

export default PreviewDeletedResults;
