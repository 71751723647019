import AuthContainer from "../../components/AuthContainer";
import AutoBuildTable from "./components/AutoBuildTable";

const AutoBuildsComponent = () => (
  <AuthContainer>
    <AutoBuildTable />
  </AuthContainer>
);

export default AutoBuildsComponent;
