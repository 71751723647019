import { useCallback, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Dimmer,
  Loader,
  Button,
  PaginationProps,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import styled from "styled-components";
import displayDateTime from "../../../utils/dateTimeHelper";
import { useAppDispatch } from "../../../store/hooks";
import { cancelAutoBuild } from "../../../store/autoBuildSlice";
import { AutoBuildsState } from "../../../models/AutoBuildsState";
import { AutoBuildsObject } from "../../../models/AutoBuildsObject";
import httpHandler from "../../../utils/httpHandler";

const AutoBuildTable = () => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalAutoBuilds, setTotalAutoBuilds] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const [autoBuilds, setAutoBuilds] = useState<AutoBuildsObject[]>([]);
  const [error, setError] = useState<string>("");
  const [firstRowDisplayed, setFirstRowDisplayed] = useState<number>(0);
  const [lastRowDisplayed, setLastRowDisplayed] = useState<number>(0);

  const fetchAutoBuilds = useCallback(() => {
    setStatus("pending");
    const filter = {
      page: currentPage,
      pageSize: pageSize,
    };

    httpHandler.instanceAxios
      .post("/api/autoBuild/list", filter)
      .then((response) => {
        setStatus("fulfilled");
        setAutoBuilds(response.data.data);
        setTotalPages(response.data.totalPages);
        setTotalAutoBuilds(response.data.total);
        const rows = pageSize * currentPage;
        setFirstRowDisplayed(rows - pageSize + 1);
        const lastRow = rows > response.data.total ? response.data.total : rows;
        setLastRowDisplayed(lastRow);
      })
      .catch((error) => {
        setStatus("rejected");
        setError(error.message);
      });
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchAutoBuilds();
  }, [currentPage, fetchAutoBuilds, pageSize]);

  const onPageChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    setCurrentPage(data.activePage as number);
  };

  const onDropdownChange = (
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setPageSize(data.value as number);
  };

  const handleCancel = (id: number) => {
    dispatch(cancelAutoBuild(id))
      .unwrap()
      .then(() => {
        fetchAutoBuilds();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const pageSizeOptions = [
    { key: 10, text: "10", value: 10 },
    { key: 15, text: "15", value: 15 },
    { key: 20, text: "20", value: 20 },
    { key: 30, text: "30", value: 30 },
  ];

  return status === "rejected" ? (
    <MainContainer>
      <h1>Error - Unable to display data</h1>
      <h2>Error Returned: {error}</h2>
    </MainContainer>
  ) : (
    <MainContainer>
      <Dimmer inverted active={status === "pending"}>
        <Loader>Loading...</Loader>
      </Dimmer>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {autoBuilds.map((record: AutoBuildsObject) => (
            <Table.Row key={record.id}>
              <Table.Cell>
                {displayDateTime(record.createDate, "DD MMM YY h:mm:ss A")}
              </Table.Cell>
              <Table.Cell>{record.userName}</Table.Cell>
              <Table.Cell>
                {`${record.shipmentsCount}/${record.totalShipments} ${
                  AutoBuildsState[record.state]
                }`}
              </Table.Cell>
              <Table.Cell>
                {record.state === 1 && (
                  <Button
                    size="mini"
                    onClick={() => {
                      handleCancel(record.id);
                    }}
                    color="red"
                  >
                    Cancel
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Dropdown
                selection
                compact
                options={pageSizeOptions}
                value={pageSize}
                onChange={onDropdownChange}
                aria-label="Rows-per-page"
              />
              <>
                {firstRowDisplayed} - {lastRowDisplayed} of {totalAutoBuilds}
              </>
              <Pagination
                floated="right"
                totalPages={totalPages}
                activePage={currentPage}
                boundaryRange={2}
                onPageChange={onPageChange}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </MainContainer>
  );
};

export default AutoBuildTable;

const MainContainer = styled.div`
  padding: 1rem 2rem !important;
`;
