import { Grid, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";
import ShipmentPanelComponent from "../ShipmentPanel/ShipmentPanel";
import OrderPanelComponent from "../OrderPanel/OrderPanel";
import AuthContainer from "../../components/AuthContainer";
import { useAppSelector } from "../../store/hooks";

const MainGrid = styled(Grid)`
  padding: 1rem 2rem !important;
`;

const MainPageComponent = () => {
  const loader = useAppSelector((state) => state.loader);

  return (
    <div>
      <AuthContainer>
        <MainGrid columns={2} role="main">
          <Grid.Row>
            <Grid.Column>
              <OrderPanelComponent />
            </Grid.Column>
            <Grid.Column>
              <ShipmentPanelComponent />
            </Grid.Column>
          </Grid.Row>
        </MainGrid>
      </AuthContainer>
      <Dimmer inverted active={loader.active}>
        <Loader content="Loading" />
      </Dimmer>
    </div>
  );
};

export { MainPageComponent as default };
