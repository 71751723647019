import styled from "styled-components";
import { Header, Grid, Icon, Button, Radio } from "semantic-ui-react";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import httpHandler from "../../../utils/httpHandler";
import { addErrorMessage } from "../../../store/alertMessageSlice";

type ShipmentPanelFilterButtonsProps = {
  headerText: string;
  showAll: boolean;
  onMyShipmentsClick: (checked: boolean) => void;
  onlyUnsaved: boolean;
  onUnsavedShipmentsClick: (checked: boolean) => void;
  unsavedTotal: number;
  refresh: () => void;
  resetFilter: () => void;
  setFilterPanelOpened: (opened: boolean) => void;
  filterPanelOpened: boolean;
};

const ShipmentPanelFilterButtons = (props: ShipmentPanelFilterButtonsProps) => {
  const dispatch = useAppDispatch();

  const { filter } = useAppSelector((state) => state.shipments);
  const { isDefaultFilter } = useAppSelector((state) => state.shipments);
  const { statistics: shipmentStatistics } = useAppSelector(
    (state) => state.shipments
  );

  const handleExportUnsaved = () => {
    if (shipmentStatistics.unsavedShipmentsCount === 0) {
      dispatch(addErrorMessage("No unsaved shipment found!"));
      return;
    }
    const custid = [filter.customerId];

    const searchFilter = {
      //Filter for Unsaved items
      pageSize: 5000,
      page: 1,
      states: [1, 0],
      orderStates: [8, 16],
      customerId: custid,
      onlyUnsaved: true,
    };

    httpHandler.instanceAxios
      .post("api/shipment/downloadUnSavedShipments", searchFilter)
      .then((response) => {
        const str_url = window.URL.createObjectURL(new Blob([response.data]));
        const str_link = document.createElement("a");
        str_link.href = str_url;
        str_link.setAttribute("download", "Unsaved " + new Date() + ".csv");
        document.body.appendChild(str_link);
        str_link.click();
      })
      .catch(() => {
        dispatch(addErrorMessage("Download Error!"));
      });
  };

  return (
    <>
      <NoPaddingColumn computer={6}>
        <CustomHeader as="h4" data-testid="shipment-count">
          {props.headerText}
        </CustomHeader>
      </NoPaddingColumn>
      <RightColumn textAlign="right" computer={10}>
        <ToggleStyled
          label="My"
          id="my-shipments"
          toggle
          checked={!props.showAll}
          onClick={(_: React.MouseEvent, { checked }: { checked: boolean }) =>
            props.onMyShipmentsClick(!checked)
          }
        />

        <ToggleStyled
          label="Unsaved"
          id="unsaved-shipments"
          toggle
          checked={props.onlyUnsaved}
          onClick={(_: React.MouseEvent, { checked }: { checked: boolean }) =>
            props.onUnsavedShipmentsClick(checked)
          }
        />

        <Button
          size="mini"
          basic
          onClick={() =>
            !props.onlyUnsaved && props.onUnsavedShipmentsClick(true)
          }
          title="Total unsaved in Turvo"
        >
          <Icon name="exclamation triangle" color="yellow" />
          {props.unsavedTotal || 0}
        </Button>

        <Button
          title="Download Unsaved"
          aria-label="Export Unsaved"
          onClick={handleExportUnsaved}
          icon="download"
          size="mini"
          disabled={isDefaultFilter}
        />
        <Button
          aria-label="Refresh Shipments"
          onClick={props.refresh}
          icon="refresh"
          size="mini"
          disabled={isDefaultFilter}
        />
        <Button
          aria-label="Reset Filter"
          onClick={props.resetFilter}
          icon={
            <Icon.Group>
              <Icon name="filter" />
              <Icon corner name="delete" />
            </Icon.Group>
          }
          size="mini"
          disabled={isDefaultFilter}
        />
        <Button
          aria-label="Toggle Filter"
          onClick={() => props.setFilterPanelOpened(!props.filterPanelOpened)}
          icon={<Icon name="sliders horizontal" />}
          size="mini"
        />
      </RightColumn>
    </>
  );
};

export default ShipmentPanelFilterButtons;

const CustomHeader = styled(Header)`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
`;

const RightColumn = styled(Grid.Column)`
  display: flex;
  padding-right: 0 !important;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const NoPaddingColumn = styled(Grid.Column)`
  padding: 0 !important;
`;

const ToggleStyled = styled(Radio)`
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.225rem 1rem;
  font-size: 11px !important;
  font-weight: 700;
  border-radius: 0.28571429rem;
`;
