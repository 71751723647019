import { useState } from "react";
import { Button, SemanticSIZES } from "semantic-ui-react";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { clearAutoBuild } from "../../../../store/autoBuildSlice";
import AutoBuildModal from "./AutoBuildModal";

type OrderPanelAutoBuildModalProps = {
  state: string;
  size: SemanticSIZES;
};

const OrderPanelAutoBuildModal = (props: OrderPanelAutoBuildModalProps) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDefaultFilter = useAppSelector(
    (state) => state.orders.isDefaultFilter
  );

  const handleClose = () => {
    setIsOpen(false);
    dispatch(clearAutoBuild());
  };

  return (
    <>
      <Button
        size={props.size}
        onClick={() => setIsOpen(true)}
        icon="box"
        content={`Build All ${props.state === "active" ? "Active" : "Inactive"}`}
        disabled={isDefaultFilter}
      />
      <AutoBuildModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default OrderPanelAutoBuildModal;
