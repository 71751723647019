import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainPage from "./MainPageComponent";
import ActionsLog from "../ActionsLog/ActionsLogComponent";
import CallbackPage from "../../components/CallbackPage";
import AutoBuildsComponent from "../AutoBuilds/AutoBuildsComponent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { connectServer } from "../../store/signalRSlice";
import { RootState } from "../../store/store";

const RouterComponent = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.oidc.user?.id_token);

  useEffect(() => {
    if (token) {
      dispatch(connectServer());
    }
  }, [dispatch, token]);

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/work-space" />
      </Route>
      <Route path="/work-space" component={MainPage} />
      <Route path="/actions-log" component={ActionsLog} />
      <Route path="/auto-builds" component={AutoBuildsComponent} />
      <Route path="/oidc-callback" component={CallbackPage} />
    </Switch>
  );
};

export default RouterComponent;
