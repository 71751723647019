import { OrderObject } from "../../../models/OrderObject";

export const mapApiOrders = (orders: OrderObject[]) =>
  orders.map((el: OrderObject) => mapApiOrder(el));

export const mapApiOrder = (order: OrderObject) => {
  if (!order.items) {
    return { ...order, items: [] };
  }

  const orderItems = order.items.map((item) => ({ ...order, ...item }));
  return orderItems[0];
};
