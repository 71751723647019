import { Dispatch, SetStateAction, createContext } from "react";
import { ShipmentBuilderFormObject } from "../../../models/ShipmentBuilderFormObject";

const ShipmentBuilderFormContext = createContext<{
  formObject: ShipmentBuilderFormObject;
  setFormObject: Dispatch<SetStateAction<ShipmentBuilderFormObject>>;
}>({
  formObject: {
    algorithmFunctionName: "OmsGroupedBinPacking",
    vehicleId: 1,
    maxUnits: 9,
    minUnits: 4,
    originRadius: "25",
    destinationRadius: "25",
    maxStops: 3,
  },
  setFormObject: () => {},
});

export default ShipmentBuilderFormContext;
